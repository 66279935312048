import React, { Fragment, useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Button,
  Popconfirm,
  Modal,
  Link,
  Row,
  Col,
  Select,
  Spin,
  MaterialFormItem,
  Form,
  Tag,
  Card,
  FormItem,
  Text,
} from "../../common/UIComponents";
import userStore from "../../stores/UserStore";
import {
  alertMessage,
  doValidate,
  isEmpty,
  validateAddressForm,
} from "../../common/Common";
import I18n from "../../common/I18n";
import AppConfig from "../../config/AppConfig";
import moment from "moment";
import BaseModal from "../BaseModal";
import { fetchTrucks } from "../../api/TrucksApi";
import { fetchUsers } from "../../api/UsersApi";
import { getValueFromArrayOfObjects } from "../../helpers/array_functions";
import {
  formatFullName,
  getAccounts,
  isObjHaveValues,
  renderAlertMessage,
} from "../../helpers/common";
import { OrgContext } from "../../context/OrgContext";
import { fetchAccountShortLos } from "../../api/Los";
import BaseSelect from "../common/SelectDropdowns/BaseSelect";
import {
  fetchAccountConfigs,
  fetchShortFormAccounts,
  setAccountConfig,
} from "../../api/Account";
import ConsigneeDetailsForm from "./ConsigneeDetailsForm";
import {
  defaultPreferences,
  dropLocation,
  pickupLocation,
  requiredFields,
} from "../../constants/orders";
import { loadGoogleMaps } from "../../utils/Utils";
import {
  getDisabledHoursFromStartTime,
  isOrderHasLocation,
} from "../../helpers/orders";
import Appointments from "../common/Appointments";
import { checkIfOrdershasExceededCreditLimit, getWhLocationSlotDetails, validateslots } from "./helpers";
import { getHMSFromTimeString } from "../../helpers/date_functions";
import { TransferOrderApi } from "../../api/TransferOrderApi";
import FormErrors from "../common/FormErrors";

const orderRequiredFields = [
  {
    form_field: "account_id",
    ui_name: I18n.t("general.account"),
    isRequired: true,
    inputType: "text",
  },
  {
    form_field: "los",
    ui_name: I18n.t("los.label"),
    isRequired: true,
    inputType: "text",
  },
];

const BulkLHOrders = (props) => {
  const { warehouseFilter, history, organizationSettings, isFromBulkContainer, isLinehaulClicked,setIsLinehaulClicked,setIsHovered,orderNumbersSelected,isLineHaulScreen  } = props;
  const orgId = userStore.getStateValue("selectedOrg");
  const currentWh =
    typeof warehouseFilter.selectedWarehouses === "string"
      ? _.find(warehouseFilter.warehouses, {
          id: warehouseFilter.selectedWarehouses,
        })
      : _.find(warehouseFilter.warehouses, {
          id: warehouseFilter.selectedWarehouses[0],
        });
  const defaultValues = {
    warehouse_id: currentWh?.id ? currentWh.id : "",
    organization_id: orgId,
    warehouse_code: currentWh?.location_code ? currentWh?.location_code : "",
    pickup_location: warehouseFilter?.selectedWarehouses
      ? warehouseFilter.selectedWarehouses
      : "",
    drop_location: "",
    pickup_scheduled_time: null,
    delivery_scheduled_time: null,
    driver_id: "",
    lh_billing : "",
    fleet_id: "",
    is_location_based: true,
    locations: [
      {
        type_of_loc: "PICKUP",
        preferences: _.cloneDeep(defaultPreferences),
        loc_order_sequence: 0,
        customer_first_name: currentWh.name,
        customer_email: currentWh.email,
        customer_phone_one: !isEmpty(currentWh.phone) ? currentWh.phone : "",
        cs_location: {
          l_address: currentWh.l_address,
        },
        l_type: !isEmpty(currentWh) ? 'WH' : "CS",
        id: !isEmpty(currentWh.id) ? currentWh.id : "",
      },
      {
        type_of_loc: "DELIVERY",
        preferences: _.cloneDeep(defaultPreferences),
        loc_order_sequence: 1,
      },
    ],
  };
  const { warehouses = [] } = warehouseFilter;
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const isMilitaryTime = organizationSettings.is_military_time === "true";
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [fleets, setFleets] = useState([]);
  const [currentFormData, setCurrentFormData] = useState({ ...defaultValues });
  const [isNew, setIsNew] = useState(true);
  const [accounts, setAccounts] = useState([]);
  const [levelOfServices, setLevelOfServices] = useState([]);
  const [accountConfig, setAccountConfig] = useState([]);
  const [errors, setErrors] = useState([]);
  const [orders, setOrders] = useState(props.orders ? [...props.orders] : []);
  // const [orders, setOrders] = useState([]);
  const [isValidating, setIsValidating] = useState(false);
  const [nsr, setNsr] = useState(false);


  useEffect(() => {
    // fetchInitialData
  }, []);

  const fetchInitialData = () => {
    getDrivers();
    getTrucks();
    getAccounts();
    if (window.google) {
      setIsMapLoaded(true);
    } else {
      loadGoogleMaps(() => {
        setIsMapLoaded(true);
      });
    }
  }

  useEffect(() => {
    if (!_.isEqual(props.orders, orders)) {
      setOrders(props.orders ? [...props.orders] : []);
      // const validatedOrders = checkIfOrdershasExceededCreditLimit(props.orders, props.creditsExceededAccnts);
      // setOrders(validatedOrders);
    }
  }, [props.orders]);

  useEffect(() => {
    getAccountConfigs();
  }, [currentFormData.account_id]);

  useEffect(() => {
    if (isLinehaulClicked) {
      fetchInitialData()
      validateOrders();
    }
  }, [isLinehaulClicked]);

  useEffect(() => {
    if(isFromBulkContainer && !showModal){
      setIsLinehaulClicked(false)
      setIsHovered(null);
    }
  }, [showModal]);

  const getAccounts = (whId) => {
    setIsLoading(true);
    fetchShortFormAccounts(orgId).then((result) => {
      if (result.success) {
        setAccounts(result.accounts);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        renderAlertMessage(result.errors)
      }
    });
  };

  const getAccountConfigs = () => {
    const config = {};
    if (currentFormData.account_id) {
      fetchAccountConfigs(
        currentFormData.account_id,
        currentFormData.organization_id
      ).then((result) => {
        if (result.success) {
          const accountConfigurations = result.account_configurations || [];
          accountConfigurations.forEach((setting) => {
            config[setting.setting_name] = setting.setting_value;
          });
          if (
            !isEmpty(config.pod_pic_settings) &&
            _.isString(config.pod_pic_settings) &&
            config.pod_pic_settings === "[]"
          ) {
            config.pod_pic_settings = [];
          }
          // config.pod_pic_settings = updatePicSettingsWithBol(
          //     config.pod_pic_settings,
          // )
          if (isEmpty(config.communication_preference)) {
            config.communication_preference = "SMSES";
          }
          setNsr(config['pod_signature'] === 'true' || isEmpty(config['pod_signature']));
          setAccountConfig(config);
        } else {
          setNsr(false);
          renderAlertMessage(result.errors)
        }
      });
    } else {
      setAccountConfig({});
    }
  };

  useEffect(() => {
    if (!_.isEmpty(currentFormData.account_id)) {
      let account = {};
      const accountIndex = _.findIndex(accounts, {
        id: currentFormData.account_id,
      });
      if (accountIndex >= 0) {
        account = accounts[accountIndex];
      }
      if (Object.keys(account).length > 0) {
        getLos(currentFormData.account_id);
      }
    } else {
      setLevelOfServices([]);
    }
  }, [currentFormData.account_id]);

  const getLos = (accountCode) => {
    const { account_id } = currentFormData;
    const accountIndex = _.findIndex(accounts, {
      id: account_id,
    });
    if (accountIndex >= 0) {
      const account = accounts[accountIndex];
      if (!isEmpty(account.primary_account_id)) {
        accountCode = getValueFromArrayOfObjects(
          accounts,
          "id",
          account.primary_account_id,
          "code"
        );
      } else {
        accountCode = account.code;
      }
    }
    if (!isEmpty(accountCode)) {
      setIsLoading(true);
      fetchAccountShortLos(accountCode, 'LH').then((result) => {
        if (result.success) {
          const loses = result.account.account_los || [];
          setLevelOfServices(loses);
          loses.push({
            name: "None",
            code: "NONE",
            duration: "0",
            id: "NONE",
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
          renderAlertMessage(result.errors)
        }
      });
    }
  };

  const getDrivers = async () => {
    const result = await fetchUsers("driver", 1, null);
    if (result.success) {
      setDrivers(result.users);
    }
  };
  const getTrucks = () => {
    fetchTrucks({}, 1, 1000).then((result) => {
      if (result.success) {
        setFleets(result.trucks);
      }
    });
  };

  const closeWindow = () => {
    setShowModal(false);
    setCurrentFormData({ ...defaultValues });
  };

  const handleOnChange = (element, value) => {
    const data = { ...currentFormData, [element]: value };
    if (element === "driver_id" && !isEmpty(value)) {
      const driver_fleet_id = getValueFromArrayOfObjects(
        drivers,
        "id",
        value,
        "driver_fleet_id"
      );
      data.fleet_id = driver_fleet_id;
    }
    if (
      element === "pickup_scheduled_time" &&
      isEmpty(data.delivery_scheduled_time)
    ) {
      const deliveryDate = value ? value.clone().add(1, "hour") : null;
      data.delivery_scheduled_time = deliveryDate;
    }
    if (element === "account_id") {
      data.los = "";
    }
    setCurrentFormData(data);
  };

  const handleClearStop = (index) => {
    const orderObject = { ...currentFormData };
    if (
      !isEmpty(orderObject) &&
      orderObject.is_location_based &&
      orderObject.locations &&
      orderObject.locations[index]
    ) {
      const locations = orderObject.locations ? orderObject.locations : [];
      if (locations[index]) {
        locations[index] =
          locations[index].type_of_loc === "PICKUP"
            ? _.cloneDeep(pickupLocation)
            : _.cloneDeep(dropLocation);
        setCurrentFormData({ ...currentFormData, locations });
      }
    }
  };

  const validateForm = () => {
    let orderErrors = [];
    let locationErrors = [];
    let validationErrors = [];
    const orderObject = _.cloneDeep(currentFormData);
    const transferOrderRequiredFields = orderRequiredFields.filter((i) =>
      ["account_id", "los"].includes(i.form_field)
    );
    orderErrors = doValidate(transferOrderRequiredFields, orderObject);
    let isFilled = true;
    const hasPickupLocation = _.find(orderObject.locations, {
      type_of_loc: "PICKUP",
    });
    if (!hasPickupLocation) {
      orderErrors.push("Pickup stop is required");
    }
    orderObject.locations.forEach((loc, locIndex) => {
      const location = {};
      const appointments = loc.appointments || [];
      const csLocation =
        loc.cs_location && loc.cs_location.l_address
          ? loc.cs_location.l_address
          : {};
      if (loc.type_of_loc === "DELIVERY") {
        const detailsObject = _.pick(loc, [
          "customer_first_name",
          "customer_last_name",
          "customer_email",
          "customer_phone_one",
          "customer_phone_two",
          "company_name",
        ]);
        const addressObject = _.pick(csLocation, [
          "address_line1",
          "address_line2",
          "city",
          "state",
          "country",
          "zipcode",
        ]);
        const isAppointfilled =
          appointments.length > 0
            ? checkIfAppointmenfFilled(appointments[0])
            : false;
        isFilled =
          isObjHaveValues(detailsObject) ||
          isObjHaveValues(addressObject) ||
          isAppointfilled;
        // if (!isFilled) {
        //   return;
        // }
      }
      let i = 0;
      const { errors: SlotErrors, appointments: slots } = validateslots(
        loc.appointments,
        isMilitaryTime
      );
      if (SlotErrors.length > 0) {
        locationErrors = [].concat(
          locationErrors,
          SlotErrors.map(
            (error) => error + " in " + loc.type_of_loc + " details"
          )
        );
      }

      let allRequiredFields = [...requiredFields];

      // if (
      //   this.state.config[
      //     I18n.t("configurations.move_to_exception_on_invalid_number")
      //   ] === "true"
      // ) {
      //   allRequiredFields = [].concat(allRequiredFields, mobileFields);
      // }

      const customerErrors = doValidate(
        allRequiredFields,
        loc,
        `In stop ${locIndex + 1}, `
      );
      const addressErrors = validateAddressForm(csLocation);
      locationErrors = [].concat(
        locationErrors,
        customerErrors,
        !isEmpty(addressErrors)
          ? [`${addressErrors} in location ${locIndex + 1} Address`]
          : []
      );
      orderObject.locations[locIndex].appointments = [...slots];
      // location.appointments = [...slots]
      // location.service_duration = loc.service_duration
      // location.l_address = loc.cs_location?.l_address ? {...loc.cs_location.l_address} : {}
      // location.type_of_loc = loc.type_of_loc;
      // location.details = _.pick(loc, ['id', 'customer_first_name', 'customer_last_name', 'customer_email', 'customer_phone_one', 'customer_phone_two', 'company_name', 'l_type', 'type_of_loc', 'is_recovery', 'recovery_status', 'is_release', 'release_status']);
      // processedData[details.key].locations.push(location);
    });
    validationErrors = [].concat(validationErrors, orderErrors, locationErrors);

    // if (isFilled && this.isDeliveryBeforePickup(currentOrder.locations)) {
    //   locationErrors.push( 'Delivery date and time should be after pickup date and time')
    // }
    if (validationErrors.length > 0) {
      setErrors(validationErrors);
    } else {
      const selectedLos = _.find(levelOfServices, {
        code: orderObject.los,
      });
      const selectedAccount = _.find(accounts, {
        id: orderObject.account_id,
      });
      const selectedWH = _.find(warehouses, {
        id: orderObject.warehouse_id,
      });
      let pickupLocation = _.find(orderObject.locations, {
        type_of_loc: "PICKUP",
      });

      const currentLocation = !isEmpty(pickupLocation)
        ? { ...pickupLocation }
        : {};
      const csLocation = currentLocation?.cs_location
        ? currentLocation.cs_location
        : {};
      const payload = {
        nsr: !nsr,
        type_of_order: "LH",
        address_type: "",
        contact_customer: "SO & SR",
        ...orderObject,
        account: selectedAccount?.code ? selectedAccount.code : "",
        account_name: selectedAccount?.name ? selectedAccount.name : "",
        warehouse_id: selectedWH?.id ? selectedWH.id : "",
        warehouse_name: selectedWH?.name ? selectedWH.name : "",
        organization_id: orgId,
        customer_order_ids: orders.map((order) => order.id),
        lh_order_ids: orders.map((order) => order.id),
        los_code : selectedLos?.code ? selectedLos.code : "",
        los_id: selectedLos?.id ? selectedLos.id : "",
        los_name: selectedLos?.name ? selectedLos?.name : "",
        operation_code: "CC0",
        company_name: currentLocation.company_name,
        first_name: !isEmpty(currentLocation.customer_first_name)
          ? currentLocation.customer_first_name
          : "",
        last_name: !isEmpty(currentLocation.customer_last_name)
          ? currentLocation.customer_last_name
          : "",
        customer_email: currentLocation.customer_email,
        phone_number_one: currentLocation.customer_phone_one,
        phone_number_two: currentLocation.customer_phone_two,
        address: {
          company_name: !isEmpty(currentLocation.company_name)
            ? currentLocation.company_name
            : "",
          customer_address_line_1: csLocation.l_address.address_line1,
          customer_address_line_2: csLocation.l_address.address_line2,
          customer_city: csLocation.l_address.city,
          customer_state: csLocation.l_address.state,
          customer_country: csLocation.l_address.country,
          customer_zipcode: csLocation.l_address.zipcode,
        },
        locations: orderObject.locations.map((loc, index) => {
          const details = (location.details = _.pick(loc, [
            "id",
            "customer_first_name",
            "customer_last_name",
            "customer_email",
            "customer_phone_one",
            "customer_phone_two",
            "company_name",
            "l_type",
            "type_of_loc",
            "activity_type",
            "activity_status",
          ]));
          const l_address = loc.cs_location?.l_address
            ? { ...loc.cs_location.l_address }
            : {};
          return {
            appointments: currentLocation.appointments, // loc.appointments
            ...details,
            customer_address_line_1: l_address.address_line1,
            customer_address_line_2: l_address.address_line2,
            customer_city: l_address.city,
            customer_state: l_address.state,
            customer_country: l_address.country,
            customer_zipcode: l_address.zipcode,
            type_of_loc: loc.type_of_loc,
            l_type: details?.l_type ? details.l_type : "CS",
            service_duration: loc?.service_duration ? loc.service_duration : 0,
            loc_order_sequence: index,
            los_code : selectedLos?.code ? selectedLos.code : "",
            los_id: selectedLos?.id ? selectedLos.id : "",
            los_name: selectedLos?.name ? selectedLos?.name : "",
          };
        }),

        // pickup_location_id: values.pickup_location,
        // drop_location_id: values.drop_location,
        // pickup_scheduled_time: values.pickup_scheduled_time
        //   ? moment(values.pickup_scheduled_time).format("YYYY-MM-DD HH:mm")
        //   : null,
        // delivery_scheduled_time: values.delivery_scheduled_time
        //   ? moment(values.delivery_scheduled_time).format("YYYY-MM-DD HH:mm")
        //   : null,
        // driver_id: values.driver_id,
        // fleet_id: values.fleet_id,
      };

      // Check if updating an existing linehaul
      setIsLoading(true);
      setErrors([]);
      TransferOrderApi.manage(payload, true)
        .then((response) => {
          if(response.success){
            if (response?.orders?.length > 0) {
              history.push(`/orders/${response.orders[0].id}/edit`);
            }
            if(response?.warning?.length > 0) {
              alertMessage(response.warning);
            } else {
              alertMessage("Linehaul created  successfully")
            }
          } else {
            setErrors(response.errors);
          }
          // handle success response
        })
        .catch((error) => {
          console.error("failed to update linehaul", error);
          // handle error response
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const clearClick = () => {
    setCurrentFormData({ ...defaultValues });
  };

  const renderForm = () => {
    return (
      <Fragment>      
        {orderNumbersSelected ? (
        <Row gutter={16} type="flex" align="middle">
          <Col xs={24}>
            <span className="textBold">Selected Orders</span> :{" "}
            {orders.map((order) => (
              <Tag
                visible
                color="blue"
                closable
                onClose={() =>
                  setOrders(orders.filter((rec) => rec.id !== order.id))
                }
                className="marginTop7"
              >
                {order.customer_order_number}
              </Tag>
            ))}
          </Col>
        </Row> ) : ""
        }
        <Row gutter={16} type="flex" align="middle" className="marginTop10">
          <Col sm={24} xs={24} md={12} lg={6}>
            
            <FormItem label={I18n.t("general.account")} require>
              
              <BaseSelect
                onChange={(e) => handleOnChange("account_id", e)}
                data={accounts}
                size="small"
                element="account_id"
                selectKey="id"
                selectValue="id"
                selectedValue="name"
                style={{ width: "100%" }}
                value={currentFormData.account_id}
                showSearch={true}
                selectedCode="code"
              />
            </FormItem>
          </Col>
          <Col sm={24} xs={24} md={12} lg={6}>
            <FormItem label={I18n.t("los.label")} require>
              <Select
                onChange={(value) => {
                  handleOnChange("los", value);
                }}
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                size="small"
                value={currentFormData.los}
              >
                <Select.Option key="los" value="">
                  -- Select --
                </Select.Option>
                {levelOfServices.map((los) => (
                  <Select.Option key={los.code} value={los.code}>
                    {los.name}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col sm={24} xs={24} md={12} lg={6}>
            <FormItem label={`${I18n.t("general.Driver")}`}>
              <Select
                value={currentFormData.driver_id || ""}
                showSearch
                style={{ width: "100%" }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={(value) => handleOnChange("driver_id", value)}
                size="small"
              >
                <Select.Option key="driver_id" value="">
                  -- Select --
                </Select.Option>
                {drivers.map((driver) => (
                  <Select.Option key={driver.id} value={driver.id}>
                    {driver.employee_code}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col sm={24} xs={24} md={12} lg={6}>
            <FormItem 
            // label={`${I18n.t("general.billing_type")}`}
            label = {"Billing Type"}
             >
                <Select showSearch  key="lh_billing"
                  value={currentFormData.lh_billing || ""}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                    style={{ width: '100%' }}
                    onChange={(value) => handleOnChange("lh_billing", value)}
                    size="small"
                >
                  <Option key="lh_billing" value="">-- Select --</Option>
                  <Option value="STANDARD">{"Standalone"}</Option>
                  <Option value="INDV">{"Individual Billing"}</Option>
                </Select>
            </FormItem>
          </Col>
          {/* <Col sm={24} xs={24} md={12} lg={6}>
            <FormItem label={`${I18n.t("fleet.label")}`} >
                <Select
                  value={currentFormData.fleet_id || ""}
                  showSearch
                  style={{ width: "100%" }}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value) => handleOnChange("fleet_id", value)}
                  size="small"
                >
                  <Select.Option key="fleet_id" value="">
                    -- Select --
                  </Select.Option>
                  {fleets.map((fleet) => (
                    <Select.Option key={fleet.id} value={fleet.id}>
                      {fleet.name}
                      {!isEmpty(fleet.vehicle_type) &&
                        ` (${fleet.vehicle_type})`}
                    </Select.Option>
                  ))}
                </Select>
            </FormItem>
          </Col> */}
        </Row>
      </Fragment>
    );
  };
  const renderConsigneeForm = () => {
    return (
      <Row gutter={12}>
        {currentFormData.locations.map((loc, locationIndex) => (
          <Col xs={12}>
            <Card
              title={
                <Fragment>
                  Stop
                  {locationIndex + 1}
                </Fragment>
              }
              bodyStyle={{ textAlign: "left" }}
              key={`loc${locationIndex}`}
              showArrow={false}
              size="small"
              className="customCard marginTop10"
              extra={
                <Fragment>
                 
                    <Button 
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClearStop(locationIndex);
                    }}
                    type="cancel" size="small" className="textRed">
                      {I18n.t("general.clear")}
                    </Button>
               
                </Fragment>
              }
            >
              <ConsigneeDetailsForm
                onAddressSelect={(address) =>
                  _onAddressSelect(address, locationIndex)
                }
                //onAddressSelect={(address) => this.handleOnAddressChange(orderType, locationIndex, "", "", address)}
                formData={loc}
                handleOnAddressChange={(element, value, obj) =>
                  handleOnAddressChange(locationIndex, element, value, obj)
                }
                handleOnChange={(element, value) =>
                  handleOrderDetailsChange(locationIndex, element, value)
                }
                map={isMapLoaded}
                predefined_locations={organizationSettings.predefined_locations}
                includePredefinedStops={true}
                editableFields={[
                  "email",
                  "customer_phone_one",
                  "customer_phone_two",
                  "address",
                ]}
                reqFrom="orders"
                gridSize={{
                  xs: 24,
                  sm: 24,
                  md: 12,
                  lg: locationIndex >= 0 ? 8 : 4,
                  xl: locationIndex >= 0 ? 8 : 4,
                }}
                size={"small"}
              />
            </Card>
          </Col>
        ))}
      </Row>
    );
  };

  const calculateOrderTimeData = (start_time, end_time) => {
    const { start_time: startTimeString = "", end_time: endTimeString = "" } = {
      start_time,
      end_time,
    };
    const dispatcherDisabledStartHours = getDisabledHoursFromStartTime(
      startTimeString,
      endTimeString,
      false
    );
    const dispatcherDisabledEndHours = getDisabledHoursFromStartTime(
      startTimeString,
      endTimeString,
      false
    );
    const startTimes = getHMSFromTimeString(
      startTimeString,
      "HH:mm",
      null,
      null
    );
    const endTimes = getHMSFromTimeString(endTimeString, "HH:mm", null, null);

    return {
      startTimes,
      endTimes,
      dispatcherDisabledStartHours,
      dispatcherDisabledEndHours,
    };
  };

  const renderAppointments = (locationIndex) => {
    const dataObject = currentFormData.locations[locationIndex];
    const selectedWh = _.find(warehouses, {
      location_code: currentFormData.warehouse_code,
    });

    let { start_time = "", end_time = "" } = selectedWh || {};
    // if start_time and end_time are not present, then organizationSettings will be used
    if (_.isEmpty(start_time) && _.isEmpty(end_time)) {
      (start_time = organizationSettings.route_delivery_start_time),
        (end_time = organizationSettings.route_delivery_end_time);
    }

    const {
      startTimes,
      endTimes,
      dispatcherDisabledStartHours,
      dispatcherDisabledEndHours,
    } = calculateOrderTimeData(start_time, end_time);
    return warehouses.length && currentFormData.warehouse_code ? (
      <Appointments
        source={"ORDER_FORM"}
        showClear
        isNewAppointment={true}
        warehouse_code={currentFormData.warehouse_code}
        slots={getWhLocationSlotDetails(
          warehouses,
          currentFormData.warehouse_code
        )}
        feasible_dates={currentFormData.feasible_dates || []}
        selectedTemplateId={""}
        isMilitaryTime={isMilitaryTime}
        hasDeliveryZipcodesConfig={accountConfig.delivery_zipcodes === "true"}
        adjustStyle={{
          label: 2,
          wrapper: 22,
        }}
        onSlotsChange={ (selectedSlot, isCustom) => {
          const [ start_time, end_time ] = selectedSlot.split("-");
          const newFormData = _.cloneDeep(dataObject);
          let newAppointments = [];
          if (selectedSlot.length > 0 || isCustom) {
            let selectedSlots = isCustom ? [ 'CUSTOM' ] : [ selectedSlot ];
            if (selectedSlots[ 0 ] === '08:00-20:00') {
              selectedSlots = [ 'ANY' ];
            }
            if (
              !_.isEmpty(dataObject.appointments) &&
              dataObject.appointments.length > 0
            ) {
              newAppointments.push({
                ...newFormData.appointments[0],
                slots: selectedSlots,
                start_time: isCustom ? null : start_time,
                end_time: isCustom ? null : end_time,
              });
            } else {
              newAppointments.push({
                slots: selectedSlots,
                has_expedite: false,
                start_time: isCustom ? null : start_time,
                end_time: isCustom ? null : end_time,
              });
            }
            if (currentFormData.is_location_based) {
              handleOrderDetailsChange(
                locationIndex,
                "appointments",
                newAppointments
              );
            } else {
              // newFormData.appointments = newAppointments;
              // this.setState({ formData: newFormData });
              handleOrderDetailsChange(-1, "appointments", newAppointments);
            }
          }
        }}
        clearPreference={(index) =>
          // this.clearPreference(orderType, index)
          {
            if (locationIndex >= 0) {
              setCurrentFormData({
                ...currentFormData,
                locations: currentFormData.locations.map((location, i) => {
                  if (i === locationIndex) {
                    return {
                      ...location,
                      appointments: [
                        {
                          appt_date: "",
                          slots: [],
                          has_expedite: false,
                          start_time: null,
                          end_time: null,
                        },
                      ],
                    };
                  }
                  return location;
                }),
              });
            }
          }
        }
        // handleDateChange={
        //   this.state.callFrom.length
        //     ? (index, element, value, cb) => {
        //         this.handleApptChange(orderType, locationIndex, index, element, value);
        //       }
        //     : null
        // }
        itemOnChange={(index, element, value) => {
          const newFormData = _.cloneDeep(dataObject);
          const newAppointments = [];
          if (!_.isEmpty(newFormData.appointments) && newFormData.appointments.length > 0) {
            const isCustom = element === 'slots' ? Array.isArray(value) && value.length > 0 && value[ 0 ] === 'CUSTOM' || newFormData.appointments[ 0 ].slots[ 0 ] === 'CUSTOM' : newFormData.appointments[ 0 ].slots[ 0 ] === 'CUSTOM';
            newAppointments.push({
              ...newFormData.appointments[ 0 ],
              [ element ]: value,
              start_time: isCustom ? element === 'slots' ? null : element === 'start_time' ? value : newFormData.appointments[ 0 ].start_time : newFormData.appointments[ 0 ].start_time,
              end_time: isCustom ? element === 'slots' ? null : element === 'end_time' ? value : newFormData.appointments[ 0 ].end_time : newFormData.appointments[ 0 ].end_time,
            });
          } else {
            newAppointments.push({
              appt_date: '',
              slots: [],
              has_expedite: false,
              start_time: null,
              end_time: null,
              [ element ]: value,
            });
          }

          handleOrderDetailsChange(
            locationIndex,
            "appointments",
            newAppointments
          );

          // newFormData[ orderType ].appointments = newAppointments;
          // this.setState({ formData: newFormData });
        }}
        data={{
          preferences: _.get(dataObject, "appointments", []),
          endHour: endTimes.hourValue,
          endMinute: endTimes.minuteValue,
          startHour: startTimes.hourValue,
          startMinute: startTimes.minuteValue,
          disabledStartHours: dispatcherDisabledStartHours,
          disabledEndHours: dispatcherDisabledEndHours,
          showExpedite: true,
        }}
        size={"small"}
      />
    ) : (
      <Text className="textBold">"No Warehouse Found"</Text>
    );
  };

  const updateFormData = (locationIndex, element, value) => {
    const formData = { ...currentFormData };
    if (isOrderHasLocation(formData, locationIndex)) {
      formData["locations"].forEach((location, index) => {
        if (index == locationIndex) {
          location[element] = value;
        }
      });
    } else {
      formData[element] = value;
    }
    return formData;
  };

  const handleOnAddressChange = (locationIndex = -1, element, value, obj) => {
    const currentOrder = { ...currentFormData };
    let dataObject = {};
    const locations = currentOrder.locations || [];
    if (locationIndex >= 0 && locations[locationIndex]) {
      dataObject = locations[locationIndex];
    }
    const address =
      dataObject.cs_location && dataObject.cs_location.l_address
        ? dataObject.cs_location.l_address
        : {};
    let newAddress = Object.assign({}, address);
    if (obj) {
      newAddress = Object.assign({}, address, { ...obj });
     // delete newAddress.id;
    } else if (element) {
      newAddress[element] = value;
    }
    if (locationIndex >= 0) {
      dataObject.cs_location = Object.assign({}, dataObject.cs_location, {
        l_address: newAddress,
      });
      locations[locationIndex] = dataObject;
    }
    setCurrentFormData({ ...currentOrder, locations });

    // if (!isEmpty(newAddress.zipcode)) {
    //   this.getAvailableDates(orderType, locationIndex)
    // }
  };

  const clearPreference = (locationIndex) => {
    const locs = currentFormData.locations.map((location, i) => {
      if (i === locationIndex) {
        return {
          ...location,
          appointments: [
            {
              appt_date: "",
              slots: [],
              has_expedite: false,
              start_time: null,
              end_time: null,
            },
          ],
        };
      }
      return location;
    });
    setCurrentFormData({
      ...currentFormData,
      locations: locs,
    });
  };

  const handleOrderDetailsChange = (
    locationIndex,
    element,
    value,
    cb = null,
    locationElement
  ) => {
    let formData = updateFormData(
      locationIndex,
      element,
      value,
      locationElement
    );
    setCurrentFormData(formData);
    //let isMultiDayTransfer = this.state.isMultiDayTransfer;
    // let isStorageNeeded = this.state.storageNeeded;
    // if (element === "appointments"){
    //   isMultiDayTransfer = this.isMultiDayTransfer(formData.locations)
    //     // if(!this.state.isNew && !isStorageChecked){
    //     //   isStorageNeeded = false
    //     // }else if(!this.state.isNew && isStorageChecked){
    //     //   isStorageNeeded = true
    //     // }else if(this.state.isNew && !this.state.isMultiDayTransfer && isMultiDayTransfer){
    //     //   isStorageNeeded = true
    //     // }
    // }
    // this.setState({ formData , isMultiDayTransfer }, () => {
    //   this.postUpdateActions(element, cb);
    // });
  };

  const _onAddressSelect = (selectedAddressInfo, locationIndex = null) => {
    if (selectedAddressInfo.type === "predefined") {
      const { first_name, last_name, email, phone, company_name } =
        selectedAddressInfo;
      handleOrderDetailsChange(
        locationIndex,
        "customer_first_name",
        first_name
      );
      handleOrderDetailsChange(locationIndex, "customer_last_name", last_name);
      handleOrderDetailsChange(locationIndex, "customer_phone_one", phone);
      handleOrderDetailsChange(locationIndex, "customer_email", email);
      handleOrderDetailsChange(locationIndex, "company_name", company_name);
      if(selectedAddressInfo?.id){
        handleOrderDetailsChange(locationIndex, "id", selectedAddressInfo.id);
      }      //delete selectedAddressInfo.id;
    }
    if(selectedAddressInfo?.l_type === 'WH' ){
      handleOrderDetailsChange(locationIndex, "l_type", 'WH');
    }
    handleOrderDetailsChange(locationIndex, "cs_location", selectedAddressInfo);
  };

  const renderAllocateWindow = () => {
    return (
      <BaseModal
        title={`Create ${I18n.t("linehaul.label")}`}
        width={"calc(100vw - 96px)"}
        style={{ top: 20 }}
        visible={showModal}
        onCancel={() => closeWindow()}
        maskClosable={false}
      >
        <Spin spinning={isLoading}>
          <Form className="linehaul-creation-form-wrapper">
            {renderForm()}
            <Card
              title={I18n.t("appointmentForm.preference_title")}
              bodyStyle={{ textAlign: "left" }}
              key={`apptloc`}
              showArrow={false}
              size="small"
              className="customCard marginTop10"
              // extra={
              //   <Fragment>
              //     <Popconfirm
              //       placement="topRight"
              //       title={I18n.t("messages.clear_confirm")}
              //       onConfirm={(e) => {
              //         e.stopPropagation();
              //         clearPreference(0);
              //       }}
              //       okText="Yes"
              //       cancelText="No"
              //     >
              //       <Button type="cancel" size="small" className="textRed">
              //         {I18n.t("general.clear")}
              //       </Button>
              //     </Popconfirm>
              //   </Fragment>
              // }
            >
              {renderAppointments(0)}
            </Card>

            {renderConsigneeForm()}
            <Row className="marginTop10">
              <Col xs={24} className="alignCenter">
                {
                  // <Popconfirm
                  //   placement="topRight"
                  //   title={I18n.t("messages.clear_confirm")}
                  //   onConfirm={() => clearClick()}
                  //   okText="Yes"
                  //   cancelText="No"
                  // >
                  <Button
                    className="marginRight5"
                    icon="close"
                    size="default"
                    onClick={closeWindow}
                  >
                    {I18n.t("general.cancel")}
                  </Button>
                  // </Popconfirm>
                }
                <Button
                  type="primary"
                  onClick={() => validateForm()}
                  icon="save"
                  size="default"
                >
                  {I18n.t("general.save")}
                </Button>
              </Col>
            </Row>
            {FormErrors([...new Set(errors)])}
          </Form>
        </Spin>
        {/* <EditOrderFormComponent
          customerDetails={{}}
          customerOrderId={''}
          preferences={[]}
          onCancel={closeWindow}
          handleSuccess={(message, isOutOfDispatch, isModified, newData) =>
            // this.handleOrderSaveSuccess(
            //   message,
            //   isOutOfDispatch,
            //   isModified,
            //   newData
            // )
            {}
          }
          currentRoute={""}
          isNew={true}
        /> */}
      </BaseModal>
    );
  };

  const validateOrders = () =>{
    const orders = props.orders ? props.orders : [];
    const orderIds = orders.map(order => order.id)
    setIsValidating(true)
    TransferOrderApi.validateOrders(orderIds.join(','))
        .then((response) => {
          if(response.success){
            // setOrders(props.orders ? props.orders : []);
            // setShowModal(true);
            // check the credit limit exceeded orders here too
            const validatedOrdersLimits = checkIfOrdershasExceededCreditLimit(orders, props.creditsExceededAccnts);
            const remainingOrders = validatedOrdersLimits.filter(order => !order.account_limit_exceeded);
            const orderErrors = validatedOrdersLimits.filter(order => order.account_limit_exceeded).map(order => `${order.customer_order_number}`);
            const remainingOrderMessages = remainingOrders.map(order => `${order.customer_order_number}`);
            // const validOrderMsg = remainingOrderMessages.length > 0 ? `Order ${remainingOrderMessages.join(', ')} can be allocated` : '';
            const validOrderMsg = remainingOrderMessages.length > 0 ? `${remainingOrderMessages.join(', ')}` : '';
            // const invalidOrderMsg = orderErrors.length > 0 ? `Order ${orderErrors.join(', ')} has exceeded credit limit` : '';
            const invalidOrderMsg = orderErrors.length > 0 ? `${orderErrors.join(', ')}` : '';
 
            if (orderErrors.length > 0) {             
               Modal.error({
                title: "Errors",
                content: (
                  <Fragment>
                    { orderErrors.length > 0 && (
                      <div className="marginTop10">
                        {/* <h4>{ I18n.t('order.lh_err_limit_orders') }</h4> */}                        
                         <h4>The following orders exceeded credit limit</h4>  
                        <span className="textRed textBold">{ invalidOrderMsg }</span>
                      </div>
                    ) }
                    { remainingOrderMessages.length > 0 && (
                      <div className="marginTop10">
                        <h4>{ I18n.t('order.lh_success_orders') }</h4>
                        <span className="textGreen">{ validOrderMsg }</span>

                      </div>
                    ) }
                  </Fragment>
                ),
                width: "40%",
                onOk: () => {
                  // if length is equal to orders length, then all orders has exceeded credit limit
                  if (orderErrors.length === orders.length) {
                    setOrders([]);
                    setShowModal(false);
                  } else {
                    const remainingOrders = validatedOrdersLimits.filter(order => !order.account_limit_exceeded);
                    setOrders(remainingOrders);
                    setShowModal(true);
                  }
                },
                okText: 'Ok',
                cancelText: "No",
                onCancel: () => {
                  setOrders([]);
                  setShowModal(false);
                }
              });
            } else {
              setOrders(props.orders ? props.orders : []);
              setShowModal(true);
            }
          } else {
            const existing_orders =  response?.data?.existing_orders || [];
            const remaining_orders =  response?.data?.remaining_orders || [];
            const remainingIds = remaining_orders.map(order => order.id)
            const hasValidOrders = remaining_orders.length > 0;
            // check if remaining orders are in credit limit exceedion
            const orderOffLimits = props.orders.filter(order => remainingIds.includes(order.id));
            const validatedOrdersLimits = checkIfOrdershasExceededCreditLimit(orderOffLimits, props.creditsExceededAccnts);
            const orderErrors = validatedOrdersLimits.filter(order => order.account_limit_exceeded).map(order => `${order.customer_order_number}`);
            const finalOrders= remaining_orders.filter(order => !orderErrors.includes(order.customer_order_number))

            Modal[hasValidOrders > 0 ? 'confirm' : 'error']({

              title: "Errors",
              content: (
                <Fragment>
                  <div>
                    <h4>{I18n.t('order.lh_err_orders')}</h4> 
                    <span className="textRed textBold">{existing_orders.map(order => order.customer_order_number).join(', ')}</span>
                  </div>
                  {/* limit errrors */ }
                  { orderErrors.length > 0 &&
                    <div className="marginTop10">
                      {/* <h4>{ I18n.t('order.lh_err_limit_orders') }</h4>*/}
                       <h4>The following orders exceeded credit limit</h4>
                      <span className="textRed textBold">{ orderErrors.join(', ') }</span>
                    </div>
                  }
                  { hasValidOrders > 0 && 
                    <div className="marginTop10">
                      <h4>{I18n.t('order.lh_success_orders')}</h4> 
                       {/* <span className="textGreen">{remaining_orders.map(order => order.customer_order_number).join(', ')}</span>
                       */}
                      <span className="textGreen">
                        {finalOrders.map((order) => (
                          <span key={order._id}>
                            {order.customer_order_number}{" "}
                          </span>
                        ))}
                      </span>

                    </div>
                  }
                  { hasValidOrders && <div className="marginTop10">{I18n.t('messages.still_proceed')}</div>}
                </Fragment>
              ),
              width: "40%",
              onOk: () => {
                if(hasValidOrders){
                  setOrders(finalOrders);
                  setShowModal(true);
                }
              },
              okText: hasValidOrders > 0 ? 'Yes' : 'Ok',
              cancelText: "No",
              onCancel: () => {
              }
            });

            // setErrors(response.errors);
          }
          // handle success response
        })
        .catch((error) => {
          console.error("failed to update linehaul", error);
          // handle error response
        })
        .finally(() => {
          setIsValidating(false)
        });

  }

  return (
    <Fragment>
      {isLineHaulScreen ? 
      <Button
          type="primary"
          style={{ marginTop: "2px", marginLeft: 10, marginRight: 10 }}
          className="buttonCerulean"
          loading={isValidating}
          onClick={() => { 
            fetchInitialData()
            setShowModal(true)
          }}
        >
          {`Create ${I18n.t("linehaul.label")}`}
        </Button>
         :
        
      !isFromBulkContainer ? (
        <Button
          type="primary"
          style={{ marginTop: "2px", marginLeft: 10, marginRight: 10 }}
          className="buttonCerulean"
          icon="column-width"
          size={props.size || "default"}
          loading={isValidating}
          onClick={() => {
            validateOrders()
          }}
        >
          {I18n.t("linehaul.label")}
        </Button>
      ) : I18n.t('linehaul.label')}
      {showModal && renderAllocateWindow()}
    </Fragment>
  );
};

BulkLHOrders.propTypes = {
  getOrderDetails: PropTypes.func,
  setRefreshValue: PropTypes.func,
  isFromBulkContainer: PropTypes.bool,
  isLinehaulClicked: PropTypes.bool,
  setIsLinehaulClicked: PropTypes.func,
  setIsHovered: PropTypes.func,
};
BulkLHOrders.defaultProps = {
  getOrderDetails: {},
  setRefreshValue: () => {},
  isFromBulkContainer: false,
  isLinehaulClicked: false,
  setIsLinehaulClicked: () => {},
  setIsHovered: () => {}
};

const BulkLinehaulForm = Form.create({ name: "linehaul_route_form" })(
  BulkLHOrders
);
export default withRouter(BulkLinehaulForm);
