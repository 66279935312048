/* eslint-disable no-nested-ternary */
/* eslint-disable react/sort-comp */
import React, { Component, Fragment, useContext } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { validateEmailList} from "../billing_screen/helpers";
import {
  Col,
  FormItem,
  Input,
  Row,
  Button,
  Alert,
  Popconfirm,
  Icon,
  Typography,
  Tooltip,
  Card,
  Modal,
  Divider,
  Select,
  Spin,
  Empty,
  message,
  Drawer,
  Tabs,
  Search,
  DatePicker,
} from "../../common/UIComponents";
import {
  alertMessage,
  findTotal,
  doValidate,
  checkNegitive,
  isEmpty,
  findNumberTotal,
  customPasteSplit,
  doFormate,
  compareString,
} from "../../common/Common";
import I18n from "../../common/I18n";
import FormattedErrors from "../common/FormattedErrors";
import FormErrors from "../common/FormErrors";
import InvoicesItemList from "./InvoicesItemList";
import Remarks from "./Remarks";
import PrintGallery from "../common/PrintGallery";
import {
  changeInvoiceStatus,
  deleteInvoiceLine,
  updateInvoice,
  updateInvoiceLines,
  saveNewInvoice,
  saveInvoiceDetailsForm,
  addOrdersForBillingGroup,
  removeOrderFromBillingGroup,
  applyFMP,
  applySMP,
  getContactEmails,
  reapproveBillings,
  updateInvoiceCharges,
  fetchBillingDetails,
  fetchManualBillingDetails,
  regenerateInvoiceWithNewLos,
  changeStatusOfInvoicesToHold,
  getBillingActivities,
  saveTemplateZoneForm,
  getEmailSubjectLine,
  saveHawbChangedDate,
  updateFuelPercentage,
} from "../../api/Billing";
import {
  fetchAccountAccessorials,
  fetchAccountConfigs,
  fetchOrderTypeLos,
} from "../../api/Account";
import BaseModal from "../BaseModal";
import InvoiceHeader from "./Header";
import { fetchAccountShortLos } from "../../api/Los";
import Legs from "../Tasks/Legs";
import photoIcon from "../../../assets/images/upload_photo.png";
import userStore from "../../stores/UserStore";
import UploadGallery from "../common/UploadGallery";
import AppConfig from "../../config/AppConfig";
import { fetchOrganizationDetails } from "../../api/Organisations";
import {
  checkServiceExistance,
  confirmationPopup,
  formatByTimeConfig,
  renderAlertMessage,
  updatePicSettingsWithBol,
} from "../../helpers/common";
import CustomerOrderDetails from "../orders/CustomerOrderDetails";
import ApproveInvoices from "./ApproveInvoices";
import TagsInput from "react-tagsinput";
import { getFMPOrderFromInvoice, showFMP } from "../../helpers/billing";
import moment from "moment";
import { deleteOrderPic, fetchOrderAccessorials, fetchOrderDetails, uploadPod } from "../../api/OrdersApi";
import { renderDriverNotes, setAccessorialResponse } from "../../helpers/orders";
import { DriverSelect } from "../common/SelectDropdowns/BaseSelect";
import SwitchComponent from "../../common/switchComponent";
import BillingPdfDownload from "./BillingPdfDownload";
import EmailsInput from "../../containers/Modals/EmailsInput";
import { SendInvoiceEmail } from "../../api/Billing";
import ConsolidationButton from "./ConsolidateButton";
import BillingDrivers from "./BillingDrivers";
import { withRouter } from "react-router-dom";
import { WarehouseContext } from "../../context/WarehouseContext";
import { OrgContext } from "../../context/OrgContext";
import { UserContext } from "../../context/UserContext";
import EditOrderFormComponent from "../orders/EditOrderForm";
import OrderQuantity from "../preplan/OrderQuantity";
import AccessorialCharges from "../../containers/AccessorialCharges";
import { fetchTechnicians, fetchUsers } from "../../api/UsersApi";
import DriverPayDetails from "./DriverPayDetails";
import BillingDriverPay from "../../containers/BillingDriverPay";
import BillingActivitiesList from "./BillingActivitiesList";
import InvoiceContact from "./InvoiceContact";
import TransportationChargesList from "./TransportationChargesList";
import { fetchAccessorialDetails, fetchAccessorialsComponents,fetchAccountAccessorials as fetchAccountComponentAccessorials } from "../../api/Accessorials";
import EditIcon from "../common/EditIcon";

const { TabPane } = Tabs;
const { TextArea } = Input;
const { confirm } = Modal;

const nextsteps = {
  [I18n.t("invoices.ready_for_billing")]: "APPROVED",
  APPROVED: "PAYMENT_CONFIRMED",
  PAYMENT_CONFIRMED: "CLOSED",
};

const requiredFields = [
  // {
  //   form_field: "invoice_name",
  //   ui_name: I18n.t("invoices.description"),
  //   isRequired: true,
  //   inputType: "text",
  // },
  {
    form_field: "invoice_value",
    ui_name: I18n.t("invoices.amount"),
    isRequired: true,
    inputType: "floatNumber",
  },
];

const ContentOuter = ({ message, navigateToBilling }) => (
  <Spin tip="Fetching Billing Details...">
    <div className="content-outer">
      <div className="content indvOrderScreen">
        <Row >
          <Col md={12}>
            <div>
              <span>
                <span className="breadCrumbLink">{message}</span>
              </span>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  </Spin>
);

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account:{},
      activeKey: "details",
      invoice: {},
      consolidatedOrderNumbers: null,
      isDriverSettlementApproved: null,
      rawInvoice : {},
      inProgress: false,
      errors: [],
      currentStatus: "",
      disableRemarksButton: true,
      comments: "",
      accessorials: [],
      levelOfServices: [],
      currentLos: "",
      config: {},
      currentOrderPics: {},
      imagesVisible: false,
      logo: null,
      showAddOrders: false,
      showEmailModal: false,
      isEmailLoading: false,
      billingEmails: [],
      contacts: [],
      subject: "",
      description: "",
      isSendingMail: false,
      additionalOrders: "",
      isRemovingGroupOrder: false,
      organizationSettings: {},
      invoiceLos: {},
      isLoading: false,
      isSaving: false,
      ordersAddingProgress: false,
      enableHide: false,
      removeEditingVals: false,
      is_pickup_by_driver: "false",
      pickup_order_driver_id: "",
      pickup_order_amount: null,
      decimalPoints: null,
      isRepproveLoading: false,
      selectedInfo: null,
      showActions: true,
      editForm : false,
      selectedOrderId: "",
      currentOrder: {},
      editForm: false,
      isFetchingDrivers: false,
      technicians: [],
      showGroupOrder: false,
      drivers: [],
      activeRecordId: this.props.activeRecordId,
      isLosChanged: false,
      isInvoiceLocked: false,
      isOnHold: false,
      filter: this.props.filter,
      isApproved: false,
      billingActivitiesList: [],
      filteredBillingActivitiesList: [],
      filteredOrdersForActivities : [],
      filterOrderIds : [],
      defaultTransportationChargeGLCode : "",
      currentGalleryLocId: "",
      configLoading: false,
      currentOrderGroupType: this.props.currentOrderGroupType || "",
      ikeaChargesrecord: [],
      newIkeaChargesRecord:[],
      orgComponents: [],
      displayComponents: [],
      accountComponentAccessorial: [],
      orderAccessorials: [],
      authorizeDetails: {},
      slotConfig:{},
      driversList : [],
      callInProgress : false,
      losConfig: {},
      losProgress: false,
      isEditHawbDate: false,
      isHawbDatemodified: "",
      currentProcessedId: "",
      editedFuelPercent: "",
      fuelPerUpdateLoading: false
    };
  }
  addBilligEmail = (value) => {
    const validEmails = validateEmailList(value);
    this.setState({ billingEmails: validEmails });
  };


  getTechnicians = () => {
    fetchTechnicians().then((result) => {
      if (result.success) {
        const technicians = result.users || [];
        this.setState(
          {
            technicians,
          }
        );
      } else {
        this.setState({ technicians: [] });
      }
    });
  };

  getDrivers = () => {
    this.setState({ isFetchingDrivers: false });
    fetchUsers("driver", 1, null, true).then((result) => {
      if (result.success) {
        this.setState({
          drivers: result.users,
          isFetchingDrivers: false,
        });
      } else if (result.errors) {
        renderAlertMessage(result.errors);
        this.setState({ isFetchingDrivers: false });
      }
    });
  };

  getCompleteDrivers = () => {
    this.setState({inProgress : true})
    fetchUsers("driver").then((result) => {
      if(result.success){
        const updatedDrivers = result.users.map((driver) => ({
          id: driver.id,
          employee_code: driver.employee_code,
          full_name: driver.full_name,
        }));
        this.setState({driversList : updatedDrivers});
      } else {
        renderAlertMessage(result.errors)
      }
    }).catch((error) => {
      alertMessage(error.message, "error",10)
    }).finally(() => {
      this.setState({inProgress : false})
    });
  };

  handleInvoiceResponse = (result, id) => {
    const defaultInvoice = {
      description: {},
      account_details: {},
      customer_details: {},
      customer_order_id: id,
      remarks: _.get(result, 'remarks', []),
      consolidated_order_numbers: _.get(result, 'consolidated_order_numbers', []),
      is_driver_settlement_approved: result.is_driver_settlement_approved,
      pickup_driver_pay_details: result.pickup_driver_pay_details || {},
      accessorial_charges: [],
      adjustment_charges: [],
      transportation_charges: [],
      order_info: {},
      status: result.billing_status,
      error_log: [],
      error_messages: [],
    };

    const currentInvoice = _.merge({}, defaultInvoice, result.invoice);

    const error_logs = result.error_logs || [];
    currentInvoice.error_log = _.map(_.filter(error_logs, 'error_log'), 'error_log');
    currentInvoice.error_messages = _.map(_.filter(error_logs, 'error_message'), 'error_message');

    return currentInvoice;
  };


  sortPriceList = (data) => {
    const sortedData = _.cloneDeep(data);

    // Sort accessorial charges
    sortedData.accessorial_charges.forEach(charge => {
      charge.price_list = _.sortBy(charge.price_list, 'invoice_name');
    });

    // Sort transportation charges
    // sortedData.transportation_charges.forEach(charge => {
    //   charge.price_list = _.sortBy(charge.price_list, 'invoice_name');
    // });

    // Sort fuel surcharges
    sortedData.fuel_surcharges.forEach(charge => {
      charge.price_list = _.sortBy(charge.price_list, 'invoice_name');
    });

    // Sort adjustment charges
    sortedData.adjustment_charges.forEach(charge => {
      charge.price_list = _.sortBy(charge.price_list, 'invoice_name');
    });

    return sortedData;
  };


  onHawbDateChange = (date, dateString) => {
    const jsDate = new Date(dateString);
    const month = String(jsDate.getMonth() + 1).padStart(2, '0');
    const day = String(jsDate.getDate()).padStart(2, '0');
    const formattedDate = `${month}.${day}`;
    this.setState({ isHawbDatemodified: formattedDate });
  }

  saveHawbDateChange = () => {
    if (!_.isEmpty(this.state.isHawbDatemodified)) {
    const current_org = _.get(this.props, 'userContext.currentOrg', '');

    // const invoiceHawb = this.state.invoice.order_info.hawb;
    // const freshHawbDate = invoiceHawb.split(" ");
    // freshHawbDate.pop();
    // freshHawbDate.push(this.state.isHawbDatemodified);
    // const updatedHawbDate = freshHawbDate.join(" "); 

    const payload = {
      organization_id: current_org.id,
      customer_order_id: this.state.invoice.customer_order_id,
      hawb: `Week Ending ${this.state.isHawbDatemodified}`
    }

    saveHawbChangedDate(payload).then((res) => {
      if (res.success) {
      console.log(this.state.invoice);
      this.setState({
        invoice: {
          ...this.state.invoice,
          transportation_charges: [ {
            ...this.state.invoice.transportation_charges[ 0 ], hawb: `Week Ending ${this.state.isHawbDatemodified}`
          } ]
        },
        isEditHawbDate: false,
        isHawbDatemodified: ""
      });
        message.success("Hawb updated successfully");
      } else {
        message.error("Failed to update Hawb");
      }
    });
    }
    else {
      return message.error('Select Hawb Date');
    }
  }
  handleUpdateInvoice = (updatedInvoice, orderNo) => {
    this.setState(prevState => {
      // Update the state with new invoice data
      const updatedOrderDriverDetails = prevState.invoice.order_driver_details.map(order => {
        if (order.customer_order_id === orderNo) {
          return {
            ...order,
            driver_details: updatedInvoice.order_driver_details.find(o => o.customer_order_id === orderNo)?.driver_details || [],
          };
        }
        return order;
      });
  
      return {
        invoice: {
          ...prevState.invoice,
          order_driver_details: updatedOrderDriverDetails,
        },
      };
    });
  };

  renderHawbDatePicker = () => {
    return (
      <Row type="flex" gutter={5}>
        <Col>
          <DatePicker
            size="small"
            format={"MMM D"}
            onChange={this.onHawbDateChange}
            style={ { width: '120px' } }
            allowClear={ false }
            autoFocus
          />
        </Col>
        <Col>
          <Icon type="save" theme="twoTone" className="fontSize14 cusorPointer" 
            onClick={() => this.saveHawbDateChange()}
          />
        </Col>
        <Col>
          <Icon type="close" className="fontSize14 cusorPointer" 
            onClick={() => this.closeHawbDatePicker()}
          />
        </Col>
      </Row>
    )
  }

  closeHawbDatePicker = () => {
    this.setState({ isEditHawbDate: false, isHawbDatemodified: "" });
  }


  handleEditHawbDate = (val) => {
    this.setState({ isEditHawbDate: val });
  }

  getBillingDetails = (id) => {
    this.setState({ isLoading: true });
    fetchBillingDetails(id, this.state.currentOrderGroupType).then((result) => {
      if (result.success) {
        const invoiceStatus = result.billing_status;
        const orgCode = _.get(this.props, 'userContext.currentOrg.code', '');
        const showHoldBucket = _.get(this.props, 'organizationSettings.show_hold_bucket', '') == "true";
        let currentInvoice = this.handleInvoiceResponse(result, id);
        currentInvoice = this.sortPriceList(currentInvoice);
        const defaultTransportationChargeGLCode = result.invoice.transportation_gl_code;
        const transportationCharges = currentInvoice?.transportation_charges?.length ? currentInvoice.transportation_charges[0] : []
        this.setState(
          {
            invoice: currentInvoice,
            inProgress: false,
            consolidatedOrderNumbers: currentInvoice.consolidated_order_numbers,
            isDriverSettlementApproved: currentInvoice.is_driver_settlement_approved,
            is_pickup_by_driver: _.get(currentInvoice, 'pickup_driver_pay_details.is_pickup_by_driver', "false"),
            pickup_order_driver_id: _.get(currentInvoice, 'pickup_driver_pay_details.pickup_order_driver_id', ""),
            pickup_order_amount: _.get(currentInvoice, 'pickup_driver_pay_details.pickup_order_amount', null),
            decimalPoints: window.localStorage.getItem('round_off_decimals'),
            isLoading: false,
            organizationSettings: this.props.organizationSettings,
            showActions: currentInvoice.status === 'READY_FOR_BILLING' ? true : false,
            isInvoiceLocked: currentInvoice?.transportation_charges &&
              currentInvoice.transportation_charges.filter(rec => rec.is_locked == 'true').length > 0 ? true : false,
            isOnHold: showHoldBucket ? invoiceStatus == 'HOLD' : false,
            defaultTransportationChargeGLCode: defaultTransportationChargeGLCode, // Set default GL code
            // ikeaChargesrecord: transportationCharges?.price_list?.length ? transportationCharges.price_list : []
            ikeaChargesrecord:
              this.state.newIkeaChargesRecord?.length > 0
                ? [
                    ...(transportationCharges?.price_list || []),
                    ...this.state.newIkeaChargesRecord,
                  ]
                : transportationCharges?.price_list || [],
          },
          () => {
            // if (modalElemet) {
            //   this.handleOnChange(modalElemet, true);
            // }
            if(Object.keys(this.state.rawInvoice).length === 0){
              this.setState({rawInvoice: currentInvoice})
              this.getDrivers();
              this.getCompleteDrivers();
              this.getAccessorials();
              this.getAccountLos();
              this.setAccountLos();
              this.getAccountConfigs();
              this.getOrgLogo();
              this.setInvoiceLos();
              this.setSelectedInfo();
              this.getTechnicians();

            } else{
              this.setInvoiceLos()
            }
          }
        );
      } else {
        renderAlertMessage(result.errors);
        this.setState({
          invoice: {},
          isLoading: false,
        });
      }
    });
  };

  // getOrgComponents = () => {
  //   fetchAccessorialsComponents().then((result) =>{
  //     if(result.success){
  //       const orgComponentsResponse = result.components || [];
  //       const displayComponents = [];
  //       const noParamComponents = [];
  //       orgComponentsResponse.forEach((component) => {
  //         if (
  //           component.component_params &&
  //           component.component_params.length > 0
  //         ) {
  //           const componentParams = component.component_params || [];
  //           const visibleParams = componentParams.filter((param) =>
  //             param.visible_to.includes('DISPATCHER'),
  //           );
  //           if (visibleParams.length > 0) {
  //             displayComponents.push(component.code);
  //           } else {
  //             noParamComponents.push(component.code);
  //           }
  //         } else {
  //           noParamComponents.push(component.code);
  //         }
  //       });
  //       this.setState({orgComponents : orgComponentsResponse,displayComponents :displayComponents })
  //     }
  //   }).finally(() => {
  //     this.getAccountComponentAccessorials();
  //   })
  // }

  // getSlotDetails = (id, reqCode = '') => {
  //   this.getAccessorialDetails(id, reqCode);
  // };

  // getAccessorialDetails = (id, reqCode) => {
  //   fetchAccessorialDetails(id).then((result) => {
  //     if (result.success) {
  //       const currentAccessorial = result.accessorial || [];
  //       const componentParams = currentAccessorial ? currentAccessorial.component_params : [];
  //       const slotParams = !isEmpty(reqCode) ?
  //         componentParams.filter(param => param.component_code === reqCode) :
  //         [ ...componentParams ];
  //       const newSlotConfig = { ...this.state.slotConfig };
  //       newSlotConfig[ id ] = slotParams;
  //       this.setState({slotConfig : newSlotConfig})
  //     } else {
  //       const newSlotConfig = { ...this.state.slotConfig };
  //       newSlotConfig[ id ] = [];
  //       renderAlertMessage(result.errors);
  //       setInProgress(false);
  //       this.setState({slotConfig : newSlotConfig})
  //     }
  //   });
  // };


  // getAccountComponentAccessorials = () => {
  //   const invoice = this.initialChargeValues(this.state.invoice);
  //   const accountId = _.get(invoice, "account_details.id", null);
  //   const orgId = _.get(this.props.userContext,"currentOrg.id",null)
  //   if(accountId) {
  //     this.setState({callInProgress : true})
  //     fetchAccountComponentAccessorials(orgId, accountId, "DISPATCHER").then((result) => {
  //       if(result.success) {
  //         const accessorials = result.accessorials || [];
  //         const accountComponentAccessorial = accessorials.map(
  //           (accessorial) => {
  //             const componentDefs = this.getDefinitions(accessorial);
  //             const slotObject = _.find(componentDefs, { code: 'SLOTS' });
  //             if (!isEmpty(slotObject)) {
  //               this.getSlotDetails(accessorial.id, 'SLOTS');
  //             }
  //             return Object.assign({}, accessorial, {
  //               componentDefs,
  //             });
  //           },
  //         );
  //         this.setState({callInProgress : false})
  //         const sortedAccountComponentAccessorial = _.sortBy(accountComponentAccessorial, [ "standard_code", "accessorial_code" ]);
  //         this.setState({accountComponentAccessorial : sortedAccountComponentAccessorial})
  //       }
  //     }).finally(() => {
  //       this.getOrderAccessorials(); 
  //     })
  //   }
  // };

  // getDefinitions = (accessorial) => {
  //   if (!isEmpty(accessorial)) {
  //     const components = accessorial.components || [];
  //     const accComponents = components.map(
  //       (component) => component.component_code,
  //     );
  //     const componentDefs = this.state.orgComponents.filter(
  //       (component) =>
  //         accComponents.includes(component.code) &&
  //         this.state.displayComponents.includes(component.code),
  //     );
  //     return componentDefs;
  //   }
  //   return [];
  // }

  // getOrderAccessorials = () => {
  //   fetchOrderAccessorials(this.state.activeRecordId, "").then((result) => {
  //     if (result.success) {
  //       const orderAccessorial = result.accessorials || {};
  //       const accessorials = orderAccessorial.account_accessorial_params || [];
  //       const authorizeDetails = {};
  //       const allowedIds = {};
  //       this.state.accountComponentAccessorial.forEach(
  //         (accessorial) => {
  //           const components = accessorial.components ?
  //             accessorial.components.map(record => record.component_code)
  //             :
  //             [];
  //           allowedIds[ accessorial.id ] = [ ...components ];
  //         });
  //       const orderAccessorials = setAccessorialResponse(
  //         accessorials,
  //         true,
  //         allowedIds,
  //       );
  //       orderAccessorials.forEach(rec => {
  //         if (!isEmpty(rec.authorized_by)) {
  //           authorizeDetails[ rec.accessorial_code ] = {
  //             authorized_by: rec.authorized_by,
  //             authorization_code: rec.authorization_code,
  //             comments: rec.comments,
  //             is_modified: false,
  //           };
  //         }
  //       });
  //       this.setState({orderAccessorials : orderAccessorials, authorizeDetails : authorizeDetails})
  //     } else {
  //       renderAlertMessage(result.errors);
  //     }
  //   })
  // }

  // /when unmounting send the order numbers in this invoice to parent
  componentWillUnmount() {
    const transportation_charges = _.get(this.state, "invoice.transportation_charges", []) || []
    const orderNumbers = transportation_charges.map(
      (order) => order.order_number
    );
    if(this.props.onUnmount)
      this.props.onUnmount(orderNumbers, this.state.isApproved);
  }

  componentDidMount () {
    const {activeRecordId}=this.state;
    this.getBillingDetails(activeRecordId);
    // this.getOrgComponents();
  }
  
  setSelectedInfo = () => {
    let totalOrders = this.getInvoiceOrders();
    const selectedInfo = {
      selectedKeys: totalOrders.map((order) => order.order_id),
      selectedRows: [...totalOrders],
    };
    this.setState({ selectedInfo });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !_.isEqual(
        prevState.organizationSettings,
        this.props.organizationSettings
      )
    ) {
      this.setState({
        organizationSettings: this.props.organizationSettings,
      });
    }

    // on change if invoice is changed, update current order pics
    if (
    !_.isEqual(prevProps.currentInvoice, this.props.currentInvoice) || 
    !_.isEqual(prevState.invoice, this.state.invoice) && this.state.currentGalleryLocId)
      this.imageAction(this.state.currentGalleryLocId);

      // if(!_.isEqual(prevState.orgComponents, this.state.orgComponents)){
      //   this.getAccountComponentAccessorials();
      // }
  }

  getOrgLogo = () => {
    const orgId = userStore.getStateValue("selectedOrg");
    fetchOrganizationDetails(orgId).then((result) => {
      if (result.success) {
        const organization = result.organization || {};
        organization.logo =
          !isEmpty(organization.logo) && organization.logo.url
            ? organization.logo.url
            : "";

        if (organization.logo) {
          this.setState({
            logo: organization.logo,
          });
        } else if (!isEmpty(orgId)) {
          const urlToFile = `${AppConfig.baseUrl}/org_logos/${orgId}/org_logo.png`;
          fetch(urlToFile).then((response) => {
            if (response.status === 200) {
              this.setState({
                logo: urlToFile,
              });
            }
          });
        }
      }
    });
    // const orgId = userStore.getStateValue("selectedOrg");
    // if (!isEmpty(orgId)) {
    //   const urlToFile = `${AppConfig.baseUrl}/org_logos/${orgId}/org_logo.png`;
    //   fetch(urlToFile)
    //     .then(response => {
    //       if (response.status === 200) {
    //         this.setState({
    //           logo: urlToFile
    //         })
    //       }
    //     });
    // }
  };

  setAccountLos = () => {
    const los =
      this.state.invoice.order_info &&
      this.state.invoice.order_info.level_of_service
        ? this.state.invoice.order_info.level_of_service
        : "";
    this.handleStateOnChange("currentLos", los);
  };

  getAccessorials = () => {
    const account = this.state.invoice.account_details
      ? this.state.invoice.account_details
      : {};
    fetchAccountAccessorials(account.id, "ALL").then((result) => {
      if (result.success) {
        this.setState({
          accessorials: result.accessorials,
          inProgress: false,
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  getAccountLos = async () => {
    const { invoice } = this.state;
    const { userContext } = this.props;
    const accountObj = invoice?.account_details || {};
    const accountCode = accountObj.code || '';
    const accountId = accountObj.id || '';
    const currentOrgId = userContext?.currentOrg?.id || '';
    this.setState({
      losProgress: true,
    })
    try {
        const [losResponse, configResponse] = await Promise.all([
            fetchAccountShortLos(accountCode),
            fetchOrderTypeLos(currentOrgId, accountId),
        ]);
        const losConfig = configResponse?.success ? 
        configResponse.data.los.reduce((acc, rec) => {
            acc[rec.order_type] = rec.los_codes ? [...rec.los_codes, 'NONE'] : ['NONE'];
            return acc;
        }, {}) : {};

        if (losResponse.success) {
            const levelOfServices = losResponse.account.account_los || [];
            this.setState({
                losConfig,
                levelOfServices: [
                    ...levelOfServices,
                    {
                        name: "None",
                        code: "NONE",
                        duration: "0",
                        id: "NONE",
                    },
                ],
                losProgress: false
            });
        } else {
            renderAlertMessage(losResponse.errors);
            this.setState({ losProgress: false, losConfig });
        }
    } catch (error) {
        console.error('Error fetching LOS:', error);
        this.setState({ losProgress: false, losConfig: {} });
    }
};

  getEmails = () => {
    const account = this.state.invoice.account_details
      ? this.state.invoice.account_details
      : {};
    const accountId = account.id ? account.id : ""
    this.setState({ isEmailLoading: true })
    getContactEmails(accountId).then((result) => {
      if (result.success) {
        this.setState({
          contacts: result.contact_emails || [],
          isEmailLoading: false
        })
      }else{
        renderAlertMessage(result.errors)
        this.setState({ isEmailLoading: false, contacts: [] });
      }
    })
  }

  findPriceTotal = () => {
    const { invoice } = this.state;
    let totalAmount = 0;
    let consideredCharges = []
    if (this.state.currentOrderGroupType === "TEMPLATE"){
      consideredCharges = ["transportation_charges"];
    }else{
      consideredCharges = [
        "transportation_charges",
        "fuel_surcharges",
        "accessorial_charges",
        "adjustment_charges",
      ];
    }
    consideredCharges.forEach((descriptionEle) => {
      const priceList = invoice[descriptionEle].map(
        (order) => order.price_list
      );
      const flattenList = _.flatten(priceList);
      const amount = parseFloat(findTotal(flattenList, "invoice_value"));
      totalAmount += amount;
    });
    this.handleOnChange("total_amount", totalAmount);
  };

  findSectionTotal = (sectionType, orderNo) => {
    const { invoice, ikeaChargesrecord } = this.state;
    const charges = [...invoice[sectionType]];
    const elementIndex = _.findIndex(charges, { order_number: orderNo });
    if (elementIndex >= 0) {
      const priceList =
        this.state.currentOrderGroupType === "TEMPLATE"
          ? ikeaChargesrecord
          : !isEmpty(charges[elementIndex].price_list)
          ? charges[elementIndex].price_list
          : [];
      // const flattenList = _.flatten(priceList);
      const amount = findTotal(priceList, "invoice_value", 1, true);
      return amount;
    }
    return 0.0;
  };

  handleOnChange = (element, value, cb = null) => {
    this.setState(
      {
        invoice: Object.assign({}, this.state.invoice, { [element]: value }),
      },
      cb
    )
  };

  editSave = (index, itemType, orderNo, id) => {
    this.setState({  isSaving: true });
    let newInvoiceLines = [];
    const { invoice,selectedInfo } = this.state;
    const {currentOrg} = this.props.userContext
    const charges = [...this.state.invoice[itemType]];
    const elementIndex = _.findIndex(charges, { order_number: orderNo });
    const typeObject = invoice[itemType][elementIndex] || {};
    const priceList = typeObject.price_list || [];

    const invoiceLines = priceList.filter((line) => line.isEditing === true);

      newInvoiceLines = invoiceLines.map((line) => ({
        ...line,
        invoice_type: I18n.t(`invoices.invoice_types.${itemType}`),
      }));
    const orderInfo = invoice.order_info || {};
    const data = {
      invoice_line_id: id,
      new_invoice_lines: newInvoiceLines,
      remark: "",
      order_group_type: invoice.order_group_type,
      customer_order_id: typeObject.order_id || orderInfo.order_id || "",
      reference_id:
        invoice.order_group_type === "ORDER"
          ? invoice.customer_order_id
          : invoice.order_number,
      status: invoice.status,
    };
    const selectedIds = selectedInfo?.selectedKeys
    ? _.compact(selectedInfo.selectedKeys)
    : []

    const reApprovalBilling = {
      customer_order_ids: selectedIds,
      organization_id: _.get(currentOrg, "id", ""),
      invoice_number: _.get(invoice, "order_number", ""),
    }

    const reApproveCall = () => reapproveBillings(reApprovalBilling);
   
    updateInvoiceCharges(data).then((result) => {
      if (result.success) {
        alertMessage(I18n.t("messages.saved"));
        // udpate in the state
        let updatedOrder = _.cloneDeep(invoice[itemType]);
        // replace the old invoice lines with the new ones and turn off editing
        updatedOrder[elementIndex].price_list = updatedOrder[elementIndex].price_list.map((item) =>
        {
          if(item.isEditing){
            return {
              ...item,
              ...newInvoiceLines.find((line) => line.id === item.id),
              isEditing: false,
            }
          }
          return item
        });

        // this.handleOnChange(itemType, updatedOrder, () => {
        //   this.findPriceTotal();
        // });

        this.setState(
          {
            invoice: {
              ...invoice,
              [itemType]: updatedOrder,
            },
            inProgress: false,
            isSaving: false,
          },
          () => {
            this.findPriceTotal();
            // this.getBillingDetails(this.state.invoice.customer_order_id);
            // this.cancelEditItem(index, itemType,id);
            if(invoice.status === "APPROVED"){
              reApproveCall();
            }
          }
        );
      } else {
        alertMessage(result.errors, "error", 10);
        this.setState({ inProgress: false, isSaving: false });
      }
    });
  };

  fuelPercentEdit = (record) => {
    const { item_description } = record
    const match = item_description.match(/[\d.]+/);
    const percentVal = match ? match[0] : "";
    this.setState({
      currentProcessedId: record.id,
      editedFuelPercent: percentVal,
    });
  }

  fuelPercentSave = (record) =>{
    const { editedFuelPercent } = this.state;
    const { item_description } = record
    const match = item_description.match(/[\d.]+/);
    const percentVal = match ? match[0] : "";
    if(editedFuelPercent < 0){
      return alertMessage("Please Enter Positive value.", "error", 10);
    }
    if (!isEmpty(editedFuelPercent)) {
      
      const data = {
        organization_id: userStore.getStateValue("selectedOrg"),
        customer_order_id: this.state.invoice.customer_order_id,
        segment_location_id: record.location_id || "",
        invoice_line_id: record.id,
        fuel_percentage_value: editedFuelPercent,
      };

      if (data.fuel_percentage_value !== percentVal) {
        this.setState({ fuelPerUpdateLoading: true });
        updateFuelPercentage(data).then((result) => {
          if (result.success) {
            alertMessage(result.message, "success", 10);
            this.setState({
              currentProcessedId: "",
              editedFuelPercent: "",
              fuelPerUpdateLoading: false,
            },()=>{
              this.refreshDetails()
            });
          } else {
            alertMessage(result.errors, "error", 10);
            this.setState({
              fuelPerUpdateLoading: false,
              currentProcessedId: "",
              editedFuelPercent: "",
            });
          }
        });
      } else {
        this.setState({
          currentProcessedId: "",
          editedFuelPercent: "",
        });
      }
    } else {
      alertMessage("Please Enter Percent value.", "error", 10);
    }
  }

  onFuelPercentChange = (element, value) =>{
    this.setState({
      [element]: value,
    });
  }

  fuelPercentCancel = (id) =>{
    this.setState({
      currentProcessedId: null,
      editedFuelPercent: "",
    });
  }

  handleKeyDown = (record, e) => {
    if (e.key === "Enter") {
      this.fuelPercentSave(record);
    }
  };


  handleStateOnChange = (element, value, isLosChanged = false) => {
    if (this.state.invoice.status == "APPROVED") {
      if (element == "invoiceLos" && isLosChanged ) { //&& val !== retrivedLos)
        this.props.handleIsLosChanged(true);
      } else {
        this.props.handleIsLosChanged(false);
      }
    }
    if (element === "invoiceLos") {
      this.setState({
        [ element ]: value,
      }, () => {
        if(this.state.invoice.status !== "APPROVED"){
          this.reGenerate();
        }
      });
    } else {
      this.setState({
        [ element ]: value,
      });
    }
  };
  handleLosChange = (order_id, index, location_id,  value, oldValue) => {
    if(isEmpty(value)){
      renderAlertMessage('Please select LoS', 'error', 15)
      return;
    }
    const retrivedLos = this.state?.selectedInfo?.selectedRows[ 0 ]?.level_of_service;
    const val = value ? Object.values(value)[ 0 ] : '';
    const invoiceLos = {...this.state.invoiceLos}
    invoiceLos[order_id][index].level_of_service_code = value
    const isLosChanged  = value !== oldValue;
    this.handleStateOnChange("invoiceLos", invoiceLos, isLosChanged)
  };

  handleItemsChange = (recordNO, element, value, itemType, orderNo) => {
    const charges = [...this.state.invoice[itemType]];
    const index = _.findIndex(charges, { order_number: orderNo });
    if (element === "accessorial_code") {
      this.handleAccessorailChange(recordNO, element, value, itemType, orderNo);
    } else {
      if ((index >= 0 && charges[ index ].price_list[ recordNO ].isNew) || (index >= 0 && charges[ index ].price_list[ recordNO ].isEditing)) {
      this.handleOnItemsChange(recordNO, element, value, itemType, orderNo);
      }
    }

  };

  handleUpdateRecords = (updatedRecords) => {
    this.setState({ ikeaChargesrecord: updatedRecords },()=>{
      this.findIkeaPriceTotal();
    });
  };

  findIkeaPriceTotal = () => {
    const { ikeaChargesrecord } = this.state;
    let totalAmount = 0;

    const amount = parseFloat(findTotal(ikeaChargesrecord, "invoice_value"));
    totalAmount += amount;

    this.handleOnChange("total_amount", totalAmount);
  };

  handleRecordUpdate = (recordNO, itemType, orderNo, data) => {
    const charges = [...this.state.invoice[itemType]];
    const index = _.findIndex(charges, { order_number: orderNo });
    if (index >= 0) {
      charges[index].price_list[recordNO] = data;
      this.handleOnChange(itemType, charges);
    }
  };

  handleOnItemsChange = (recordNO, element, value, itemType, orderNo) => {
    const charges = [...this.state.invoice[itemType]];
    const elementIndex = _.findIndex(charges, { order_number: orderNo });
    if (elementIndex >= 0) {
      charges[elementIndex].price_list[recordNO][element] = value;
      if (element === "accessorial_code" && !isEmpty(value)) {
        const accessorials = this.state.accessorials || [];
        const currentAccRecord = _.find(accessorials, {
          accessorial_code: value,
        });
        if (!isEmpty(currentAccRecord)) {
          charges[elementIndex].price_list[recordNO]["gl_code"] =
            currentAccRecord.gl_code;
        }
      }
      this.handleOnChange(itemType, charges, () => {
        this.findPriceTotal();
      });
    }
  };

  handleAccessorailChange = (recordNO, element, value, itemType, orderNo) => {
    const charges = [...this.state.invoice[itemType]];
    const elementIndex = _.findIndex(charges, { order_number: orderNo });
    if (elementIndex >= 0) {
      const invoiceLines = charges[elementIndex].price_list || [];
      const accessorials = this.state.accessorials || [];
      const currentAccessorial = _.find(accessorials, {
        accessorial_code: value,
      });
      if (
        !isEmpty(currentAccessorial) &&
        !isEmpty(currentAccessorial.standard_code)
      ) {
        const accessorialCodes = accessorials
          .filter(
            (accessorial) =>
              accessorial.standard_code === currentAccessorial.standard_code
          )
          .map((accessorial) => accessorial.accessorial_code);
        const existanceRecords = invoiceLines
          .filter((line) => accessorialCodes.includes(line.accessorial_code))
          .map((line) => line.invoice_name);
        if (existanceRecords.length > 0) {
          confirm({
            title: I18n.t("messages.standard_change_confirm", {
              field: existanceRecords.join(", "),
            }),
            content: <span>{I18n.t("messages.proceed_confirm")}</span>,
            onOk: () => {
              this.handleOnItemsChange(
                recordNO,
                element,
                value,
                itemType,
                orderNo
              );
            },
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onCancel: () => {
              this.handleOnItemsChange(
                recordNO,
                element,
                "",
                itemType,
                orderNo
              );
            },
          });
        } else {
          this.handleOnItemsChange(recordNO, element, value, itemType, orderNo);
        }
      } else {
        this.handleOnItemsChange(recordNO, element, value, itemType, orderNo);
      }
    }
  };

  addItem = (itemType, orderNo,locations) => {
    const { config } = this.state;
    const charges = [...this.state.invoice[itemType]];
    const index = _.findIndex(charges, { order_number: orderNo });
    if (index >= 0) {
      const data = {
        invoice_name: "",
        invoice_value: "",
        invoice_quantity: 1,
        // sign: 'CR',
        isNew: true,
        invoice_type: I18n.t(`invoices.invoice_types.${itemType}`),
        order_item_group_id: null,
        // location_id:locations?.length === 1 ? locations[0].location_id : ''
        location_id : locations.length ? locations[0].location_id : ''
      };

      if (itemType !== "adjustment_charges") {
        data.accessorial_code = "";
      }
      if (itemType === "transportation_charges") {
        data.unit_price = 0.0;
      }

      data.order_id = charges[index].order_id;
      if (itemType === "fuel_surcharges") {
        data.gl_code = config["fuel_surcharge_gl_code"];
      }
      charges[index].price_list.push(data);
      charges[index].price_list = charges[index].price_list.map((item) => {
        item.isEditing = false;
        return item;
      });
      this.setState({
        editedFuelPercent: "",
        currentProcessedId: ""
      })
      this.handleOnChange(itemType, charges);
      if (this.state.currentOrderGroupType === "TEMPLATE") {
        const updatedIkeaChargesRecord = [
          ...this.state.ikeaChargesrecord,
          data,
        ];

        // Convert the array to a Set to remove duplicates, then back to an array
        const uniqueIkeaChargesRecord = Array.from(
          new Set(updatedIkeaChargesRecord.map((item) => JSON.stringify(item)))
        ).map((item) => JSON.parse(item));

        this.setState({
          ikeaChargesrecord: uniqueIkeaChargesRecord,
        });
      }
    }
  };

  editItem = (index, itemType, itemId, record, locations) => {
    if (record && record.isEditing) {
      const { invoice } = this.state;
      const updatedOrder = _.cloneDeep(invoice);
      // find index 
      const priceListIndex = _.findIndex(updatedOrder[itemType], (item) =>
        item.price_list.find((line) => line.id === itemId)
      );
      const priceListToEdit = updatedOrder[itemType][priceListIndex];
      const priceList = priceListToEdit.price_list || [];
      let updatedPriceList = []
      updatedPriceList = priceList.filter((item) => !item.isNew);
      updatedPriceList = updatedPriceList.map((item, i) =>
        i === index ? { ...item, isEditing: true } : { ...item, isEditing: false }
      );

      updatedOrder[itemType][priceListIndex].price_list = updatedPriceList;
      //reset editing of fuel percent
      this.setState({
        editedFuelPercent: "",
        currentProcessedId: "",
      })
      this.handleOnChange(itemType, updatedOrder[itemType]);
    }
  }

  cancelEditItem = (index, itemType, itemId) => {
    const { invoice, rawInvoice } = this.state;
    // Clone the invoices to avoid mutating the state directly
    const updatedOrder = _.cloneDeep(invoice);
    const previousOrder = _.cloneDeep(rawInvoice);
    // Retrieve the price lists from both the updated and previous invoices
    const priceList = updatedOrder[itemType][0].price_list || [];
    const previousPriceList = previousOrder[itemType][0].price_list || [];
    // Map through the price list to update isEditing and restore original data
    const updatedPriceList = priceList.map((item) => {
      if (item.id === itemId) {
        // Set isEditing to false for the item being canceled
        item.isEditing = false;
        // Retrieve the original data from the previous order
        const originalItem = previousPriceList.find(
          (prevItem) => prevItem.id === itemId
        );
        // Return the original data if found, otherwise keep the current data
        return originalItem ? originalItem : item;
      } else {
        // Keep other items unchanged
        return item;
      }
    });
    // Update the price list in the updated order
    updatedOrder[itemType][0].price_list = updatedPriceList;
    this.setState({removeEditingVals: true})
    // Call handleOnChange to update the state with the modified order
    this.handleOnChange(itemType, updatedOrder[itemType], () => {
      this.findPriceTotal();
      this.setState({removeEditingVals: false})
    });
  };



  handleDeleteclick = (recordNO, itemType, orderNo) => {
    const charges = [...this.state.invoice[itemType]];
    const elementIndex = _.findIndex(charges, { order_number: orderNo });
    if (elementIndex >= 0) {
      // const cuttentInvoiceItem = charges[elementIndex].price_list
      //   ? (
      //     itemType !== 'transportation_charges' ?
      //     charges[elementIndex].price_list.sort((a, b) => a.invoice_name ? (a.invoice_name.localeCompare(b.invoice_name)) : 1)[recordNO] :
      //     charges[elementIndex].price_list[recordNO]
      //     )
      //   : {};
      const cuttentInvoiceItem = charges[elementIndex].price_list
      ? 
        charges[elementIndex].price_list[recordNO]
      : {};
      if (cuttentInvoiceItem?.id) {
        if(this.state.currentOrderGroupType === "TEMPLATE"){
          const newIkeaChargesRecord = this.state.ikeaChargesrecord.filter(
            (rec) => rec?.isNew
          );
          this.setState({newIkeaChargesRecord})
        }
        this.handleDeleteInvoiceItem(cuttentInvoiceItem.id);
      } else {
        charges[elementIndex].price_list.splice(recordNO, 1);
          if(this.state.currentOrderGroupType === "TEMPLATE"){
            const updatedIkeaChargesRecord = [...this.state.ikeaChargesrecord];
            updatedIkeaChargesRecord.splice(recordNO, 1);
            this.setState({
              ikeaChargesrecord: updatedIkeaChargesRecord,
            });
          }
        this.setState({
          errors: [],
        });
        this.handleOnChange(itemType, charges, () => {
          this.findPriceTotal();
        });
      }
    }
  };


  getManualBillingDetails = (id, modalElemet) => {
    this.setState({ isLoading: true });
    fetchManualBillingDetails(id).then((result) => {
      if (result.success) {
        const defaultInvoice = {
          description: {},
          account_details: {},
          customer_details: {},
          accessorial_charges: [],
          adjustment_charges: [],
          transportation_charges: [],
          order_info: {},
        };

        let currentInvoice = _.merge(defaultInvoice, result.invoice);
        currentInvoice.customer_order_id = id;
        currentInvoice.remarks = result.remarks || [];
        currentInvoice.status = result.billing_status;

        const error_logs = result.error_logs || [];
        currentInvoice.error_log = error_logs.filter(
          (err_log) => !_.isEmpty(err_log.error_log)
        );
        currentInvoice.error_messages = error_logs.filter(
          (err_log) => !_.isEmpty(err_log.error_message)
        );

        this.setState(
          {
            invoice: currentInvoice,
            isLoading: false,
          },
          () => {
            // if (modalElemet) {
            //   this.handleOnChange(modalElemet, true);
            // }
          }
        );
      } else {
        renderAlertMessage(result.errors);
        this.setState({
          invoice: {},
          isLoading: false,
        });
      }
    });
  };


  // getBillingDetails = (id, modalElemet) => {
  //   this.setState({ inProgress: true });
  //   fetchBillingDetails(id).then((result) => {
  //     if (result.success) {
  //       const currentInvoice = this.handleInvoiceResponse(result, id);
  //       this.setState(
  //         {
  //           // result.orders.customer_order
  //           invoice: currentInvoice,
  //           inProgress: false,
  //         },
  //         () => {
  //           // if (modalElemet) {
  //           //   this.handleOnChange(modalElemet, true);
  //           // }
  //         }
  //       );
  //     } else {
  //       renderAlertMessage(result.errors);
  //       this.setState({
  //         invoice: {},
  //         inProgress: false,
  //       });
  //     }
  //   });
  // };


  refreshDetails = () => {
    if (this.state.invoice.order_group_type === "MANUAL") {
      this.getManualBillingDetails(this.state.invoice.order_number);
    } else {
      this.getBillingDetails(this.state.invoice.customer_order_id);
    }
  };

  handleDeleteInvoiceItem = (id) => {
    this.setState({ inProgress: true });
    deleteInvoiceLine(id).then((result) => {
      if (result.success) {
        alertMessage(I18n.t("messages.deleted"));
        if(this.state.invoice.status === "APPROVED"){
          this.handleSaveAndReapprove();
        }
        this.refreshDetails();
        // this.props.getInvoices();
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  imageAction = (id) => {
    const currentOrderPics = _.find(this.state.invoice.pod_pictures, {
      location_id: id,
    });
    this.setState({
      currentGalleryLocId : id,
      currentOrderPics: !isEmpty(currentOrderPics)
        ? _.cloneDeep(currentOrderPics)
        : {},
    });
  };

  getAccountConfigs = () => {
    this.setState({
      configLoading: true
    })
    const { config, invoice } = this.state;
    fetchAccountConfigs(
      invoice.account_details.id,
      userStore.getStateValue("selectedOrg"),
      "ALL"
    ).then((result) => {
      if (result.success) {
        const accountConfigurations = result.account_configurations || [];
        accountConfigurations.forEach((setting) => {
          config[setting.setting_name] = setting.setting_value;
        });
        if (
          !isEmpty(config.pod_pic_settings) &&
          _.isString(config.pod_pic_settings) &&
          config.pod_pic_settings === "[]"
        ) {
          config.pod_pic_settings = [];
        }
        config.pod_pic_settings = updatePicSettingsWithBol(
          config.pod_pic_settings
        );
        if (isEmpty(config.communication_preference)) {
          config.communication_preference = "SMSES";
        }
        this.setState({
          config,
          configLoading: false,
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({
          configLoading: false,
        });
      }
    });
  };

  onCloseUploadGallery = () => {
    this.setState(
      {
        imagesVisible: false,
        currentOrderPics: {},
        currentGalleryLocId: ""
      },
      () => {
        this.refreshDetails();
      }
    );
  };
  addOrdersToInvoice = () => {
    const payload = {
      organization_id: userStore.getStateValue("selectedOrg"),
      customer_order_id: this.state.invoice.customer_order_id,
      new_customer_order_numbers: this.state.additionalOrders,
    };
    this.setState({
      ordersAddingProgress: true,
    });
    addOrdersForBillingGroup(payload).then((result) => {
      if (result.success) {
        this.setState({
          ordersAddingProgress: false,
          showAddOrders: false,
          additionalOrders: "",
        });
        alertMessage(I18n.t("messages.orders_added"));
        this.refreshDetails();
        // this.props.getBillingDetails(
        //   this.props.currentInvoice.customer_order_id
        // );
      } else {
        renderAlertMessage(result.errors)
        this.setState({
          ordersAddingProgress: false,
        });
      }
    });
  };

  handleSaveReadyForBilling = async () => {
    try {
      const payload = {
        customer_order_ids: [this.props.activeRecordId],
        organization_id: userStore.getStateValue("selectedOrg"),
      };
      let response = {};
      payload.status = this.state.isOnHold == true ? "READY_FOR_BILLING" : "HOLD";
      response = await changeStatusOfInvoicesToHold(payload);
      if (response.success) {
        const result = response?.invoices ? response.invoices : {};
        const successMessage = result?.message
          ? result.message
          : I18n.t("messages.saved");
        //  typeof onSave === 'function' && onSave(I18n.t('messages.saved'))
        alertMessage(successMessage);
        this.props.closeModal();
        this.props.handleSuccess();
      } else {
        throw new Error(response.errors[0]);
      }
    } catch (error) {
      alertMessage(error.message, "error", 10);
    }
  };

  renderAddOrders = () => {
    const additionalOrders = !isEmpty(this.state.additionalOrders)
      ? this.state.additionalOrders
      : "";
    return (
      <BaseModal
        title={I18n.t("general.customer_orders")}
        className="customer-orders-modal"
        onCancel={() => {
          this.setState({ showAddOrders: false, additionalOrders: "" });
        }}
        width="65%"
        maskClosable={false}
      >
        <Spin spinning={this.state.ordersAddingProgress} delay={1000}>
          <div className="tagsInput">
            <Row type="flex" gutter={4}>
              <Col span={22}>
                {/* <Input
              autoFocus
              onChange={(e) => {
                this.setState({ additionalOrders: e.target.value });
              }}
            ></Input> */}
                <TagsInput
                  value={
                    additionalOrders.length ? additionalOrders.split(",") : []
                  }
                  onChange={(orderNums) =>
                    this.setState({ additionalOrders: orderNums.join(",") })
                  }
                  validationRegex={/^[a-zA-Z0-9_-]+$/}
                  onlyUnique
                  addOnPaste
                  pasteSplit={customPasteSplit}
                  inputProps={{
                    placeholder: "Add Orders",
                  }}
                  addOnBlur={true}
                  autoFocus
                />
              </Col>
              <Col span={2}>
                <Button type="primary" onClick={this.addOrdersToInvoice} icon="plus">
                  Add
                </Button>
              </Col>
            </Row>
          </div>
        </Spin>
      </BaseModal>
    );
  };

  handleSendingMails = () =>{
    if (!this.state.billingEmails.length) return alertMessage('Please enter atleast one email address', 'error');

    const account = this.state.invoice.account_details
    ? this.state.invoice.account_details
    : {};    
    
    let totalOrders = this.getInvoiceOrders();
    const selectedInfo = {
      selectedKeys: totalOrders.map((order) => order.order_id),
      selectedRows: [...totalOrders],
    };

    const payload = {
      account_id: account.id,
      customer_order_ids: [...selectedInfo.selectedKeys],
      organization_id: userStore.getStateValue('selectedOrg'),
      email_ids: this.state.billingEmails,
      subject: this.state.subject,
      description: this.state.description,
    };
    this.setState({ isSendingMail: true });
    SendInvoiceEmail(payload).then((result) => {
      if (result.success) {
        alertMessage(result.message, 'success', 5);
        this.setState({ 
        // billingEmails: [],
        //   subject: "",
        //   description: "",
        });
      } else {
        alertMessage(result.message, 'error', 5);
      }
    }
    ).finally(() => { this.setState({ isSendingMail: false }); }
    );
  }

  renderEmailModal = () => {
    const description =  "Description" // I18n.t("general.body")
    const subject = "Subject" // I18n.t("general.subject")
    return (
      <BaseModal
        title={ I18n.t("general.send_to", { from: I18n.t("invoices.billing"), to: "Emails" }) }
        onCancel={ () => this.setState({
          showEmailModal: false,
          billingEmails:[]
        }) }
        width="50%"
        style={ { top: 40 } }
        maskClosable={ false }
      >
        <Spin spinning={this.state.isEmailLoading}>
        <Row className="tagsInput emailForm" type='flex' align='middle' gutter={8}>
          <Col span={ 21 }>
            {/* <FormItem label= {I18n.t('general.email')} require>
              <EmailsInput emails={ this.state.billingEmails } onChange={ this.addBilligEmail } />
            </FormItem> */}
            <FormItem label={I18n.t('general.email')}>
            <Select
                  value = {this.state.billingEmails}
                  onChange={this.addBilligEmail}
                  mode="tags"
                  validationRegex = {this.addBilligEmail}
                  style={{ width: "100%" }}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Enter or select Emails"
                  tokenSeparators={[","]}
                >
                 {
                  this.state.contacts.map(opt => <Option key={opt}>{opt}</Option>)
                 }
                </Select>
                </FormItem>
          </Col>
          <Col span={ 3 } className="alignRight">
              <Button type="primary" loading={ this.state.isSendingMail } icon='mail' onClick={this.handleSendingMails}>
                Send
              </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormItem label={subject}>
              <Input
                rows={4}
                placeholder={subject}
                className="sop-form-textArea"
                value={this.state.subject}
                onChange={(e) => this.setState({subject: e.target.value})}
              />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormItem label={description}>
              <TextArea
                rows={4}
                placeholder={description}
                className="sop-form-textArea"
                value={this.state.description}
                onChange={(e) => this.setState({description: e.target.value})}
              />
            </FormItem>
          </Col>
        </Row>
        </Spin>

      </BaseModal>
    );
  }

  closeDriverPayWindow = () => {
    this.setState(
      {
        currentInvoice:
          this.state.showGroupOrder === true ? this.state.currentInvoice : {},
        showDriverPayForm: false,
      },
      () => {
        this.refreshDetails();
      }
    );
  };


  renderDriverPayModal = () => {
    const currentInvoice = { ...this.state.invoice };
    return (
      <BaseModal
        title={ `${I18n.t("invoices.billing")} #${this.state.invoice.order_number
          }` }
        onCancel={ () => this.closeDriverPayWindow() }
        width="70%"
        style={ { top: 50 } }
        bodyStyle={ { padding: 20 } }
      >
        <DriverPayDetails
          currentInvoice={ currentInvoice }
          isNew={ false }
          onCancel={ () => this.closeDriverPayWindow() }
          onChange={ (element, value) => this.handleOnChange(element, value) }
          getInvoiceDetails={ (orderId) => this.getBillingDetails(orderId) }
          showHeaders={ this.state.showGroupOrder !== true }
          handleOrderDetailsViewClick={ (order_id) => { }
          }
          showRemarks={ false }
          driversList = {this.state.driversList}
        />
      </BaseModal>
    );
  };

  renderUploadGallery = () => {
    return (
      <BaseModal
        title={I18n.t("order.upload_images")}
        onCancel={this.onCloseUploadGallery}
        visible={this.state.imagesVisible}
        width="70%"
        style={{ top: 30 }}
        maskClosable={false}
      >
        <UploadGallery
          config={this.state.config}
          refreshOrder={this.refreshDetails}
          key={this.state.uploadKey}
          closeGallery={this.onCloseUploadGallery}
          uploadApi={uploadPod}
          deleteAttachments={deleteOrderPic}
          currentOrderPics={this.state.currentOrderPics}
          refer={"invoice"}
          invoice={this.state.invoice}
        />
      </BaseModal>
    );
  };

  renderOrderPics = () => {
    const { pod_pictures: podPics } = this.state.invoice;
    if (
      this.state.invoice.order_group_type !== "MANUAL" &&
      podPics &&
      podPics.length > 0
    ) {
      return (
        <Fragment>
          <Spin spinning={this.state.configLoading} tip="Fetching POD's Details...">
          <Row>
            {podPics.map((currentOrder, index) => {
              const signatureImgs = [];
              const printPics = [];
              const pictures = [];
              const showActions = this.state.showActions;
              const orderPictures = currentOrder?.pictures
                ? [...currentOrder.pictures]
                : [];
              let signatureObject = {};
              if (orderPictures.length > 0) {
                orderPictures.forEach((pic) => {
                  if (pic.picture && pic.picture.url) {
                    const formattedImage = pic.picture.url;
                    if (pic.image_type === "normal") {
                      pictures.push(formattedImage);
                    } else if (pic.image_type === "signature") {
                      signatureImgs.push(formattedImage);
                      signatureObject = { ...pic };
                    } else if (pic.image_type === "print") {
                      printPics.push(formattedImage);
                    }
                  }
                });
              }
              const signature =
                signatureImgs.length > 0 ? signatureImgs[0] : "";
              const cardStyle =
                index % 2 == 0 ? { marginRight: "2px" } : { marginLeft: "2px" };
              const orderNo = ["T", "LH"].includes(currentOrder?.type_of_order)
                ? `${currentOrder.customer_order_number}${
                   currentOrder.type_of_loc ? currentOrder.type_of_loc === "PICKUP" ? "(P)" : "(D)" : ''
                  }`
                : `${currentOrder.customer_order_number}`;

              return (
                <Col md={12}>
                  <Card
                    style={cardStyle}
                    size="small"
                    title={`${orderNo} ${I18n.t(
                      "general.pod"
                    )}`}
                    extra={
                      <>
                      {showActions === true && (
                      <div className="center">
                        {checkServiceExistance("NRASOP") && 
                         (
                          <Tooltip title={I18n.t("order.upload_images")}>
                            <img
                              src={photoIcon}
                              onClick={() => {
                                this.setState({ imagesVisible: true });
                                this.imageAction(
                                  currentOrder.location_id
                                );
                              }}
                              style={{ width: 20, height: 20 }}
                              alt="upload"
                              className="marginRight5"
                            />
                          </Tooltip>
                        )}
                        {printPics && printPics.length > 0 && (
                          <Tooltip title="Print Images">
                            <PrintGallery pictures={printPics}/>
                          </Tooltip>
                        )}
                      </div>
                      )}
                      </>
                    }
                  >
                    <Legs
                      pictures={pictures}
                      signature={signature}
                      showActions = {showActions}
                      pagination={{ position: "none" }}
                      size="small"
                      showType="seperate"
                      imageAction={showActions === false ? null : () => {
                        this.setState({ imagesVisible: true });
                        this.imageAction(currentOrder.location_id);
                    }}
                    currentStatus = {this.props.filter}
                    />
                    {!isEmpty(signatureObject) &&
                      _.isObject(signatureObject) &&
                      signatureObject.sign_by && (
                        <Fragment>
                          <Row>
                            <Col xs={24}>
                              <h4 className="alignLeft">
                                {I18n.t("order.sign_by")}{" "}
                                <span className="textCaptilize">
                                  {signatureObject.sign_by}
                                </span>{" "}
                                {!isEmpty(
                                  signatureObject.title_by_relation
                                ) && (
                                  <span className="textCaptilize">
                                    ( {signatureObject.title_by_relation} )
                                  </span>
                                )}
                                {signatureObject.captured_at
                                  ? `at ${formatByTimeConfig(
                                      moment.utc(signatureObject.captured_at),
                                      this.state.organizationSettings[
                                        "is_military_time"
                                      ],
                                      "Do MMM YYYY, HH:mm",
                                      "Do MMM YYYY, hh:mm A"
                                    )} ${
                                      currentOrder.order_tz_short_name
                                        ? currentOrder.order_tz_short_name
                                        : ""
                                    }`
                                  : ""}
                              </h4>
                            </Col>
                          </Row>
                        </Fragment>
                      )}
                  </Card>
                </Col>
              );
            })}
          </Row>
          </Spin>
        </Fragment>
      );
    }
    return <Fragment />;
  };

  handleInputSaveItem = (recordNO, itemType, orderNo, data) => {
    const charges = [...this.state.invoice[itemType]];
    const elementIndex = _.findIndex(charges, { order_number: orderNo });
    if (elementIndex >= 0) {
      this.setState({ isSaving: true }) 
      let priceList = charges[elementIndex].price_list[recordNO]
      priceList.invoice_name = data.invoice_name;
      priceList.gl_code = data.gl_code;
      priceList.invoice_quantity = data.invoice_quantity;
      priceList.invoice_value = data.invoice_value;
      this.handleOnChange(itemType, charges, () => {
        this.findPriceTotal();
      });
      this.handleSaveItem(recordNO, itemType, orderNo)
    }
  }
  handleSaveItem = (index, itemType, orderNo) => {
    const { invoice } = this.state;
    const charges = [...this.state.invoice[itemType]];
    const elementIndex = _.findIndex(charges, { order_number: orderNo });
    if (elementIndex >= 0) {
      const typeObject = invoice[itemType][elementIndex] || {};
      const priceList = typeObject.price_list || [];
      const item = priceList[index];
      const errors = doValidate(requiredFields, item, `Item  ${index + 1}`);
      // if (itemType === "accessorial_charges" && isEmpty(item.accessorial_key)) {
      //   errors.push(
      //     `Item  ${index + 1} ${I18n.t("errors.field_required", {
      //       field: I18n.t("order.accessorial"),
      //     })}`
      //   );
      // }
      if (errors.length > 0) {
        this.setState({ errors, inProgress: false, isSaving: false });
      } else {
        let newInvoiceLines = [];
        const invoiceLines = priceList.filter((line) => line.isNew === true || line.isEditing === true );

        if (itemType === "accessorial_charges") {
          invoiceLines.forEach((line) => {
            const newLine = Object.assign({}, line);
            const accessorialIndex = _.findIndex(this.state.accessorials, [
              "accessorial_code",
              line.accessorial_code,
            ]);
            if (accessorialIndex >= 0) {
              newLine.invoice_name =
                this.state.accessorials[accessorialIndex].accessorial_name;
              newLine.account_accessorial_id =
                this.state.accessorials[accessorialIndex].id;
            }
            newLine.invoice_type =
              itemType === "accessorial_charges" ? "Accessorial" : itemType;
            newInvoiceLines.push(newLine);
          });
        } else {
          newInvoiceLines = [...invoiceLines];
        }
        if (invoice.order_group_type === "MANUAL") {
          const data = {
            new_invoice_lines: newInvoiceLines,
            remark: "",
            order_group_type: invoice.order_group_type,
            invoice_number:
              invoice.order_group_type === "ORDER"
                ? invoice.customer_order_id
                : invoice.order_number,
            status: invoice.status,
            account_id: invoice.account_details.id,
            driver_id: invoice.driver_id,
            warehouse_id: invoice.warehouse_id,
          };
          saveNewInvoice(data).then((result) => {
            if (result.success) {
              alertMessage(I18n.t("messages.saved"));
              this.setState(
                {
                  inProgress: false,
                  errors,
                  isSaving: false
                },
                () => {
                  // this.props.getInvoices();
                }
              );
              this.getManualBillingDetails(
                this.state.invoice.order_number
              );
            } else {
              alertMessage(result.errors, "error", 10);
              this.setState({ inProgress: false, isSaving: false });
            }
          }).then(() => {
            // call save and reapprove if in approval status
            if (invoice.status === "APPROVED") {
              this.handleSaveAndReapprove();
            }
          });

        } else {
          const orderInfo = invoice.order_info || {};
          const data = {
            new_invoice_lines: newInvoiceLines,
            remark: "",
            order_group_type: invoice.order_group_type,
            customer_order_id: typeObject.order_id || orderInfo.order_id || "",
            reference_id:
              invoice.order_group_type === "ORDER"
                ? invoice.customer_order_id
                : invoice.order_number,
            status: invoice.status,
          };
          updateInvoiceLines(data).then((result) => {
            if (result.success) {
              alertMessage(I18n.t("messages.saved"));
              this.setState(
                {
                  inProgress: false,
                  errors,
                  isSaving: false
                },
                () => {
                  // this.props.getInvoices();
                }
              );
              this.getBillingDetails(
                this.state.invoice.customer_order_id
              );
            } else {
              alertMessage(result.errors, "error", 10);
              this.setState({ inProgress: false, isSaving: false });
            }
          }).then(() => {
            // call save and reapprove if in approval status
            if (invoice.status === "APPROVED") {
              this.handleSaveAndReapprove();
            }
          });
        }
      }
    } else {
      this.setState({ isSaving: false }) 
    }
  };

  handleSave = () => {
    const invoice = {
      new_invoice_lines: [],
      remark: this.state.comments || "",
      reference_id:
        this.state.invoice.order_group_type === "ORDER"
          ? this.state.invoice.customer_order_id
          : this.state.invoice.order_number,
      order_group_type: this.state.invoice.order_group_type,
      status: this.state.invoice.status,
    };
    updateInvoice(invoice).then((result) => {
      if (result.success) {
        alertMessage(I18n.t("messages.saved"));
        this.setState(
          {
            inProgress: false,
            comments: "",
          },
          () => {
            // this.props.getInvoices();
          }
        );
        this.closeRemarksModal();
        this.refreshDetails();
      } else {
        alertMessage(result.errors, "error", 10);
        this.setState({ inProgress: false });
      }
    });
  };

  saveInvoiceForm = () => {
    const { invoice } = this.state;
    const accessorial_charges = invoice.accessorial_charges;
    const transportation_charges = invoice.transportation_charges;
    const adjustment_charges = invoice.adjustment_charges;
    const fuel_surcharges = invoice.fuel_surcharges;

    if (
      accessorial_charges[0].price_list.length === 0 &&
      transportation_charges[0].price_list.length === 0 &&
      adjustment_charges[0].price_list.length === 0 &&
      fuel_surcharges[0].price_list.length === 0
    ) {
      alertMessage("Please add invoice line", "error", 10);
    } else {
      const data = {
        invoice: this.state.invoice,
        account_id: this.state.invoice.account_details.id,
        order_group_type: this.state.invoice.order_group_type,
        invoice_number: this.state.invoice.order_number,
        status: this.state.invoice.status,
        reference_id:
          this.state.invoice.order_group_type == "GROUP"
            ? this.state.invoice.order_number
            : this.state.invoice.customer_order_id,
      };
      saveInvoiceDetailsForm(data).then((result) => {
        if (result.success) {
          alertMessage(I18n.t("messages.saved"), 10);
          // this.props.getInvoices();
          if (invoice.order_group_type === "MANUAL") {
            this.getManualBillingDetails(this.state.invoice.order_number);
          } else {
            this.getBillingDetails(this.state.invoice.customer_order_id);
          }
        } else {
          renderAlertMessage(result.errors)
        }
      });
    }
  };

  saveTemplateZoneBilling = () => {
    const { invoice, ikeaChargesrecord } = this.state;
    const transportation_charges = invoice.transportation_charges;
    const priceList = transportation_charges[0].price_list;
    if (transportation_charges[0].price_list.length === 0) {
      alertMessage("Please add invoice line", "error", 10);
    } 
    else if (
      ikeaChargesrecord.some(
        (record) =>
          isEmpty(record.gl_code) ||
          isEmpty(record.invoice_name) ||
          isEmpty(record.invoice_quantity) ||
          isEmpty(record.invoice_value) ||
          isEmpty(record.unit_price) ||
          isEmpty(record.location_id)
      )
    ) {
      let errors = [];

      if (ikeaChargesrecord.some((record) => isEmpty(record.gl_code))) {
        errors.push("GL code can't be empty");
      }
      if (ikeaChargesrecord.some((record) => isEmpty(record.invoice_name))) {
        errors.push("Description can't be empty");
      }
      if (ikeaChargesrecord.some((record) => isEmpty(record.invoice_quantity))) {
        errors.push("Units can't be empty");
      }
      if (ikeaChargesrecord.some((record) => isEmpty(record.invoice_value))) {
        errors.push("Invoice amount can't be empty");
      }
      if (ikeaChargesrecord.some((record) => isEmpty(record.unit_price))) {
        errors.push("Unit price can't be empty");
      }
      if (ikeaChargesrecord.some((record) => isEmpty(record.location_id))) {
        errors.push("Order Type can't be empty");
      }

      // Display errors in alert or handle them as needed
      if (errors.length > 0) {
        renderAlertMessage(errors.join(","), "error", 10)
      }
    }
    else {
      this.setState({
        newIkeaChargesRecord: []
      })
      const data = {
        new_invoice_lines: ikeaChargesrecord?.length
          ? ikeaChargesrecord.map((item) => ({
              ...item,
              invoice_type: "Transportation",
            }))
          : [],
        remarks: invoice.remarks,
        order_group_type: "TEMPLATE",
        customer_order_id: invoice.customer_order_id,
        status: this.state.invoice.status,
        reference_id:
          this.state.invoice.order_group_type == "GROUP"
            ? this.state.invoice.order_number
            : this.state.invoice.customer_order_id,
      };
      saveTemplateZoneForm(data).then((result) => {
        if (result.success) {
          alertMessage(I18n.t("messages.saved"), 10);
          // this.props.getInvoices();
          if (invoice.order_group_type === "MANUAL") {
            this.getManualBillingDetails(this.state.invoice.order_number);
          } else {
            this.getBillingDetails(this.state.invoice.customer_order_id);
          }
        } else {
          renderAlertMessage(result.errors);
        }
      });
    }
  }


  handleSaveAndReapprove = async (selectedInfo = selectedInfo ? selectedInfo : this.state.selectedInfo) => {
    this.setState({ isRepproveLoading : true });
    const selectedIds = selectedInfo?.selectedKeys
      ? _.compact(selectedInfo.selectedKeys)
      : []
      try {
        const payload = {
          customer_order_ids: selectedIds,
          organization_id: userStore.getStateValue("selectedOrg"),
          invoice_number: this.state.invoice.order_number
        };
        if (this.state.is_pickup_by_driver === "true") {
          payload.is_pickup_by_driver = "true";
          payload.pickup_order_driver_id = this.state.pickup_order_driver_id;
          payload.pickup_order_amount = this.state.pickup_order_amount;
        }
        let response = {};
        response = await reapproveBillings(payload);
        if (response.success) {
          const result = response?.invoices ? response.invoices : {};
          const successMessage = result?.message
            ? result.message
            : I18n.t("messages.saved");
          //  typeof onSave === 'function' && onSave(I18n.t('messages.saved'))
          alertMessage(successMessage);
          this.handleApproveSuccess("APPROVED")
          // resetValues();
        } else {
          throw new Error(response.errors[0]);
        }
      } catch (error) {
        alertMessage(error.message, "error", 10);
      } finally {
        this.setState({ isRepproveLoading: false });
      }
  };

  handleReapprove = () => {
    if(this.state.invoice.status === "APPROVED")
    this.handleSaveAndReapprove();
  };

  handleApproveSuccess = (status) => {
    this.setState(
      {
        isApproved: true,
        loading: false,
        invoice: Object.assign({}, this.state.invoice, { status }),
        enableHide: true,
      },
      () => {
        // this.props.onChange("currentInvoice", this.state.invoice);
      }
    );
  };

  handleStatusChange = (status) => {
    const { invoice } = this.state;
    const accessorial_charges = invoice.accessorial_charges;
    const transportation_charges = invoice.transportation_charges;
    const adjustment_charges = invoice.adjustment_charges;

    if (
      (accessorial_charges[0].price_list.length === 0 &&
        transportation_charges[0].price_list.length === 0 &&
        adjustment_charges[0].price_list.length === 0) ||
      (accessorial_charges[0].price_list.find((acc) => acc.id) === undefined &&
        transportation_charges[0].price_list.find((acc) => acc.id) ===
          undefined &&
        adjustment_charges[0].price_list.find((acc) => acc.id) === undefined)
    ) {
      alertMessage("Please add and save invoice line", "error", 10);
    } else {
      this.setState({
        loading: true,
      });
      const data = {
        // order_id: id,
        order_id:
          this.state.invoice.order_group_type === "ORDER"
            ? this.state.invoice.customer_order_id
            : this.state.invoice.order_number,
        order_group_type: this.state.invoice.order_group_type,
        status,
      };
      changeInvoiceStatus(data).then((result) => {
        if (result.success) {
          alertMessage(I18n.t("messages.processed"), 10);
          this.setState(
            {
              loading: false,
              invoice: Object.assign({}, this.state.invoice, { status }),
            },
            () => {
              // this.props.onChange("currentInvoice", this.state.invoice);
            }
          );
        } else {
          renderAlertMessage(result.errors)
          this.setState({
            loading: false,
          });
        }
      });
    }
  };

  // componentWillReceiveProps(nextProps) {
  //   if (!_.isEqual(nextProps.currentInvoice, this.state.invoice)) {
  //     this.setState(
  //       {
  //         invoice: nextProps.currentInvoice,
  //       },
  //       () => {
  //         this.setInvoiceLos();
  //         this.setSelectedInfo();
  //       }
  //     );
  //   }
  // }

  setInvoiceLos = () => {
    const invoiceLos = {};
    const totalOrders = this.getInvoiceOrders();
    totalOrders.forEach((order) => {
      invoiceLos[order.order_id] = [];
      //invoiceLos[order.order_id] = order.level_of_service;
      order?.locations.forEach(loc => {
        invoiceLos[order.order_id].push({
          location_id: loc.location_id,
          level_of_service_code: loc.level_of_service_code,
        });
      })

    });
    this.setState({
      invoiceLos,
    });
  };

  closeRemarksModal = () => {
    this.setState({
      showRemarks: false,
    });
  };

  regenerateInvoiceWithLos = (invoiceLos, cb = null) => {
    this.setState({ isLoading : true });
    const data = {
      organization_id: userStore.getStateValue("selectedOrg"),
      fmp_order_id: getFMPOrderFromInvoice(this.state.invoice),
      account_id: this.state.invoice.account_details.id,
      orders_info: Object.keys(invoiceLos).map((rec) => {
      const nonNoneLos = _.get(_.find(invoiceLos[rec], function(o) { return o.level_of_service_code !== 'NONE' }), "level_of_service_code", "") || "";
      return ({
        customer_order_id: rec,
        los_code: nonNoneLos || _.get(invoiceLos[rec], "[0].level_of_service_code", "") || "",
        locations_info: invoiceLos[rec],

      })
    }),
    };
    message.loading({
      key: "generate",
      content: I18n.t("messages.generating"),
    });
    regenerateInvoiceWithNewLos(data)
      .then((result) => {
        if (result.success) {
          message.success({
            key: "generate",
            content: I18n.t("messages.generated"),
          });
          const currentInvoice = this.handleInvoiceResponse(
            result,
            this.state.invoice.customer_order_id
          );
          this.setState(
            {
              // result.orders.customer_order
              invoice : currentInvoice,
              regenerateProgress: false,
            },
            () => {
              // this.getBillingOrders();
              this.getBillingDetails(this.state.invoice.customer_order_id);
              if (this.state.invoice.status === "APPROVED") {
                cb();
              }
            }
          );
        } else {
          message.error({
            key: "generate",
            content: result.errors.join(","),
          });
        }
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  reGenerate = () => {
    this.props.handleIsLosChanged(false);
    this.regenerateInvoiceWithLos(this.state.invoiceLos , this.handleSaveAndReapprove);
    // this.getBillingDetails(this.state.invoice.customer_order_id)
  };

  showRemarksModal = () => {
    this.setState({
      showRemarks: true,
    });
  };

  renderRemarks = () => (
    <BaseModal
      title={I18n.t("invoices.add_remark")}
      onCancel={() => this.closeRemarksModal()}
    >
      <div>
        <TextArea
          rows={4}
          placeholder={I18n.t("invoices.remarks")}
          className="sop-form-textArea"
          value={this.state.comments}
          onChange={(e) => this.handleRemarksChange("comments", e.target.value)}
        />
        <div style={{ textAlign: "center" }}>
          <Button onClick={this.closeRemarksModal}>
            {I18n.t("general.close")}
          </Button>
          <Button
            onClick={this.handleSave}
            disabled={this.state.disableRemarksButton || this.state.isOnHold}
            className="assignDriver"
            style={{ margin: 10 }}
            type="primary"
            loading={this.state.loading}
          >
            {I18n.t("general.save")}
          </Button>
          <Typography.Paragraph>
            {" "}
            <b>
              {" "}
              <span className="textRed">Note</span>{" "}
            </b>{" "}
            : Remarks should be minimum of 10 characters.{" "}
          </Typography.Paragraph>
        </div>
      </div>
    </BaseModal>
  );

  handleRemarksChange = (element, value) => {
    if (value !== "" && value.length > 2) {
      this.setState({
        [element]: value,
        disableRemarksButton: false,
      });
    } else {
      this.setState({
        [element]: value,
        disableRemarksButton: true,
      });
    }
  };
  showAddOrdersModal = () => {
    this.setState({
      showAddOrders: true,
    });
  };

  getEmailSubject = async () => {
    const orderNumbers = this.state.invoice.transportation_charges.map(
      (charge) => charge.order_number
    );
    const orderIds = this.state.invoice.transportation_charges.map(
      (charge) => charge.order_id
    );
    const orderCount =
      orderNumbers.length > 1
        ? "_(" + (orderNumbers.length - 1) + "_more)"
        : "";
    const orgId = userStore.getStateValue("selectedOrg");

    const defaultSubject = orderNumbers?.length
      ? `${orderNumbers[0]} ${orderCount} ${"BILLING_PDF"}`
      : `${orderNumbers}`;

    const payload = {
      organization_id: orgId,
      order_id: orderIds[0],
      setting_name: "billing_email_subject",
    };

    try {
      const result = await getEmailSubjectLine(payload);

      if (result.success) {
        const emailSubject = result?.subject ? result.subject : defaultSubject;
        this.setState({
          subject: emailSubject,
        });
      } else {
        this.setState({
          subject: defaultSubject,
        });
        alertMessage(result.errors[0], "error", 10);
      }
    } catch (error) {
      console.error("Error fetching email subject:", error);
    } finally {
      this.setState({
        showEmailModal: true,
        description: "Please find the billing attachment",
      });
    }
  };

  showEmailModal = () => {
    // const orderNumbers = this.state.invoice.transportation_charges.map(charge => charge.order_number);
    // const orderCount = orderNumbers.length > 1 ? ('+${orderNumbers.length - 1}') : '';
    // const orderCount = orderNumbers.length > 1 ? ('_(' + (orderNumbers.length - 1) + '_more)') : ''
    this.getEmailSubject()
    this.getEmails();
    // this.setState({
    //   showEmailModal: true,
    //   subject: orderNumbers?.length ? `${orderNumbers[0]} ${orderCount} ${'BILLING_PDF'}` : `${orderNumbers}`,
    //   description: "Please find the billing attachment",
    // });
    // this.getEmails();
  };

  _renderHeaderInfo = ({isLocked,hasConsolidatedOrders,selectedInfo}) => {
    const { invoice, showActions } = this.state;
    const isReadyOnly = invoice.status === "PAYMENT_CONFIRMED";
    const isDriverSettlementApproved = this.state.isDriverSettlementApproved
    // const isLocked = false;
    // const DriverSettlementApproved = true;
    // const hasConsolidatedOrders = true;

    return (
      <InvoiceHeader
        invoice={invoice}
        isReadyOnly={isReadyOnly}
        showActions = {showActions}
        logoUrl={this.state.logo}
        consolidatedOrderNumbers = {this.state.consolidatedOrderNumbers}
        levelOfServices={this.state.levelOfServices || []}
        handleOnChange={this.handleStateOnChange}
        currentLos={this.state.currentLos}
        reGenerate={this.reGenerate}
        showAddOrdersModal={this.showAddOrdersModal}
        showAddOrders={true}
        isLocked={isLocked || this.state.isOnHold}
        isConsolidated = {hasConsolidatedOrders}
        DriverSettlementApproved = {isDriverSettlementApproved}
        // getInvoices={this.props.getInvoices}
        showEmailModal={this.showEmailModal}
        isSendingMail={this.state.isSendingMail}
        selectedInfo={selectedInfo}
        isInvoiceLocked={ this.state.isInvoiceLocked }
        isLoading={this.state.isLoading}
        driversList = {this.state.driversList}
      />
    );
  };

  handleEditOrderClick = (id) => {
    this.setState(
      {
        selectedOrderId: id,
      },
      () => {
        fetchOrderDetails(id).then((result) => {
          if (result.success) {
            const currentOrder =
              result.orders.length > 0 ? _.find(result.orders, { id: id }) : {};
            if (!currentOrder.id) {
              currentOrder.id = id;
            }
            this.setState(
              {
                currentOrder,
              },
              () => {
                this.setState({ editForm: true });
              }
            );
          }
        });
      }
    );
  };


  closeEditFormModal = () => {
    this.setState({
      editForm: false,
      currentOrder: {},
    });
  };


  renderTitle = (order) => {
    const { invoice, config, showActions } = this.state;
    const { showisConsolidation } = this.props;
    const isLocked = order.is_locked == "true" || this.state.isOnHold;
    const isConsolidated = order.is_consolidated === 'true';
    return (
      <Fragment>
        {invoice.order_group_type !== "MANUAL" && (
          <Row type="flex">
            {
              showisConsolidation && isConsolidated  &&
              <Col>
                <ConsolidationButton
                  consolidation_no={order.consolidation_number}
                  consolidate_order_id={order.consolidated_id}
                    handleSuccess={ () => this.getBillingDetails(order.order_id) }
                  displayType="icon"
                  className={"marginLeft10"}
                  label={I18n.t("consolidation.consolidation_details")}
                  orders={[order]}
                  key={`conslodiate${order.order_id}`}
                  styleObj={{ marginRight : 7}}
                />
              </Col>
            }
            <Col>
              <CustomerOrderDetails
              refreshCallback={() =>  this.getBillingDetails(this.state.activeRecordId)}
                isOnHold={this.state.isOnHold}
              key={`bill${order.order_id}`}
              order_no={order.order_no}
              order={{
                id: order.order_id,
                customer_order_number: order.order_no,
                is_smp_applied: order.is_smp_applied === true,
                smp_stamp: order.smp_stamp === "true" ? "true" : "false",
              }}
              showEdit={ this.state.currentOrderGroupType !== "TEMPLATE" && !isLocked }
              editClick={ () => this.handleEditOrderClick(order.order_id) }
              applyFmp={ () => this.applyFmp(order.order_id) }
              showSMP={ !isLocked }
              showFMPLink={ !isLocked && config[ "second_price_type" ] !== "ITEM" }
              style={ { fontSize: 18 } }
              applySmp={ () => this.applySmp(order.order_id) }
              showApplySMP={ !isLocked && invoice.transportation_charges.length === 1 }
            />
            </Col>
            {/* <span
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                fontWeight: "bold",
              }}
              onClick={() =>
                this.props.handleOrderDetailsViewClick(order.order_id)
              }
            >
              {`#${order.order_no}`}
            </span> */}
            {/* {this.renderFMP(order)} */}
          </Row>
        )}
      </Fragment>
    );
  };

  renderApplySMP = (order) => {
    return (
      <Fragment>
        {order.smp_stamp === "true" && !order.is_smp_applied && (
          <Popconfirm
            placement="topRight"
            title={I18n.t("general.are_you_sure")}
            onConfirm={() => this.applySmp(order.order_id)}
          >
            <Button
              type="primary"
              size="small"
              className="textBold fontSize12 "
              style={{ marginTop: 1 }}
              icon='check-circle'
            >
              Apply SMP
            </Button>
          </Popconfirm>
        )}
      </Fragment>
    );
  };

  renderFMP = (order) => {
    return (
      <Fragment>
        {" "}
        {order.smp_stamp === "true" && order.is_smp_applied && (
          <Popconfirm
            placement="topRight"
            title={I18n.t("general.are_you_sure")}
            onConfirm={() => this.applyFmp(order.order_id)}
          >
            <Button
              type="primary"
              size="small"
              className="textBold fontSize12 "
              style={{ marginTop: -2 }}
            >
              Apply&nbsp;
              {showFMP()}
            </Button>
          </Popconfirm>
        )}{" "}
      </Fragment>
    );
  };

  initialChargeValues = (invoice) => {
    if (isEmpty(invoice.transportation_charges)) {
      invoice.transportation_charges = [];
    }
    if (isEmpty(invoice.fuel_surcharges)) {
      invoice.fuel_surcharges = [];
    }
    if (isEmpty(invoice.accessorial_charges)) {
      invoice.accessorial_charges = [];
    }
    if (isEmpty(invoice.adjustment_charges)) {
      invoice.adjustment_charges = [];
    }
    return invoice;
  };

  removeGroupedOrder = (order_id, invoice) => {
    const orgId = userStore.getStateValue("selectedOrg");
    const payload = {
      organization_id: orgId,
      customer_order_id: order_id,
      order_group_type: invoice.order_group_type,
      fmp_order_id: getFMPOrderFromInvoice(invoice),
    };
    this.setState({ isRemovingGroupOrder: true });
    removeOrderFromBillingGroup(payload)
      .then((result) => {
        if (result.success) {
          this.refreshDetails();
          alertMessage(I18n.t("messages.removed_successfully"), 10);
        } else {
          renderAlertMessage(result.errors)
        }
      })
      .finally(() => {
        this.setState({ isRemovingGroupOrder: false });
        // this.props.getInvoices();
      });
  };

  getInvoiceOrders = () => {
    const { invoice } = this.state;
    const orders =
      invoice?.transportation_charges &&
      invoice.transportation_charges.length > 0
        ? invoice.transportation_charges.sort((a,b)=>a.order_number?a.order_number.localeCompare(b.order_number,{numeric:true}):-1)
          .map((order) => ({
            order_id: order.order_id,
            order_no: order.order_number,
            is_smp_applied: order.is_smp_applied,
            hawb: order.hawb,
            mawb: order.mawb,
            level_of_service: order.level_of_service,
            driver_notes: order.driver_notes,
            is_locked: order.is_locked,
            smp_stamp: order.smp_stamp,
            is_consolidated: order.is_consolidated,
            is_driver_settlement_approved : order.is_driver_settlement_approved,
            consolidated_id: order.consolidated_id,
            consolidation_number: order.consolidation_number,
            locations: _.sortBy((order.locations || []), "location_sequence"),
            type_of_order: order.type_of_order,
            instructions : order.instructions || {},
            weight: order.order_weight,
            quantity : order.order_qty,
            surface_area : order.surface_area,
            pallets : order.pallets,
            related_order : order.related_order,
          }))
        : [];
    return orders;
  };

  searchBar = (e) => {
    const value = e.target.value
    const billingActivities = this.state.billingActivitiesList;

    if (!_.isEmpty(_.trim(value))) {
      const lowerValue = _.lowerCase(_.trim(value));
      const filteredActivities = billingActivities.filter(item => {
        const activityValues = Object.values(item);
        return activityValues.some(val => _.lowerCase(val).includes(lowerValue));
      });

      this.setState({
        filteredBillingActivitiesList: filteredActivities
      });
    } else {
      this.setState({
        filteredBillingActivitiesList: billingActivities
      });
    }
  }
  filterOrderNos = (selectedTags) => {
    const {filterOrderIds} = this.state;
    const updatedTags = selectedTags;
    const orderIds = [];
    if (selectedTags.length === 0 && this.state.filteredOrdersForActivities.length === 1) {
      return;
    }
      // Iterate over each tag in updatedTags
      updatedTags.forEach(tag => {
        // Find the corresponding object in filterOrderIds
        const matchingOrder = filterOrderIds.find(order => order.order_number === tag);

        // If a matching object is found, push its orderId into the orderIds array
        if (matchingOrder) {
            orderIds.push(matchingOrder.order_id);
        }
    });
    this.setState({ filteredOrdersForActivities: updatedTags }, () => {
        const parms ={
            organization_id: userStore.getStateValue("selectedOrg"),
            customer_order_ids: orderIds
        };
        getBillingActivities(parms).then(result => {
          if (result.success) {
            this.setState({ billingActivitiesList: result.data.activities, filteredBillingActivitiesList: result.data.activities, })
          }
          else {
            renderAlertMessage(result.errors);
          }
        })
    });
}

  
  applyFmp = (orderId) => {
    const account = this.state.invoice.account_details
      ? this.state.invoice.account_details
      : {};
    const data = {
      organization_id: userStore.getStateValue("selectedOrg"),
      account_id: account?.id ? account.id : "",
      fmp_order_id: orderId,
    };
    this.setState({
      isLoading: true,
    });
    applyFMP(data).then((result) => {
      if (result.success) {
        this.setState({
          isLoading: false,
        });
        const msg = result.message ? result.message : I18n.t("messages.saved");
        this.refreshDetails();
        // this.props.getInvoices();
        alertMessage(msg, 10);
      } else {
        this.setState({
          isLoading: false,
        });
        renderAlertMessage(result.errors)
      }
    });
  };

  applySmp = (orderId) => {
    const data = {
      customer_order_id: orderId,
    };
    this.setState({
      isLoading: true,
    });
    applySMP(data).then((result) => {
      if (result.success) {
        this.setState({
          isLoading: false,
        });
        const msg = result.message ? result.message : I18n.t("messages.saved");
        this.refreshDetails();
        // this.props.getInvoices();
        alertMessage(msg, 10);
      } else {
        this.setState({
          isLoading: false,
        });
        alertMessage(result.errors, "error", 10);
      }
    });
  };

  navigateToBilling = () => {
    this.props.history.replace("/billing");
  }


  goToTab = (key) => {
    this.setState({
      activeKey: key,
    });

    if (key === 'activities') {
      this.setState({ isLoading: true })
      this.setState({filteredOrdersForActivities:this.state.invoice.transportation_charges.map((orders) => orders.order_number)})
      this.setState({filterOrderIds : this.state.invoice.transportation_charges})
      const params = {
        organization_id: userStore.getStateValue("selectedOrg"),
        customer_order_ids: this.state.invoice.transportation_charges.map((orderIds) => orderIds.order_id)
      }
      getBillingActivities(params).then(result => {
        if (result.success) {
          this.setState({ billingActivitiesList: result.data.activities, filteredBillingActivitiesList: result.data.activities, })
        }
        else {
          renderAlertMessage(result.errors);
        }
      }
      ).finally(() => {
        this.setState({ isLoading: false })
      })
    }

  };


  handleOrderSaveSuccess = (
    callbackMessage,
  ) => {
    alertMessage(callbackMessage);
    this.getBillingDetails(this.state.invoice.customer_order_id);
    this.closeEditFormModal();
  };
  

  updateDriverDetails = (updated_driver_info) => {
    this.setState({
      invoice : {
        ...this.state.invoice,
        order_driver_details : updated_driver_info
      }
    })

  }

  render() {
    // if (this.state.isLoading) {
    //   return <ContentOuter message="Loading ..." navigateToBilling={ this.navigateToBilling } />;
    // } else if (!this.state.invoice || Object.keys(this.state.invoice).length === 0) {
    //   return <ContentOuter message="Invoice not found" navigateToBilling={ this.navigateToBilling } />;
    // }

    if (!this.state.invoice || Object.keys(this.state.invoice).length === 0){
      return <ContentOuter/>;
    }
    const invoice = this.initialChargeValues(this.state.invoice);
    let ikeaTotal = null;
    // use lodash to chain get
    const errorMessages = _.get(invoice, "error_messages", []);
    const errorLog = _.get(invoice, "error_log", []);
    const errors = errorMessages.length > 0 ? errorMessages : errorLog;
    const orderGroupType = invoice?.order_group_type;
   
    let totalOrders = this.getInvoiceOrders();
    const { isRemovingGroupOrder } = this.state;
    const orderInfo = invoice.order_info || {};
    // const selectedInfo = {
    //   selectedKeys: [orderInfo.order_id],
    //   selectedRows: [{...orderInfo}],
    // };
    const selectedInfo = {
      selectedKeys: totalOrders.map((order) => order.order_id),
      selectedRows: [...totalOrders],
    };
    if (this.props.showFirstOne === true) {
      const filteredOrders = totalOrders.filter(
        (order) => order.order_id === orderInfo.order_id
      );
      totalOrders = [...filteredOrders];
    }
    const totalList = _.compact([
      ...invoice.transportation_charges,
      ...invoice.fuel_surcharges,
      ...invoice.accessorial_charges,
      ...invoice.adjustment_charges,
    ]);
    const isLocked = totalOrders.filter(order => order.is_locked == 'true').length > 0;
    // const DriverSettlementApproved = totalOrders.filter(order => order.is_driver_settlement_approved === 'true').length > 0;
    const hasConsolidatedOrders = totalOrders.filter(order => order.is_consolidated == 'true').length > 0;
    const isReadyOnly = isLocked || invoice.status === "PAYMENT_CONFIRMED";
    const showActions = invoice.status === 'READY_FOR_BILLING';
    const orderDriversInfo = invoice.order_driver_details || []
    const isInvoiceApproved = invoice.status === "APPROVED"
    const headerTitle = `Billing ${this.state?.invoice?.order_info?.order_number ? `#${this.state.invoice.order_info.order_number}` : ""} `;
    const isTemplateZoneBilling = this.state?.invoice?.order_group_type === "TEMPLATE"
    const InstructionsDisplay = (data) => {
      const instructionsData = _.get(data, 'instructions', {
        notes: '',
        instructions_from_account_1: '',
        instructions_from_account_2: '',
      });
      const driverNotesData = _.get(data, 'driverNotes', '');
      return (
        <div>
          <Row>
            <Col span={ 24 }>
              <Typography.Text strong>Notes : </Typography.Text>
              <Typography.Text>{ instructionsData.notes }</Typography.Text>
            </Col>
          </Row>
          
          <strong>Instructions :</strong>
          <ul style={{marginLeft:30}}>
            {instructionsData.instructions_from_account_1 ? <li  >{instructionsData.instructions_from_account_1}</li> : null}
            {instructionsData.instructions_from_account_2 ? <li  >{instructionsData.instructions_from_account_2}</li> : null}
            {instructionsData.instructions_from_account_3 ? <li  >{instructionsData.instructions_from_account_3}</li> : null}
          </ul>
          {/* <Row>
            <Col span={ 24 }>
              <Typography.Text strong>Instructions 1:</Typography.Text>
              <Typography.Text>{ instructionsData.instructions_from_account_1 }</Typography.Text>
            </Col>
          </Row>
          <Row>
            <Col span={ 24 }>
              <Typography.Text strong>Instructions 2:</Typography.Text>
              <Typography.Text>{ instructionsData.instructions_from_account_2 }</Typography.Text>
            </Col>
            <Col span={ 24 }>
              <Typography.Text strong>Instructions 3:</Typography.Text>
              <Typography.Text>{ instructionsData.instructions_from_account_3 }</Typography.Text>
            </Col>*/}
          <Row>
            <Col span={ 24 }>
              <Typography.Text strong>Driver Notes : </Typography.Text>
              <Typography.Text>{ renderDriverNotes(driverNotesData) }</Typography.Text>
              </Col>
          </Row> 
        </div>
      );
    }

    return (
      <Spin spinning={this.state.isLoading}>
      <div className="content-outer">
        <div className="content indvOrderScreen">
          {/* <Row className="page-header" justify="space-between" type="flex">
            <Col>
              <div>
                <span>
                  <span
                  // onClick={ () => this.gotoInvoices() }
                  >
                    { headerTitle }
                  </span>
                </span>
              </div>
            </Col>
            <Col>
            <div>
                <span>
                  <Icon
                    type="close"
                    onClick={ () => this.navigateToBilling() }
                  />{ " " }
                  &nbsp;
                  <span
                  >
                  </span>
                </span>
              </div>
            </Col>
          </Row> */}
          <div className="billing_indv">
              <Tabs
                onChange={ this.goToTab }
                activeKey={ this.state.activeKey }
                size="small"
                type="card"
                tabBarExtraContent={isLocked ? <Button type="danger" icon="lock" size="small">{I18n.t('general.locked')}</Button> : <Fragment />}
              >
                <TabPane tab={ I18n.t("general.details") } key="details">
              <div>

          <Row gutter={16}>
            {this.props.showHeaders !== false ? (
              <this._renderHeaderInfo isLocked={isLocked} hasConsolidatedOrders = {hasConsolidatedOrders} selectedInfo={selectedInfo}/>
            ) : (
              <div className="alignRight" />
            )}
            <div className="totalOrdersWrapper">
              {totalOrders.map((order, orderIndex) => {
                const locations = order?.locations || [];
                const orderCharges = totalList.filter(
                  (items) => items.order_id === order.order_id
                );
                const totalPriceList = this.state.currentOrderGroupType === "TEMPLATE" ? [...this.state.ikeaChargesrecord] :_.flattenDeep(
                  _.compact(orderCharges.map((item) => [...item.price_list]))
                );
                const jobTotal = findNumberTotal(
                  totalPriceList,
                  "invoice_value",
                  null,
                  this.state.decimalPoints
                );
                ikeaTotal = jobTotal;
                const { invoiceLos, losConfig } = this.state;
                const driverObjects  = _.find(orderDriversInfo, { customer_order_id: order.order_id})
                return (
                  <div
                    key={orderIndex}
                    className={`invoiceOrderItemWrapper order-${order.order_id}`}
                  >
                    <Spin spinning={this.state.isSaving}>
                    <Card
                      size="small"
                      className="marginTop10"
                      title={
                        <Row>
                          <Col span={7}>{this.renderTitle(order)}</Col>
                          {/* <Col span={6} className="fontSize12">
                            {this.renderFMP(order)}
                          </Col> */}
                          {/* <Col span={5} className="fontSize12">
                            {this.renderApplySMP(order)}
                          </Col> */}
                          { orderGroupType !== "TEMPLATE" &&
                            locations.map((loc) => {
                              const losIndex = _.findIndex(invoiceLos[order.order_id], {location_id: loc.location_id})
                              const value = _.get(invoiceLos[order.order_id], `[${losIndex}].level_of_service_code`, '') || ''
                              const levelOfServices = this.state.levelOfServices || [];
                              const type_of_order = !isEmpty(order.related_order) ? 'M' : order.type_of_order;
                              const orderTypeLos = type_of_order && losConfig?.[type_of_order] ? 
                              levelOfServices.filter(rec => losConfig[type_of_order].includes(rec.code)) : 
                              [...levelOfServices]
                              return (<Col span={locations?.length > 1 ? 7 : 10}>
                              {loc.order_type}&nbsp;{I18n.t("los.short_form")}&nbsp;:&nbsp;
                              {checkServiceExistance("LOSI") ? (
                                <Select
                                  size="small"
                                  value={
                                    value
                                  }
                                  showSearch
                                  style={{ width: 180 }}
                                  filterOption={(input, option) =>
                                    option.props.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  onChange={(e) =>
                                    this.handleLosChange(
                                      order.order_id,
                                      losIndex,
                                      loc.location_id,
                                      e,
                                      loc.level_of_service_code
                                  )}
                                  disabled={this.state.isInvoiceLocked || this.state.isOnHold}
                                  loading={this.state.losProgress}
                                >
                                  <Select.Option key="LOS" value="">
                                    -- Select --
                                  </Select.Option>
                                  {orderTypeLos.map((los) => (
                                    <Select.Option
                                      key={los.code}
                                      value={los.code}
                                    >
                                      {los.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              ) : (
                                ""
                              )}
                            </Col>)
                            })
                          }
                          <Col span={3} className="alignRight">
                            {((totalOrders.length > 1 &&
                              invoice.order_group_type == "MOVE") ||
                              invoice.order_group_type == "GROUP") && showActions === true && (
                              <Button
                                loading={isRemovingGroupOrder}
                                size="small"
                                type="danger"
                                onClick={() => {
                                  confirmationPopup({
                                    title: I18n.t("messages.action_confirm", {
                                      action: "remove",
                                      field: "Order from Group",
                                    }),
                                    onConfirm: () => {
                                      this.removeGroupedOrder(
                                        order.order_id,
                                        invoice
                                      );
                                    },
                                  });
                                }}
                                icon='close-circle'
                              >
                                Remove
                              </Button>
                            )}
                          </Col>
                        </Row>
                      }
                      style={{ textAlign: "left" }}
                    >         
                        <Row className="marginBottom10" justify="space-between" type="flex">
                          <Col span={( !isEmpty(order.mawb) ||  !isEmpty(order.hawb) || !isEmpty(order.driver_notes))? 8 : 12}>
                          <>
                              <h4
                                style={{ marginBottom: 0 }}
                                className="textBold"
                              >
                                {" "}
                                {I18n.t("order.details")}{" "}
                              </h4>
                              <Row>
                              <Col span={24}>
                                  <Typography.Text strong>
                                    Weight:
                                  </Typography.Text>
                                  <Typography.Text>
                                    {order.weight
                                      ? `${order.weight} (${AppConfig.weight_unit})`
                                      : "N/A"}
                                  </Typography.Text>
                                </Col>
                                <Col span={24}>
                                  <Typography.Text strong>
                                    Quantity:{" "}
                                  </Typography.Text>
                                  {order.quantity ? (
                                    <OrderQuantity
                                      record={order}
                                      key={`unm${order.order_id}`}
                                      keyId={`un${order.order_id}`}
                                      primaryKey="order_id"
                                      showActions={true}
                                      style={{
                                        textDecoration: "underline",
                                        textDecorationColor: "red",
                                        textDecorationThickness: "3px",
                                      }}
                                    >
                                      {order.quantity}
                                    </OrderQuantity>
                                  ) : (
                                    "N/A"
                                  )}
                                </Col>
                                <Col span={24}>
                                  <Typography.Text strong>
                                    Surface Area:
                                  </Typography.Text>
                                  <Typography.Text>
                                    {order.surface_area ? (
                                      <Fragment>
                                        {order.surface_area} (
                                        {I18n.t("order.surface_area_units")}{" "}
                                        <sup>2</sup>)
                                      </Fragment>
                                    ) : (
                                      "N/A"
                                    )}
                                  </Typography.Text>
                                </Col>
                                <Col span={24}>
                                  <Typography.Text strong>
                                    Skids / Pallets:
                                  </Typography.Text>
                                  <Typography.Text>
                                    {order.pallets || "N/A"}
                                  </Typography.Text>
                                </Col>
                              </Row>
                            </>
                          </Col>
                          <Col className="paddingLeft15 border-left-light" span={( !isEmpty(order.mawb) ||  !isEmpty(order.hawb) || !isEmpty(order.driver_notes))? 8 : 0}>
                            {(!isEmpty(order.mawb) ||
                              !isEmpty(order.hawb) ||
                              !isEmpty(order.driver_notes)) && (
                                <Fragment>
                                  <h4
                                    style={{ marginBottom: 0 }}
                                    className="textBold"
                                  >
                                    {" "}
                                    {I18n.t("order.reference_label")}{" "}
                                  </h4>
                                  {!isEmpty(order.mawb) && (
                                    <Row>
                                      <Col xs={24}>
                                        <span>
                                          {I18n.t("general.mawb")}
                                          &nbsp;&nbsp;:&nbsp;&nbsp;
                                        </span>
                                        <span>
                                          <span className="textBold">{doFormate(order.mawb)}</span>
                                        </span>
                                      </Col>
                                    </Row>
                                  )}
                                  {!isEmpty(order.hawb) && (
                                    <Row>
                                      <Col xs={24}>
                                      <Row type="flex">
                                        <Col>
                                          {I18n.t("general.hawb")}
                                          &nbsp;&nbsp;:&nbsp;&nbsp;
                                        </Col>
                                        <Col>
                                          <Row className="textBold" type="flex" gutter={10}>
                                            <Col>
                                              {doFormate(order.hawb)}
                                            </Col>
                                            {/* and only if it match "week ending as hawb" */}
                                            {this.props.userContext.currentOrg.code == "LYKES" && _.includes(order.hawb.toLowerCase(), "week ending") && <Col>
                                              {this.state.isEditHawbDate ?
                                                // this.renderHawbDatePicker()

                                                <Row type="flex" gutter={ 5 }>
                                                  <Col>
                                                    <DatePicker
                                                      size="small"
                                                      format={ "MMM D" }
                                                      onChange={ this.onHawbDateChange }
                                                      style={ { width: '120px' } }
                                                      allowClear={ false }
                                                      autoFocus
                                                    />
                                                  </Col>
                                                  <Col>
                                                    <Icon type="save" theme="twoTone" className="fontSize14 cusorPointer"
                                                      onClick={ () => this.saveHawbDateChange() }
                                                    />
                                                  </Col>
                                                  <Col>
                                                    <Icon type="close" className="fontSize14 cusorPointer"
                                                      onClick={ () => this.closeHawbDatePicker() }
                                                    />
                                                  </Col>
                                                </Row>

                                                :
                                                <EditIcon handleClick={() => this.handleEditHawbDate(true)} />
                                              }
                                            </Col>}
                                          </Row>
                                        </Col>
                                      </Row>
                                      </Col>
                                    </Row>
                                  )}
                                </Fragment>
                              )}
                          </Col>

                          {driverObjects && (
                            <Col
                            span={( !isEmpty(order.mawb) ||  !isEmpty(order.hawb) || !isEmpty(order.driver_notes))? 8 : 12}
                              className="paddingLeft15 border-left-light"
                            >
                              {orderGroupType === "TEMPLATE" ?
                                <Card size="small" bodyStyle={{ height: 185, fontSize: 12 }}>
                                  <h3 className="main_heading primaryText fontSize16">
                                    {I18n.t("account.details")}
                                  </h3>
                                  <span className="textBold">
                                    <Fragment>{invoice.account_details.name}</Fragment>
                                  </span>
                                  <div>
                                    <Col xs={1}>
                                      <Icon type="environment" />
                                    </Col>
                                    <Col xs={23}>
                                      <InvoiceContact
                                        data={invoice.account_details}
                                        // showIcon
                                        showAddr
                                        displayType="mixFormat"
                                      />
                                    </Col>
                                  </div>
                                </Card>
                              :
                              <BillingDrivers
                                invoice={invoice}
                                currentObj={driverObjects ? driverObjects : {}}
                                isReadyOnly={isReadyOnly}
                                showActions={showActions}
                                driversList = {this.state.driversList}
                                updateDriverDetails={this.updateDriverDetails}
                                // getInvoices={this.props.getInvoices}
                              />}
                            </Col>
                          )}
                        </Row>
                        {Object.values(order.instructions).some(
                          (value) =>
                            value != null &&
                            value !== "" &&
                            value !== undefined
                        ) && (
                            <Row
                            className="border-top-light border-bottom-light paddingBottom15 paddingTop15"
                              xs={12}
                            >
                              <InstructionsDisplay
                                instructions={_.get(order, "instructions")}
                                driverNotes={_.get(order, "driver_notes")}
                              />
                            </Row>
                          )}

                      <Row>
                        {invoice.transportation_charges.length > 0 && (
                          <Col sm={24} xs={24} md={24} lg={24}>
                            <h4>
                              {I18n.t("invoices.charges")}
                              {/* {this.state.invoice.transportation_gl_code
                  ? ` ( ${I18n.t("invoices.gl_code")} : ${
                      this.state.invoice.transportation_gl_code
                    } )`
                  : ""} */}
                            </h4>
                            {invoice.transportation_charges
                              .filter(
                                (section, index) =>
                                  section.order_id === order.order_id
                              )
                              .map((section, index) => (
                                <Fragment>
                                  <Row>
                                    <Col xs={24} className="alignRight">
                                      {section.price_list &&
                                        section.price_list.length === 0 &&
                                        (!this.state.isInvoiceLocked || (!isReadyOnly && showActions === true)) && (!this.state.isOnHold) && (
                                          <Button
                                            type="link"
                                            size="small"
                                            onClick={() =>
                                              this.addItem(
                                                "transportation_charges",
                                                section.order_number,
                                                locations)
                                            }
                                          >
                                            <Icon type="plus-circle" />
                                            &nbsp;
                                            {I18n.t(
                                              "invoices.add_transportation_charges"
                                            )}
                                          </Button>
                                        )}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xs={24}>
                                    {isTemplateZoneBilling ? (
                                      <TransportationChargesList
                                        initializeInputValues
                                        updateRecords={this.handleUpdateRecords} 
                                        defaultTransportationChargeGLCode={this.state.defaultTransportationChargeGLCode}
                                        isInvoiceApproved={isInvoiceApproved}
                                        data={this.state.ikeaChargesrecord || []}
                                        initialInputValues={this.state.ikeaChargesrecord || []}
                                        currentSection={section}
                                        showActions ={showActions}
                                        invoice_type="transportation_charges"
                                        handleOnChange={(
                                          index,
                                          element,
                                          value,
                                          orderNo
                                        ) =>
                                          this.handleItemsChange(
                                            index,
                                            element,
                                            value,
                                            "transportation_charges",
                                            orderNo
                                          )
                                        }
                                        size="small"
                                        pagination={{ position: "none" }}
                                        deleteClick={this.handleDeleteclick}
                                        saveItem={this.handleInputSaveItem}
                                        totalRecords={section.price_list.length}
                                        addItem={this.addItem}
                                        // addNewItem={this.addNewItem}
                                        showAddIcon={!isReadyOnly}
                                        isReadyOnly={isReadyOnly}
                                        isLocked={this.state.isInvoiceLocked || this.state.isOnHold || order.is_locked == "true"}
                                        hasNew={
                                          section.price_list.filter(
                                            (item) => item.isNew === true
                                          ).length === 0
                                        }
                                        footer={() => (
                                          <div
                                            className="alignRight textBold"
                                            style={{ paddingRight: 70 }}
                                          >
                                            {I18n.t("invoices.sub_total")}
                                            :&nbsp;&nbsp;
                                            {this.findSectionTotal(
                                              "transportation_charges",
                                              section.order_number
                                            )}
                                          </div>
                                        )} /* {checkNegitive(invoice.total_amount)} */
                                        bordered={true}
                                        orderData={order}
                                        glCodesList={this.props.glCodesList || []}
                                        showEditIcon={ true }
                                        handleEditItem={this.editItem}
                                        cancelEditItem={this.cancelEditItem}
                                        editSave={this.editSave}
                                        removeEditingVals={this.state.removeEditingVals}
                                        isTemplateZoneBilling={isTemplateZoneBilling}
                                      />
                                    ) : 
                                      (<InvoicesItemList
                                        defaultTransportationChargeGLCode={this.state.defaultTransportationChargeGLCode}
                                        // data={section.price_list ? section.price_list.sort((a, b) => a.invoice_name ? a.invoice_name.localeCompare(b.invoice_name) : 1) : []}
                                        isInvoiceApproved={isInvoiceApproved}
                                        data={section.price_list || []}
                                        currentSection={section}
                                        showActions ={showActions}
                                        invoice_type="transportation_charges"
                                        handleOnChange={(
                                          index,
                                          element,
                                          value,
                                          orderNo
                                        ) =>
                                          this.handleItemsChange(
                                            index,
                                            element,
                                            value,
                                            "transportation_charges",
                                            orderNo
                                          )
                                        }
                                        size="small"
                                        pagination={{ position: "none" }}
                                        deleteClick={this.handleDeleteclick}
                                        saveItem={this.handleInputSaveItem}
                                        totalRecords={section.price_list.length}
                                        addItem={this.addItem}
                                        showAddIcon={!isReadyOnly}
                                        isReadyOnly={isReadyOnly}
                                        isLocked={this.state.isInvoiceLocked || this.state.isOnHold || order.is_locked == "true"}
                                        hasNew={
                                          section.price_list.filter(
                                            (item) => item.isNew === true
                                          ).length === 0
                                        }
                                        footer={() => (
                                          <div
                                            className="alignRight textBold"
                                            style={{ paddingRight: 70 }}
                                          >
                                            {I18n.t("invoices.sub_total")}
                                            :&nbsp;&nbsp;
                                            {this.findSectionTotal(
                                              "transportation_charges",
                                              section.order_number
                                            )}
                                          </div>
                                        )} /* {checkNegitive(invoice.total_amount)} */
                                        bordered={true}
                                        orderData={order}
                                        glCodesList={this.props.glCodesList || []}
                                        showEditIcon={ true }
                                        handleEditItem={this.editItem}
                                        cancelEditItem={this.cancelEditItem}
                                        editSave={this.editSave}
                                        removeEditingVals={this.state.removeEditingVals}
                                      />)
                                    }
                                    </Col>
                                  </Row>
                                </Fragment>
                              ))}
                          </Col>
                        )}
                        {invoice.fuel_surcharges.length > 0 && orderGroupType !=="TEMPLATE" &&(
                          <Col
                            sm={24}
                            xs={24}
                            md={24}
                            lg={24}
                            className="marginTop10"
                          >
                            <h4>{I18n.t("invoice_lines.fuel_surcharges")}</h4>
                            {invoice.fuel_surcharges
                              .filter(
                                (section, index) =>
                                  section.order_id === order.order_id
                              )
                              .map((section, index) => (
                                <Fragment>
                                  <Row>
                                    <Col
                                      xs={24}
                                      className="textBold alignRight"
                                    >
                                      {section.price_list.length === 0 &&
                                        (!this.state.isInvoiceLocked || !isReadyOnly && showActions === true) && (!this.state.isOnHold) && (
                                          <Button
                                            type="link"
                                            size="small"
                                            onClick={() =>
                                              this.addItem(
                                                "fuel_surcharges",
                                                section.order_number,
                                                locations
                                              )
                                            }
                                          >
                                            <Icon type="plus-circle" />
                                            &nbsp;
                                          {I18n.t("invoices.add_fuel_surcharges")}
                                          </Button>
                                        )}
                                    </Col>
                                    <Col xs={24}>
                                      <InvoicesItemList
                                      // data={section.price_list ? section.price_list.sort((a, b) => {
                                      //   if (a.isNew && !b.isNew) {
                                      //     return 1;
                                      //   } else if (!a.isNew && b.isNew) {
                                      //     return -1;
                                      //   } else {
                                      //     return a.invoice_name ? a.invoice_name.localeCompare(b.invoice_name) : 1;
                                      //   }
                                      // }) : []}
                                        data={section.price_list || []}
                                        // showActionsWhenApproved={showActionsWhenApproved}
                                        currentSection={section}
                                        showActions ={showActions}
                                        invoice_type="fuel_surcharges"
                                        handleOnChange={(
                                          index,
                                          element,
                                          value,
                                          orderNo
                                        ) =>
                                          this.handleItemsChange(
                                            index,
                                            element,
                                            value,
                                            "fuel_surcharges",
                                            orderNo
                                          )
                                        }
                                        size="small"
                                        pagination={{ position: "none" }}
                                        deleteClick={this.handleDeleteclick}
                                        saveItem={this.handleInputSaveItem}
                                        totalRecords={section.price_list.length}
                                        addItem={this.addItem}
                                        showAddIcon={!isReadyOnly}
                                        isReadyOnly={isReadyOnly}
                                        isLocked={this.state.isInvoiceLocked || this.state.isOnHold || order.is_locked == "true"}
                                        hasNew={
                                          section.price_list.filter(
                                            (item) => item.isNew === true
                                          ).length === 0
                                        }
                                        footer={() => (
                                          <div
                                            className="alignRight textBold"
                                            style={{ paddingRight: 70 }}
                                          >
                                            {I18n.t("invoices.sub_total")}
                                            :&nbsp;&nbsp;
                                            {this.findSectionTotal(
                                              "fuel_surcharges",
                                              section.order_number
                                            )}
                                          </div>
                                        )} /* {checkNegitive(invoice.total_amount)} */
                                        bordered
                                        orderData={order}
                                        glCodesList={this.props.glCodesList || []}
                                        showEditIcon={ true }
                                        handleEditItem={this.editItem}
                                        cancelEditItem={this.cancelEditItem}
                                        editSave={this.editSave}
                                        removeEditingVals={this.state.removeEditingVals}
                                        fuelPercentEdit={this.fuelPercentEdit}
                                        currentProcessedId={this.state.currentProcessedId}
                                        fuelPercentSave={this.fuelPercentSave}
                                        onFuelPercentChange={this.onFuelPercentChange}
                                        editedFuelPercent={this.state.editedFuelPercent}
                                        fuelPercentCancel={this.fuelPercentCancel}
                                        handleKeyDown={this.handleKeyDown}
                                      />
                                    </Col>
                                  </Row>
                                </Fragment>
                              ))}
                          </Col>
                        )}
                        {checkServiceExistance("AAAAC") &&
                          invoice.accessorial_charges.length > 0 && orderGroupType !=="TEMPLATE" &&(
                            <Col
                              sm={24}
                              xs={24}
                              md={24}
                              lg={24}
                              className="marginTop10"
                            >
                              <h4>{I18n.t("invoices.accessorial_charges")}</h4>
                              {invoice.accessorial_charges
                                .filter(
                                  (section, index) =>
                                    section.order_id === order.order_id
                                )
                                .map((section) => (
                                  <Row>
                                    <Col
                                      xs={24}
                                      className="textBold alignRight"
                                    >
                                      {section.price_list.length === 0 &&
                                        (!this.state.isInvoiceLocked || !isReadyOnly && showActions === true) && (!this.state.isOnHold) && (
                                          <Button
                                            type="link"
                                            size="small"
                                            onClick={() =>
                                              this.addItem(
                                                "accessorial_charges",
                                                section.order_number,
                                                locations
                                              )
                                            }
                                          >
                                            <Icon type="plus-circle" />
                                            &nbsp;{I18n.t("accessorials.add")}
                                          </Button>
                                        )}
                                    </Col>
                                    <Col xs={ 24 }>
                                      <AccessorialCharges
                                        reapproveBilling={this.handleReapprove}
                                        invoice={invoice}
                                        accessorials={ this.state.accessorials }
                                        bordered={true}
                                        isMilitaryTime={this.state.organizationSettings.is_military_time}
                                        technicians={ this.state.technicians }
                                        drivers={ this.state.drivers }
                                        invoice_type="accessorial_charges"
                                        currentSection={section}
                                        orgId={ userStore.getStateValue("selectedOrg") }
                                        accountId={ invoice.account_details?.id }
                                        orderId={ order.order_id }
                                        showActions ={showActions}
                                        reqData={this.state.accessorials}
                                        orgrules={ _.get(this.props, 'userContext.orgRules.org_billing_rules', {})}
                                        // data={_.get(section, 'price_list', [])}
                                        // data={section.price_list || []}
                                        data={_.sortBy(section.price_list, [(item) => (item?.invoice_name ? item.invoice_name : -1)]) || []}
                                        handleRecordUpdate={(record, index , currentOrderNo) => this.handleRecordUpdate(index ,"accessorial_charges", currentOrderNo , record)}
                                        handleOnChange={(
                                          index,
                                          element,
                                          value,
                                          orderNo
                                        ) =>
                                          this.handleItemsChange(
                                            index,
                                            element,
                                            value,
                                            "accessorial_charges",
                                            orderNo
                                          )
                                        }
                                        size="small"
                                        pagination={{ position: "none" }}
                                        deleteClick={this.handleDeleteclick}
                                        saveItem={this.handleInputSaveItem}
                                        totalRecords={section.price_list.length}
                                        addItem={this.addItem}
                                        showAddIcon={!isReadyOnly}
                                        isReadyOnly={isReadyOnly}
                                        isLocked={this.state.isInvoiceLocked || this.state.isOnHold || order.is_locked == "true"}
                                        hasNew={
                                          section.price_list.filter(
                                            (item) => item.isNew === true
                                          ).length === 0
                                        }
                                        footer={() => (
                                          <div
                                            className="alignRight textBold"
                                            style={{ paddingRight: 70 }}
                                          >
                                            {I18n.t("invoices.sub_total")}
                                            :&nbsp;&nbsp;
                                            {this.findSectionTotal(
                                              "accessorial_charges",
                                              section.order_number
                                            )}
                                          </div>
                                        )}
                                        orderData={order}
                                        glCodesList={this.props.glCodesList || []}
                                        // refreshParentDetails={this.getBillingDetails}
                                        refreshParentDetails={() => this.getBillingDetails(this.state.invoice.customer_order_id)}

                                        showEditIcon={ true }
                                        handleEditItem={this.editItem}
                                        cancelEditItem={this.cancelEditItem}
                                        editSave={this.editSave}
                                        removeEditingVals={this.state.removeEditingVals}
                                        refreshAccessorial={this.getAccessorials}
                                        orgComponents = {this.state.orgComponents}
                                        displayComponents={this.state.displayComponents}
                                        accountComponentAccessorial={this.state.accountComponentAccessorial}
                                        orderAccessorials = {this.state.orderAccessorials}
                                        authorizeDetails = {this.state.authorizeDetails}
                                        slotConfig = {this.state.slotConfig}
                                      />

                                    </Col>
                                  </Row>
                                ))}
                            </Col>
                          )}
                        {invoice.adjustment_charges.length > 0 && orderGroupType !=="TEMPLATE" &&(
                          <Col
                            sm={24}
                            xs={24}
                            md={24}
                            lg={24}
                            className="marginTop10"
                          >
                            <h4>
                              {I18n.t("invoices.adjustments")}
                              {/* {this.state.invoice.transportation_gl_code
                  ? ` ( ${I18n.t("invoices.gl_code")} : ${
                      this.state.invoice.transportation_gl_code
                    } )`
                  : ""} */}
                            </h4>
                            {invoice.adjustment_charges
                              .filter(
                                (section, index) =>
                                  section.order_id === order.order_id
                              )
                              .map((section, index) => (
                                <Fragment>
                                  <Row>
                                    <Col xs={24} className="alignRight">
                                      {section.price_list &&
                                        section.price_list.length === 0 &&
                                        (!this.state.isInvoiceLocked || (!isReadyOnly && showActions === true)) && (!this.state.isOnHold) && (
                                          <Button
                                            type="link"
                                            size="small"
                                            onClick={() =>
                                              this.addItem(
                                                "adjustment_charges",
                                                section.order_number,
                                                locations
                                              )
                                            }
                                          >
                                            <Icon type="plus-circle" />
                                            &nbsp;
                                            {I18n.t("invoices.add_charge")}
                                          </Button>
                                        )}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xs={24}>
                                      <InvoicesItemList
                                        // data={section.price_list ? section.price_list.sort((a, b) => {
                                        //   if (a.isNew && !b.isNew) {
                                        //     return 1;
                                        //   } else if (!a.isNew && b.isNew) {
                                        //     return -1;
                                        //   } else {
                                        //     return a.invoice_name ? a.invoice_name.localeCompare(b.invoice_name) : 1;
                                        //   }
                                        // }) : []}
                                        data={section.price_list || []}
                                        currentSection={section}
                                        showActions ={showActions}
                                        invoice_type="adjustment_charges"
                                        handleOnChange={(
                                          index,
                                          element,
                                          value,
                                          orderNo
                                        ) =>
                                          this.handleItemsChange(
                                            index,
                                            element,
                                            value,
                                            "adjustment_charges",
                                            orderNo
                                          )
                                        }
                                        size="small"
                                        pagination={{ position: "none" }}
                                        deleteClick={this.handleDeleteclick}
                                        saveItem={this.handleInputSaveItem}
                                        totalRecords={section.price_list.length}
                                        addItem={this.addItem}
                                        showAddIcon={!isReadyOnly}
                                        isReadyOnly={isReadyOnly}
                                        isLocked={this.state.isInvoiceLocked || this.state.isOnHold || order.is_locked == "true"}
                                        hasNew={
                                          section.price_list.filter(
                                            (item) => item.isNew === true
                                          ).length === 0
                                        }
                                        footer={() => (
                                          <div
                                            className="alignRight textBold"
                                            style={{ paddingRight: 70 }}
                                          >
                                            {I18n.t("invoices.sub_total")}
                                            :&nbsp;&nbsp;
                                            {this.findSectionTotal(
                                              "adjustment_charges",
                                              section.order_number
                                            )}
                                          </div>
                                        )} /* {checkNegitive(invoice.total_amount)} */
                                        orderData={order}
                                        glCodesList={this.props.glCodesList || []}
                                        showEditIcon={ true }
                                        handleEditItem={this.editItem}
                                        cancelEditItem={this.cancelEditItem}
                                        editSave={this.editSave}
                                        removeEditingVals={this.state.removeEditingVals}
                                      />
                                    </Col>
                                  </Row>
                                </Fragment>
                              ))}
                          </Col>
                        )}
                      </Row>
                    </Card>
                    </Spin>

                    <Row className="marginTop15">
                      <Col
                        xs={24}
                        className="textBold alignRight textPrimary fontSize18"
                        style={{ paddingRight: 85 }}
                      >
                        Job Total: {checkNegitive(jobTotal)}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24}>
                        <Divider />
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </div>
              <Col sm={ 24 } xs={ 24 } md={ 24 } lg={ 24 } className="marginTop10">
                <Row>
                <Col className="alignRight">
                  <h4
                    className="textBold fontSize20"
                    style={{ paddingRight: 85, marginTop: 20 }}
                  >
                    {I18n.t("invoices.total")}:{" "}
                    {this.state.currentOrderGroupType === "TEMPLATE" ? checkNegitive(ikeaTotal) : checkNegitive(invoice.total_amount)}
                  </h4>
                </Col>
              </Row>
            </Col>
            <Col xs={24}>{FormErrors(this.state.errors)}</Col>
              { errors.length > 0 && (
              <Col xs={24} className="marginTop10">
                <FormItem label={I18n.t("general.exceptions")}>
                  <div className="borderLine padding5">
                      { _.isString(errors[ 0 ])
                        ? FormattedErrors(errors, I18n.t("general.exceptions"))
                      : <Fragment />}
                  </div>
                </FormItem>
                {false && (
                  <Alert message={invoice.error_messages} type="error" />
                )}
              </Col>
            )}

            {invoice.remarks.length > 0 && (
              <Col sm={24} xs={24} md={24} lg={24} className="marginTop10">
                <FormItem label={I18n.t("general.notes")}>
                  <Remarks data={invoice.remarks || []} />
                </FormItem>
              </Col>
            )}
            {invoice.status === "EXCEPTION" && (
              <Row>
                <Col xs={24} className="paddingLeft5">
                  <Fragment>
                    <span className="fontSize12">Exception:</span>&nbsp;
                    <span className="textRed notes_content fontsize11">
                      {invoice.exception_message}
                    </span>
                  </Fragment>
                </Col>
              </Row>
            )}
            {!isReadyOnly && (
              <Fragment>
                {!this.state.showRemarks && showActions === true  ? (
                  <Col xs={24} className="alignRight">
                    <Button
                      type="link"
                      size="small"
                      onClick={() => this.showRemarksModal()}
                    >
                      <Icon type="plus-circle" />
                      &nbsp;{I18n.t("invoices.add_remark")}
                    </Button>
                  </Col>
                ) : (
                  <Fragment>
                    {showActions === true && (
                    <Col xs={24} className="marginTop10">
                      <FormItem
                        label={`${I18n.t("general.add")} ${I18n.t(
                          "general.notes"
                        )}`}
                      >
                        <TextArea
                          rows={4}
                          placeholder={I18n.t("general.notes")}
                          className="sop-form-textArea"
                          value={this.state.comments}
                          onChange={(e) =>
                            this.handleRemarksChange("comments", e.target.value)
                          }
                        />
                        {checkServiceExistance("BLUIND") && !isLocked && showActions === true && (
                          <div style={{ textAlign: "center" }}>
                            <Button onClick={this.closeRemarksModal} icon='close'>
                              {I18n.t("general.cancel")}
                            </Button>
                            <Button
                              onClick={this.handleSave}
                              disabled={this.state.disableRemarksButton || this.state.isOnHold}
                              className="assignDriver"
                              style={{ margin: 10 }}
                              loading={this.state.loading}
                              icon='save'
                            >
                              {I18n.t("general.save")}
                            </Button>
                          </div>
                        )}
                      </FormItem>
                    </Col>
                    )}
                  </Fragment>
                )}
              </Fragment>
            )}
          </Row>
          {!this.state.showRemarks && !isLocked && (
              <Row> 
              <Col xs={24} className="alignCenter marginTop15 billingActions">
                {/* {checkServiceExistance(["BLUS", "BLSIND"], "ANY") &&
                (showActions === true || isInvoiceApproved && !isLocked ) &&  (
                  <Button
                      onClick={() => {
                        this.props.onCancel();
                        if (invoice.status === "APPROVED"){
                          // this.handleSaveAndReapprove(selectedInfo);
                          // this.props.getInvoices();
                        }
                      }}
                      icon="close"
                    >
                      {I18n.t("general.close")}
                    </Button>
                )}{" "} */}
                  <Button type="primary" onClick={this.props.closeModal} icon='close'>
                    Close
                  </Button>
                          {this.state.isOnHold &&
                            <>
                              &nbsp;&nbsp;
                              <Popconfirm
                                placement="topRight"
                                title={I18n.t("messages.confirm")}
                                onConfirm={()=>this.handleSaveReadyForBilling()}
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button type="primary" icon="file-done">Ready For Billing</Button>
                              </Popconfirm>
                            </>
                          }
                          &nbsp;&nbsp;
                          {
                            this.props.organizationSettings.show_hold_bucket == "true" &&
                            this.state.invoice.status == "READY_FOR_BILLING" &&
                            window.location.href.split("/").pop() == "billing" &&
                            !this.state.isOnHold && (
                              <>
                                  <Button className="buttonYellow" icon="pause-circle" onClick={() => this.handleSaveReadyForBilling()}>
                                    Hold
                                  </Button>
                                &nbsp;&nbsp;
                              </>
                            )
                          }
                {checkServiceExistance("BLSIND") &&
                showActions === true && (
                  <Button type="primary" onClick={isTemplateZoneBilling ? this.saveTemplateZoneBilling : this.saveInvoiceForm}  icon='save'>
                    Save
                  </Button>
                )}
                { isInvoiceApproved && !isLocked && (
                  <Button type="primary" onClick={isTemplateZoneBilling ? this.saveTemplateZoneBilling : () => this.handleSaveAndReapprove(selectedInfo)}  icon='save' loading={this.state.isRepproveLoading}>
                    Save & Re-Approve
                  </Button>
                )}
                &nbsp;&nbsp;
                { showActions === true && !hasConsolidatedOrders && checkServiceExistance("BLUS") && !isLocked &&
                  !isReadyOnly &&
                  invoice.status === "APPROVED" && (
                    <Fragment>
                      <ApproveInvoices
                        selectedInfo={{ ...selectedInfo }}
                        action={
                          invoice.status === "READY_FOR_BILLING"
                            ? "APPROVED"
                            : "REAPPROVED"
                        }
                        buttonType="danger"
                        isDisabled={!(selectedInfo.selectedKeys.length > 0)}
                        showActions = {showActions}
                        label={
                          invoice.status === "READY_FOR_BILLING"
                            ? I18n.t("invoices.approve")
                            : I18n.t("invoices.reapprove")
                        }
                        handleSuccess={() =>
                          this.handleApproveSuccess("APPROVED")
                        }
                        handleCancel={null}
                        screenFrom="form"
                        is_pickup_by_driver={this.state.is_pickup_by_driver}
                        pickup_order_driver_id={
                          this.state.pickup_order_driver_id
                        }
                        pickup_order_amount={this.state.pickup_order_amount}
                      />
                    </Fragment>
                  )}
                &nbsp;&nbsp;
                {showActions === true && !hasConsolidatedOrders && checkServiceExistance("BLUS") && !isLocked && 
                  !isReadyOnly &&
                  !["EXCEPTION", "APPROVED"].includes(invoice.status) && (
                    <Fragment>
                      <ApproveInvoices
                        selectedInfo={{ ...selectedInfo }}
                        action={
                          invoice.status === "READY_FOR_BILLING"
                            ? "APPROVED"
                            : "REAPPROVED"
                        }
                        buttonType="danger"
                        isDisabled={!(selectedInfo.selectedKeys.length > 0)}
                        label={
                          invoice.status ===
                          I18n.t("invoices.ready_for_billing")
                            ? I18n.t("invoices.approve")
                            : invoice.status === "APPROVED"
                            ? I18n.t("invoices.payment_confirmed")
                            : I18n.t("invoices.close")
                        }
                        handleSuccess={() =>
                          this.handleApproveSuccess("APPROVED")
                        }
                        handleCancel={null}
                        screenFrom="form"
                        is_pickup_by_driver={this.state.is_pickup_by_driver}
                        pickup_order_driver_id={
                          this.state.pickup_order_driver_id
                        }
                        pickup_order_amount={this.state.pickup_order_amount}
                        userContext={this.props.userContext}
                      />
                    </Fragment>
                  )}
                  {showActions === true && (
                    <BillingPdfDownload
                      className={"buttonCoolBlue marginLeft10"}
                      buttonType="primary"
                      size="default"
                      label={I18n.t("general.print")}
                      isDisabled={selectedInfo.selectedKeys.length === 0}
                      handleSuccess={() => {}}
                      orderIds={[...selectedInfo.selectedKeys]}
                    />
                  )}
              </Col>
            </Row>
          )}
            <Row className="marginTop20" style={ {
              paddingBottom: 20,
            } }>
            <this.renderOrderPics />
          </Row>
                  </div>
                </TabPane>
                {!this.state.isOnHold && orderGroupType !=="TEMPLATE" && <TabPane tab="Driver Pays" key="driver_pay">
                  <BillingDriverPay
                    drivers={ this.state.drivers }
                    currentInvoice={ this.state.invoice }
                    isLocked={ isLocked }
                    glCodesList={this.props.glCodesList}
                    updateParentComponent={this.handleUpdateInvoice}
                  />
                </TabPane>}
                <TabPane tab={I18n.t("order.activities")} key="activities">
                  {!_.isEmpty(this.state.billingActivitiesList) ?
                    <Row type="flex" gutter={[16, 16]}>
                      <Col span={12}>
                        <Search
                          onChange={this.searchBar}
                          placeholder={I18n.t("general.search")}
                          // onSearch={value => this.searchBar(value)}
                          style={{ width: '100%' }}
                        />
                      </Col>
                      <Col span={12}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <label
                            style={{ marginRight: "10px", marginTop: "2px" }}
                          >
                            Order(s):
                          </label>
                          <Select
                            className={
                              this.state.filteredOrdersForActivities.length ===
                              1
                                ? "one-tag"
                                : ""
                            }
                            value={this.state.filteredOrdersForActivities}
                            onChange={(value) => this.filterOrderNos(value)}
                            mode="multiple"
                            maxTagCount={3}
                            style={{ width: "80%" }} // Reduced width
                            showSearch
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {this.state.filterOrderIds.map((order) => (
                              <Select.Option
                                key={order.order_number}
                                value={order.order_number}
                              >
                                {order.order_number}
                              </Select.Option>
                            ))}
                          </Select>
                        </div>
                      </Col>
                      <Col span={24}>
                        <BillingActivitiesList
                          data={
                            !_.isEmpty(this.state.filteredBillingActivitiesList)
                              ? this.state.filteredBillingActivitiesList
                              : ''
                          }
                        />
                      </Col>
                    </Row>
                    : <div style={{ textAlign: "center" }}>No Activies Recorded</div>}

                </TabPane>
              </Tabs>



          {this.state.imagesVisible && this.renderUploadGallery()}
              { this.state.showDriverPayForm && this.renderDriverPayModal() }
          {this.state.showAddOrders && this.renderAddOrders()}
          {this.state.showEmailModal && this.renderEmailModal()}


            { this.state.editForm && (
              <Drawer
                placement="right"
                closable={ false }
                maskClosable={ false }
                onClose={ this.closeEditFormModal }
                visible={ this.state.editForm }
                width="90%"
              >
                <EditOrderFormComponent
                  customerDetails={ this.state.currentOrder }
                  customerOrderId={ this.state.currentOrder.id }
                  preferences={ [] }
                  onCancel={ this.closeEditFormModal }
                  handleSuccess={ (message, isOutOfDispatch, isModified, newData) =>
                    this.handleOrderSaveSuccess(
                      message,
                      isOutOfDispatch,
                      isModified,
                      newData
                    )
                  }
                  currentRoute={ this.state.selectedRouteObject }
                  isNew={ false }
                  tab="accessorials"
                />
              </Drawer>
            ) }

          </div>
        </div>
      </div>
      </Spin>
    );
  }
}

Form.propTypes = {
  account: PropTypes.shape().isRequired,
  currentInvoice: PropTypes.shape().isRequired,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  getInvoiceDetails: PropTypes.func.isRequired,
  showHeaders: PropTypes.bool.isRequired,
  onUnmount : PropTypes.func.isRequired,
  handleIsLosChanged : PropTypes.func.isRequired,
};

Form.defaultProps = {
  handleIsLosChanged : () => {},
};


export const BillingForm = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const { orgSettings: organizationSettings, glCodesList } = useContext(OrgContext);
  const userContext = useContext(UserContext);

  return (
    <Form
      warehouseFilter={ warehouseFilter }
      organizationSettings={ organizationSettings }
      userContext={ userContext }
      glCodesList={ glCodesList }
      { ...props }
    />
  );
});

export default withRouter(BillingForm);