import { Call, ApiUrl, PageParams, ParseGeneralResponse } from "./ApiUtils";
import AppConfig from "../config/AppConfig";

export function fetchAccountSummary(data, page = 1, perPage = AppConfig.perPage) {
  const {account_id , customer_order_number, status} = data;
  const url = ApiUrl(`v2/billing/account_customer_order_billings?${Object.keys(data).map(key => `${key}=${data[key]}`).join('&')}&${PageParams(page, perPage)}&operation_code=ACSOB`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      invoices: "account_billings",
      stats: "count",
      pagination: "meta.pagination",
    },
  });
}

export function fetchInvoicesWithFilter(data, searchKeyType, searchKeyValue) {
  const opCode = "operation_code=FCSOB";
  const parameter = Object.keys(data).map((key) => `${key}=${data[key]}`);
  let params = parameter.join("&");
  params = params ? `?${opCode}&${params}` : `?${opCode}`;
  const url = ApiUrl(`v2/billing/filtered_customer_order_billings${params}`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { invoices: "bills", pagination: "meta.pagination" , status_counts : "status_counts" },
  });
}

export function fetchIndividualInvoices(data) {
  const opCode = "operation_code=AOIN";
  const parameter = Object.keys(data).map((key) => `${key}=${data[key]}`);
  let params = parameter.join("&");
  params = params ? `?${opCode}&${params}` : `?${opCode}`;
  const url = ApiUrl(`v2/billing/approved_order_invoices${params}`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      invoices: "order_invoices",
      pagination: "meta.pagination",
      stats: "status_counts",
    },
  });
}

export function fetchInvoiceDetails(data) {
  const opCode = "operation_code=BLCONB";
  const parameter = Object.keys(data).map((key) => `${key}=${data[key]}`);
  let params = parameter.join("&");
  params = params ? `?${opCode}&${params}` : `?${opCode}`;
  const url = ApiUrl(`v2/billing/consolidate_bill${params}`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      invoice: "order_invoices",
      smp_details: "smp_details",
      is_locked: "is_locked",
      is_paid: "is_paid",
      ar_status: "ar_status",
      invoice_logo_address: "invoice_logo_address",
      billing_company_details: "billing_company_details",
      vendor_no: "vendor_no"
    },
  });
}

export function fetchSummaryDetails(data) {
  let params = "";
  const opCode = "operation_code=SOIN";
  const parameter = Object.keys(data).map((key) => `${key}=${data[key]}`);
  params = parameter.join("&");
  params = params ? `?${opCode}&${params}` : `?${opCode}`;
  const url = ApiUrl(`v2/billing/summery_order_invoices${params}`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      details: "",
    },
  });
}

export function fetchBolPdf(customerOrderId) {
  const url = ApiUrl(
    `v2/billing/get_order_bol_pdf?customer_order_id=${customerOrderId}&operation_code=GOBP`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      bol_file: "bol_file",
    },
  });
}

export function postInvoiceToQb(data) {
  data.operation_code = 'PITQB';
  const url = ApiUrl("v2/account_invoices/post_invoice_to_qb");
  return Call(
    "PUT",
    url,
    data,
    ParseGeneralResponse,
    {
      responseDataKeys: { invoices: "" },
    }
  );
}

export function updateDriverRate(data) {
  const url = ApiUrl("v2/billing/update_driver_charge_value");
  return Call(
    "PUT",
    url,
    data,
    ParseGeneralResponse,
    {
      responseDataKeys: { data: "" },
    }
  );
}


export function validInvoices(data) {
  const url = ApiUrl("v2/account_invoices/validate_invoices");
  return Call(
    "POST",
    url,
    data,
    ParseGeneralResponse,
    {
      responseDataKeys: { invoices: "" },
    }
  );
}
export function deleteInvoice(data) {
  data.operation_code = 'ID';
  const url = ApiUrl(`v2/account_invoices/delete_invoice_from_qb`);
  return Call(
    "DELETE",
    url,
    data,
    ParseGeneralResponse,
    {
      responseDataKeys: { invoices: "" },
    }
  );
}

export function fetchUpdatedInvoiceNo(data) {
  const url = ApiUrl("v2/account_invoices/updating_invoice_number");
  return Call("POST", url, data, ParseGeneralResponse, {
    responseDataKeys: { message: "message" },
  });
}

export function unlockInvoice(data) {
  data.operation_code = 'IUNLOCK';
  const url = ApiUrl("v2/account_invoices/unlock_invoice");
  return Call(
    "POST",
    url,
    data,
    ParseGeneralResponse,
    {
      responseDataKeys: { invoices: "" },
    }
  );
}

export function removeOrderFromInvoice(data) {
  data.operation_code = 'REMOVE_ORDER_FROM_INVOICE';
  const url = ApiUrl("v2/billing/remove_order_from_invoice");
  return Call(
    "POST",
    url,
    data,
    ParseGeneralResponse,
    {
      responseDataKeys: { errors: "errors",message: "message" },
    }
  );
}

export function exportFile(fromDate, toDate, status, file_type, desktop_type) {
  const url = `${AppConfig.baseUrl}/api/v2/billing/invoice_export_files?status=${status}&from_date=${fromDate}&to_date=${toDate}&file_type=${file_type}&desktop_type=${desktop_type}`;
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      file: "file",
    },
  });
}

export function sendInvoice({
  organization_id,
  process_date,
}) {
  const url = `${AppConfig.baseUrl}/api/v2/account_invoices/process_invoice_mails_per_day?process_date=${process_date}&organization_id=${organization_id}`;
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      file: "file",
    },
  });
}


export function exportInvoiceAsExcel(url) {
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      data: "",
    },
  });
}

export function exportInvoiceAsPdf(url) {
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      data: "",
    },
  });
}

export function saveRemark(data) {
  const url = ApiUrl("v2/billing/save_vendor_number");
  return Call("POST", url, data, ParseGeneralResponse, {
    responseDataKeys: {
      message: "message",
    },
  });
}

export function grossOrderReports(data) {
  const url = ApiUrl("v2/gross_order_reports/process_driver_invoice_mails_per_day");
  return Call("POST", url, data, ParseGeneralResponse, {
    responseDataKeys: {
      data: "data",
    },
  });
}

