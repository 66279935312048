import React, { useEffect, useState, Fragment, useContext, useRef } from "react";
import {
  Search,
  Row,
  Col,
  Icon,
  Drawer,
  Spin,
  Empty,
  DatePicker,
  notification,
  Link,
  Skeleton,
} from "../../common/UIComponents";
import circle from "../../../images/assign_route/circle.svg";
import splitCirle from "../../../images/assign_route/split_circle.svg";
import DriverBox from "./DriverBox";
import DriverRouteDetails from "./DriverRoutesDetails";
import PropTypes from "prop-types";
import {
  assignOrderToRoutes,
  newDispatchRoute,
  updateQuickDispatchRouteInMiddle,
} from "../../api/Routes";
import moment from "moment";
import { WarehouseContext } from "../../context/WarehouseContext";
import { alertMessage, isEmpty, randomNumber } from "../../common/Common";
import { getRouteStartTime } from "../../helpers/common"
import {
  getOperationalTimings,
  removeSpecialCharacters,
  renderAlertMessage,
} from "../../helpers/common";
import _ from "lodash";
import RouteLabel from "../../config/RouteLabel";
import { showRouteValidationError } from "../../helpers/capacityValidation";
import { getLocIdFormLoc, pickLocsFromOrders } from "../../helpers/orders";
import { UserContext } from "../../context/UserContext";
import { Snackbar } from "@mui/material";
import { withRouter } from "react-router-dom";
import AppConfig from "../../config/AppConfig";
import QuickDispatchapptError  from "./QuickDispatchApptError";
import QuickDispatchHint  from "./QuickDispatchHint";
import QuickDispatchErroBox from "./QuickDispatchErroBox";
import { AssignIcon } from "../../common/CustomIcon";


const AssignOrders = ({
  selectedOrders = [],
  isFromBulkContainer,
  isAssignClicked,
  setIsAssignClicked,
  setIsHovered,
  currentOrg,
  organizationSettings,
  orderRefreshCall,
  history,
}) => {
  const userContext = useContext(UserContext);
  const { updateUserConfig, currentUser } = userContext;
  const [showDrawer, setShowDrawer] = useState(false);
  const [currentDriver, setCurrentDriver] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { selectedWarehouses } = useContext(WarehouseContext);
  const warehouseData = useContext(WarehouseContext);
  const [driversInfo, setDriversInfo] = useState([]);
  const [filteredDrivers, setFilteredDrivers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [orders, setOrders] = useState([]);
  const [showDescription, setShowDescription] = useState();
  const [showApptErrors, setShowApptErrors] = useState(false);

  
  const [selectedDate, setSelectedDate] = useState(null);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const requireValidation = organizationSettings.capacity_optimization === "true";
  const [errors, setErrors] = useState([]);


  // Synchronize orders with selectedOrders
  useEffect(() => {
    processOrders(selectedOrders);
  }, [selectedOrders]);

  const processOrders = (orders) => {
    const locWiseOrders = pickLocsFromOrders(orders, "location_id");
    setOrders(locWiseOrders);
  };

  useEffect(() => {
    if(currentDriver){
      setShowDescription(false)
    }
  }, [currentDriver]);

  useEffect(() => {
    if (isAssignClicked) {
      setSelectedDate(moment())
      enableDrawer();
     // setShowDescription(true);
    }
  }, [isAssignClicked]);

  useEffect(() => {
    if(selectedDate){
      getDriverDetails();
    }
  }, [selectedDate]);

  useEffect(() => {
    if (isFromBulkContainer && !showDrawer) {
      setIsAssignClicked(false);
      setIsHovered(null);
    }
  }, [showDrawer]);

  const enableDrawer = () => {
    setShowDrawer(true);
  };

  const renderNotification = (message, type) => {
    // return <Snackbar>Hrllo</Snackbar>
    const key = randomNumber()
    if(type === "success"){
      const key = randomNumber();
      notification.open({
        message: (
          <Row className="custom-notification" type="flex">
            <Col className="notification-successIcon" style={{ width: 40, height: 60}}> 
            <AssignIcon
              style={{ fontSize: 24 }}
              color="#FFFFFF"
            />
            </Col>
            <Col className="notification-content">{message}</Col>
          </Row>
        ),
        duration: 0, // Make it persistent
        className: 'custom-notification-wrapper ',
        placement: 'bottomLeft',
        key,
      });
      setTimeout(() => notification.close(key), 10000)
    }else if(type === "error"){
      const key = randomNumber();
      notification.open({
        message: (
          <Row className="custom-notification" type="flex" style={{ alignItems: 'stretch', minHeight: 60 }}>
            <Col className="notification-errorIcon" style={{ width: 40 }}> 
            <Icon
              style={{ fontSize: 24, color: 'white' }}
              type="warning"
            />
            </Col>
            <Col className="notification-content">{message}</Col>
          </Row>
        ),
        duration: 0, // Make it persistent
        className: 'custom-notification-wrapper',
        placement: 'bottomLeft'
      });
      setTimeout(() => notification.close(key), 10000);
    }
  }

  const navigateToRoute = (routeId, routeStatus, date) => {
    // this.props.history.push(`/stops/${routeId}`);
    if (routeStatus !== "ALLOCATED") {
      history.push({
        pathname: "/routes",
        filter: routeStatus,
        routeId,
        dateFilter: {
          fromDate: date,
          toDate: date,
        },
      });
    }
  };

  const getDriverDetails = async (driverId = null) => {
    try {
      const payload = {
        org_id: currentOrg?.id,
        start_datetime: selectedDate.format("YYYY-MM-DD"),
        end_datetime: selectedDate.format("YYYY-MM-DD"),
        warehouse_id: selectedWarehouses || "",
      };
      setIsLoading(true);
      const response = await assignOrderToRoutes(payload);
      if (response.success) {
        const routesData = response.driver_details || [];
        setDriversInfo(routesData);
        setFilteredDrivers(routesData); 
        // if(!_.isEmpty(currentDriver))
        //   {
        //     setCurrentDriver(routesData[0]);
        //   }
      } else {
        throw new Error(response.errors[0]);
      }
    } catch (error) {
      alertMessage(error.message, "error", 10);
    } finally {
      setIsLoading(false);
    }
  };

  const getDriverInfo = (rec) => {
    setCurrentDriver((prevDriver) =>
      prevDriver?.employee_code === rec.employee_code ? null : rec
    );
  };

  const handleTextChange = (e) => {
    const text = e.target.value;
    const filteredString = removeSpecialCharacters(text);
    const searchValue = filteredString.trim();
    setSearchText(searchValue);
    if (searchValue === "") {
      setFilteredDrivers(driversInfo);
    } else {
      const filtered = driversInfo.filter((driver) =>
        driver.employee_code?.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredDrivers(filtered);
      if (!_.isEmpty(currentDriver) && !_.isEmpty(searchValue) && filtered?.length > 0) {
        setCurrentDriver(filtered[0]);
      }
    }
  };

  const createRoute = async (record, requireValidation) => {
    const organization_id = currentOrg.id;
    const selectedWh = warehouseData.getWHDetails();

    let { start_time = "", end_time = "" } = getOperationalTimings(
      organizationSettings,
      selectedWh
    );

    const endDeliveryTime = moment(
      `${selectedDate.format("YYYY-MM-DD")} ${end_time}`,
      "YYYY-MM-DD HH:mm"
    ).format();
    // const loc_ids = _.compact(
    //   _.flatten(
    //     orders.map((order) => [
    //       getLocIdFormLoc(order.origin, "location_id"),
    //       getLocIdFormLoc(order.destination, "location_id"),
    //     ])
    //   )
    // );
    const loc_ids = _.uniq(orders.map((order) => order.location_id));
    const routeStartTime = getRouteStartTime(start_time, end_time, selectedWh.timeZoneId, selectedDate, record.all_routes_completion_date);
    const startDateTime = moment(
      `${selectedDate.format("YYYY-MM-DD")} ${routeStartTime}`,
      "YYYY-MM-DD HH:mm"
    ).format();

    const endDateTime =  (moment(routeStartTime, "HH:mm")).isAfter((moment(end_time, "HH:mm"))) ? moment(
      `${selectedDate.format("YYYY-MM-DD")} 23:59`,
      "YYYY-MM-DD HH:mm"
    ).format() :  moment(
      `${selectedDate.format("YYYY-MM-DD")} ${end_time}`,
      "YYYY-MM-DD HH:mm"
    ).format();


    const payload = {
      organization_id,
      warehouse_id: selectedWh?.id || '',
      driver_id: record.driver_id,
      delivery_end_date: endDateTime,
      delivery_start_date: startDateTime,
      customer_order_ids: orders.map((order) => order.id),
      action_type: RouteLabel.actionLabels.ROUTE_CREATED,
      action_from: "ORDER",
      location_ids: loc_ids || [],
      skip_capacity_validation: !requireValidation,
      skip_route_time_validation: false,

    };
    setIsLoading(true);
    try {
      const result = await newDispatchRoute(payload);
      if (result.success) {
        if(result.response.capacity_overloaded){
          showRouteValidationError(
            result.response.capacity_info,
            record.employee_code,
            () => {
              createRoute(record, false)
            },
            setIsLoading,
          )
        } else{
          const navRoute = result.nav_route || {};
          renderNotification(<Fragment>{`${orders.length} Order(s) have been assigned to `}<Link onClick={() => navigateToRoute(navRoute.id, navRoute.r_status, selectedDate.format("YYYY-MM-DD"))}>{record.employee_code}</Link></Fragment> , 'success')
          setShowDrawer(false);
          orderRefreshCall();
          setIsLoading(false)
        }
       
      } else {
        renderErrors(result.errors)
        setIsLoading(false)
      }
    } catch (error) {
      console.error("Error assigning orders:", error);
      setIsLoading(false)
    } finally {
      // setIsLoading(false);
    }
  };

  const getStopLocationIds = (route_details) => {
    return route_details.stop_details.map((stop) => stop.stop_location_ids).flat();
  };

  const getStopOrders = (route_details) => {
    return route_details.stop_details.map((stop) => stop.stop_orders).flat();
  };

  const addOrders = (route, requireValidation) => {
    const { route_details = {} } = route;
    const selectedWh =
      warehouseData?.selectedWarehouses?.length &&
      Array.isArray(warehouseData.selectedWarehouses)
        ? warehouseData.selectedWarehouses[0]
        : warehouseData.selectedWarehouses;

    const routeStopLocationIds = !_.isEmpty(route_details)
      ? getStopLocationIds(route_details)
      : [];
    // const selectedOrdersLocationIds = _.compact(
    //   _.flatten(
    //     orders.map((order) => [
    //       getLocIdFormLoc(order.origin, "location_id"),
    //       getLocIdFormLoc(order.destination, "location_id"),
    //     ])
    //   )
    // );
    const selectedOrdersLocationIds = _.uniq(orders.map(order => order.location_id));
    const loc_ids = [...routeStopLocationIds, ...selectedOrdersLocationIds];

    const routeOrders = !_.isEmpty(route_details) ? getStopOrders(route_details) : [];
    const routeOrderIds = routeOrders?.length > 0 ? routeOrders.map((order) => order.id) : [];
    const selectedOrderIds = orders.map((order) => order.id);
    const totalOrderIds = [...routeOrderIds, ...selectedOrderIds];

    const payload = {
      nav_route_id: route?.route_details?.route_id || "",
      order_ids_to_add: totalOrderIds,
      warehouse_id: selectedWh || "",
      location_ids: loc_ids || [],
      driver_id: route?.driver_id || "",
      skip_capacity_validation: !requireValidation,
    };

    setIsLoading(true);
    updateQuickDispatchRouteInMiddle(payload)
      .then((result) => {
        if (result.success) {
          if(result.response.capacity_overloaded){
            showRouteValidationError(
              result.response.capacity_info,
              route.employee_code,
              () => {
                addOrders(route, false)
              },
              setIsLoading,
            )
          } else{
            alertMessage(
              `Orders have been assigned to ${route.employee_code}`,
              "success",
              10
            );
            setShowDrawer(false);
            orderRefreshCall();
            setIsLoading(false);
          }
        } else {
          renderErrors(result.errors)
          setIsLoading(false);
        }
      })
      .finally(() => {

      });
  };

  const assignToRoute = (record) => {
    const hasRoute = record?.route_details && !_.isEmpty(record.route_details);
    const { route_details = {} } = record;
    const showLockIcon = hasRoute ? currentUser.id !== route_details?.is_locked_by && route_details?.is_locked : false
    if (hasRoute && !showLockIcon) {
      addOrders(record, requireValidation);
    } else {
      createRoute(record, requireValidation);
    }
  };

  const updateOrders = (newOrders) => {
    setOrders(newOrders);
  };

  const handleDateChange = (date) => {
     if (_.isEmpty(date)) {
       setSelectedDate(moment()); 
     } else {
       setSelectedDate(date);
     }
    setDatePickerOpen(false);
  };

  const handleColClick = () => {
    setDatePickerOpen((prevState) => !prevState); // Toggle the DatePicker open/close
  };

  const renderDriversDrawer = () => {
    const selectedWh = warehouseData.getWHDetails();
    const selectedTimeZone = selectedWh?.timeZoneId || 'America/New_York';
    const driversSecHeight = showDescription && (showApptErrors || errors.length > 0)?
    "calc(100vh - 600px)"
    :
    showDescription
    ? "calc(100vh - 435px)" :
    (showApptErrors || errors.length>0) ?
    "calc(100vh - 335px)" :
    "calc(100vh - 200px)";
    const ordersSecHeight = showDescription && (showApptErrors || errors.length > 0)?
    "calc(100vh - 675px)"
    :
    showDescription
    ? "calc(100vh - 500px)" :
    (showApptErrors || errors.length>0) ?
    "calc(100vh - 470px)" :
    "calc(100vh - 335px)";
    return (
      <Drawer
        placement="right"
        closable
        onClose={() => {
          setShowDrawer(false);
          setCurrentDriver(null);
          const fieldsToIgnore = ['location_id', 'order_type'];
          const cleanedArr = orders.map(obj => _.omit(obj, fieldsToIgnore));
          if (!_.isEqual(selectedOrders, cleanedArr)) {
            orderRefreshCall();
          }
        }}
        visible={showDrawer}
        width={currentDriver ? 950 : 500}
        className="custom-drawer"
        maskClosable={false}
      >
       {
        selectedDate ?
        <div className="assignOrders">
          <span className="title">
            {orders.length > 0 && selectedOrders.length
              ? `Assign (${selectedOrders.length}) Orders`
              : `Route Details`}
          </span>
          {/* {showDescription && (
            <div className="desc-container">
              <p>How Quick Assign Works</p>
              <p className="description">
                Select a driver to manage routes or adjust orders. Click the
                assign icon to immediately add orders to the end of a driver's
                route. The bar charts show how assigning orders will impact
                truck capacity based on the current route for the day selected.
                If a driver has no routes, it anticipates what creating a route
                for the current hour would look like.
              </p>
              <span
                className="dismiss-tip"
                onClick={() => {
                  setShowDescription(false);
                  updateUserConfig(
                    "order_listing",
                    false,
                    "show_quick_dispatch_hint"
                  );
                }}
              >
                Dismiss Tip
              </span>
              <div className="icon">
                {" "}
                <LightOnIcon />{" "}
              </div>
            </div>
          )} */}
          { <QuickDispatchHint setShowDescription={setShowDescription} currentDriver={currentDriver}/>}

          {/* <p className="description">
            The impact on capacity of orders you will be assigning is shown in
            purple, defaulting to end of a route. Click a driver for more
            details and to reorder, or use the assign icon for immediate
            assignment.
          </p> */}
          <Spin spinning={isLoading} tip="Loading...">
            <Search
              style={{ marginTop: 12, marginBottom: 12 }}
              placeholder="Search Drivers"
              className="custom-search-input"
              onChange={handleTextChange}
              value={searchText || ""}
              allowClear
              onClear={() => {
                setSearchText("");
              }}
              prefix={
                <Row type="flex" align="middle" gutter={4}>
                  <Col>
                    <Icon type="search" />
                  </Col>
                </Row>
              }
              suffix={null}
            />
            <Row
              type="flex"
              justify="space-between"
              gutter={16}
              className="fontSize12 marginTop10 marginBottom10 statusRibbon"
            >
              <Col style={{ marginTop: -6 }}>
                <Row type="flex" className="date-filter" justify="start">
                  <Col className="date-filter-col" onClick={handleColClick}>
                    <span>
                      <Icon
                        type="calendar"
                        style={{
                          color: "#617AD6",
                          fontSize: "20px",
                        }}
                      />
                    </span>
                    {selectedDate && (
                      <span style={{ marginLeft: "10px" }}>
                        {selectedDate.format(AppConfig.dateFormat)}
                      </span>
                    )}
                  </Col>
                </Row>
                {datePickerOpen && (
                  <DatePicker
                    format={AppConfig.dateFormat}
                    open={datePickerOpen}
                    value={selectedDate}
                    onChange={handleDateChange}
                    onOpenChange={(open) => {
                      if (!open) {
                        setDatePickerOpen(false);
                      }
                    }}
                    disabledDate={
                      current => current && selectedTimeZone && moment(moment(current).format('YYYY-MM-DD')).isBefore(moment().tz(selectedTimeZone).format('YYYY-MM-DD')) // moment(moment().add(AppConfig.preplanShownDuration - 24, 'hours'), 'YYYY-MM-DD')
                    }
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      zIndex: 1000,
                    }}
                  />
                )}
              </Col>
              <Col>
                <Row type="flex" justify="end" gutter={16}>
                  <Col>
                    <Row type="flex" gutter={4}>
                      <Col>
                        <img src={circle} width={16} />
                      </Col>
                      <Col>Current Payload</Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row type="flex" gutter={4}>
                      <Col>
                        <img src={splitCirle} width={16} />
                      </Col>
                      <Col>Impact/Overage</Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            {errors?.length > 0 ? 
              <QuickDispatchErroBox
                errors={errors} 
                setErrors={setErrors}
              /> :
              <QuickDispatchapptError 
                orders={orders} 
                selectedDate={selectedDate} 
                showApptErrors={showApptErrors} 
                setShowApptErrors={setShowApptErrors} 
              />
            }
            {!currentDriver ? (
              <Row gutter={16}>
                <Col
                  style={{
                    overflowY: "auto",
                    height: driversSecHeight
                  }}
                >
                  {filteredDrivers?.length > 0 ? (
                    filteredDrivers.map((rec) => (
                      <DriverBox
                        key={rec.driver_id}
                        getDriverInfo={() => getDriverInfo(rec)}
                        driver={rec}
                        orders={orders}
                        assignToRoute={() => assignToRoute(rec)}
                        warehouseData={warehouseData}
                        organizationSettings={organizationSettings}
                        userContext={userContext}
                        selectedDate={selectedDate}
                      />
                    ))
                  ) : (
                    <Empty
                      description={<h3>Driver Not Found!!</h3>}
                      style={{
                        display: "inline-block",
                        marginTop: "15vh",
                        padding: "25%",
                      }}
                    />
                  )}
                </Col>
              </Row>
            ) : (
              <DriverRouteDetails
                currentDriver={currentDriver}
                orders={orders}
                driversInfo={driversInfo}
                getDriverInfo={getDriverInfo}
                filteredDrivers={filteredDrivers}
                searchText={searchText}
                warehouseData={warehouseData}
                currentOrg={currentOrg}
                orderRefreshCall={orderRefreshCall}
                updateOrders={updateOrders}
                organizationSettings={organizationSettings}
                currentDate={selectedDate}
                renderNotification={renderNotification}
                unassinedOrders={selectedOrders}
                showDescription={showDescription}
                getDriverDetails={getDriverDetails}
                navigateToRoute={navigateToRoute}
                userContext={userContext}
                driversSecHeight={driversSecHeight}
                setErrors={setErrors}
                renderErrors={renderErrors}
                ordersSecHeight={ordersSecHeight}
              />
            )}
          </Spin>
        </div>
        :
        <Skeleton />
        }
      </Drawer>
    );
  };

  const renderErrors = (errors) => {
    if(errors){
      setErrors(errors)
    }
    //renderNotification(errors?.length ? errors.join(',') : 'Unable to process the request', 'error');
  }

  return (
    <Fragment>
      {!isFromBulkContainer ? (
        <div onClick={enableDrawer}>Assign Driver</div>
      ) : (
        "Assign"
      )}
      {showDrawer && renderDriversDrawer()}
    </Fragment>
  );
};

AssignOrders.propTypes = {
  isFromBulkContainer: PropTypes.bool,
  isAssignClicked: PropTypes.bool,
  setIsAssignClicked: PropTypes.func,
  setIsHovered: PropTypes.func,
  orderRefreshCall: PropTypes.func,
  currentOrg: PropTypes.object,
  organizationSettings: PropTypes.object,
  selectedOrders: PropTypes.array,
};

AssignOrders.defaultProps = {
  isFromBulkContainer: false,
  isAssignClicked: false,
  setIsAssignClicked: () => {},
  setIsHovered: () => {},
};

export default withRouter(AssignOrders);