/* eslint-disable no-nested-ternary */
/* eslint-disable react/sort-comp */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component, Fragment, useContext, useEffect, useState } from 'react'
import {Alert, Descriptions, Empty, Form, Modal, Select, Table, Typography } from 'antd';
import equal from "fast-deep-equal";
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import {
  Button,
  Col,
  Spin,
  Row,
  Card,
  Icon,
  Avatar,
  Rate,
  Timeline,
  Skeleton,
  Tabs,
  Collapse,
  Text,
  Divider,
  Popover,
  Link,
  Tooltip
} from '../../common/UIComponents'
import {
  doFormate,
  isEmpty,
  alertMessage,
  base64ToPdf,
  ellipseText,
  retrieverValueFromObject,
  randomNumber,
} from "../../common/Common";
import I18n from '../../common/I18n';
import Invoice from '../billing/Invoice'
import customerlogo from '../../../assets/images/customer.png'
import ItemDetails from '../Routes/ItemDetails'
import ItemListDetails from '../orders/ItemsFullList'
import WarehouseDetails from '../stops/WarehouseDetails'
import OrderActivities from '../orders/Activities'
import Profile from '../common/Profile'
import Details from '../orders/Details'
import ExceptionsList from '../orders/ExceptionsList'
import BaseModal from '../BaseModal'
import QuesFromResponse from '../forms/FormResponse'
import { changeDriverOnCompletedOrder, dispatcherNotesApi, downloadOrderPictures, fetchConsolidatedMawbs, fetchOrderDetails, fetchWms, privateNotesApi, wmsPreCall } from '../../api/OrdersApi'
import ViewOrderAccessorial from '../orders/ViewOrderAccessorial'
import userStore from '../../stores/UserStore'
import { checkAccessExistance, checkServiceExistance, formatByTimeConfig, formatFullName, formatUSPhoneNumber, getAccounts, printPDF, renderAlertMessage } from '../../helpers/common'
import OrderQuoteAmount from '../orders/OrderQuoteAmount'
import OrderAccessorialSelect from '../orders/OrderAccessorialSelect'
import DispatcherNotes from '../orders/DispatcherNotes'
import OrderPictures from '../common/OrderPictures'
import OrderStatus from '../orders/OrderStatus'
import NotificationList from '../orders/NotificationList'
import OrderRefrences from '../orders/OrderRefrences'
import OrderScheduleDate from '../orders/OrderScheduleDate'
import ConsigneeNote from '../orders/ConsigneeNote'
import ShipperNote from '../orders/ShipperNote'
import EstimationAmount from '../orders/EstimationAmount'
import SurveyFormIcon from '../orders/SurveyFormIcon'
import { sortByDate } from '../../helpers/array_functions'
import driver_logo from '../../../assets/images/driver_icon.svg'
import { withRouter } from 'react-router'
import StatusLogList from '../orders/StatusLogList'
import { momentTimeWithShortName } from '../../helpers/date_functions'
import BillOfRatingModal from '../../containers/BillOfRatingModal'
import RecoveryManage from '../recoveries/RecoveryManage'
import ReleaseManage from '../releases/ReleaseManage'
import ApprovedOrRejectStatus from './ApprovedOrRejectStatus'
import PendingOrdersActions from '../billing_screen/PendingOrdersActions'
import AttemptedDetails from '../orders/AttemptedDetails'
import { fetchBolPdf } from '../../api/BillingScreenAPI'
import trailerIcon from "../../../assets/images/trailer_1.svg";
import ItemGrouping from "../orders/ItemGrouping";
import { OrgContext } from "../../context/OrgContext";
import AddressInfo from "../common/AddressInfo";
import BranchesIcon from '../common/BranchesIcon';
import OrderFiledActivities from '../orders/OrderFiledActivities';
import SubORdersForLh from '../orders/SubORdersForLh';
import AccWhAccessorial from '../orders/AccWhAccessorial';
import RecoveryDataTab from '../recoveries/RecoveryDataTab';
import ReleaseDataTab from '../releases/ReleaseDataTab';
import LhDetails from '../../containers/LineHaul/lhDetailView';
import Copyable from '../common/Copyable';
import AppConfig from '../../config/AppConfig';
import WMSList from '../orders/WMSList'
import DisplayTime from '../common/DisplayTime';
import { fetchShortFormAccounts } from '../../api/Account';
import { findIsbeyondArea } from '../../helpers/orders';
import EndUserTitle from '../common/EndUserTitle';
import MiscData from '../orders/MiscData';
import { fetchUsers } from '../../api/UsersApi';
import ImageViewer from '../common/ImageViewer';
import ItemsExceptionsList from '../orders/ItemsExceptionsList';
import ItemsExceptionModal from '../orders/ItemExceptionModal';
import SendStatusReportComponent from './SendStatusReport';
import PrivateNotes from '../orders/PrivateNotes';
import ItemsExceptionLogList from '../orders/ItemsExceptionList';
import { WarehouseContext } from '../../context/WarehouseContext';
import InvoicesLines from '../billing/InvoiceLines';
import { UserContext } from '../../context/UserContext';
import { getEnvironment } from '../orders/helpers';

const { TabPane } = Tabs
const { Panel } = Collapse
const { Text: AntText } = Typography


export const DriverDetails = ({ currentOrder, cardStyle = {},edit= false,getOrderDetails,locationBased,isShipper,isDriver}) => {
  const [openDriversModal, setOpenDriversModal] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const[selectedDriver, setSelectedDriver ] = useState('');
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    driversList();
  }, []);


  const changeDriver = () => {
    const locationId = locationBased ? currentOrder.id : currentOrder.cs_location.id;
    const orderId = locationBased ? currentOrder.customer_order_id : currentOrder.id;
    setInProgress(true);
    const data = {
      driver_id: selectedDriver,
      order_id: orderId,
      location_id: locationId,
      old_driver_id: _.get(currentOrder, "driver_details[0].id", "") || "",
    };
    changeDriverOnCompletedOrder(data).then((result) => {
      if (result.success) {
        alertMessage(result.data.message, "success", 10);
        setOpenDriversModal(false);
        getOrderDetails();
      } else {
        renderAlertMessage(result.errors)
      }
    }
    ).catch((error) => {
      alertMessage(error.message, "error", 10);
    })
      .finally(() => {
        setInProgress(false);
      });
    }

  const driversList = () => {
    setInProgress(true);
    fetchUsers("driver")
      .then((result) => {
        if (result.success) {
          const updatedDrivers = result.users.map((driver) => ({
            id: driver.id,
            employee_code: driver.employee_code,
            full_name: driver.full_name,
          }));

          setDrivers(updatedDrivers);
        } else {
          renderAlertMessage(result.errors)
        }
      })
      .catch((error) => {
        alertMessage(error.message, "error", 10);
      })
      .finally(() => {
        setInProgress(false);
      });
  };

  const handleEdit = () => {
    setOpenDriversModal(true);
  }

  const handelCancel = () => {
    setOpenDriversModal(false);
  }

  const handleDriverChnage = (value) => {
    setSelectedDriver(value);
  }

  const renderDriverEditModal = () => {
    return (
      <Modal
        title={"Change Driver"}
        onCancel={handelCancel}
        footer={null}
        bodyStyle={{ padding: 10 }}
        visible={openDriversModal}
      >
        <div>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Spin spinning={inProgress} >
              <Form>
                <span className="textBold">{I18n.t("general.Driver")}:</span>
                <Select
                  showSearch
                  value={selectedDriver ? selectedDriver : ""}
                  onChange={handleDriverChnage}
                  filterOption={(input, option) => {
                    const children = option.props.children;
                    if (children && children.length > 0) {
                      if (Array.isArray(children)) {
                        return children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0;
                      } else {
                        return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                      }
                    }
                  }}
                  style={{ width: "80%", height: "20%", marginLeft: 10 }}
                >
                  <Select.Option key={"driver"} value={""}>
                    --select--
                  </Select.Option>
                  {drivers.map((driver) => (
                    <Select.Option key={driver.id} value={driver.id}>
                      {driver.employee_code}
                    </Select.Option>
                  ))}
                </Select>
              </Form>
              </Spin>
            </Col>
          </Row>
          <Row className="marginTop10">
            <Col xs={24} className="alignCenter">
              <Button
                className="marginRight5"
                onClick={handelCancel}
                icon='close'
                size='small'
              >
                {I18n.t("general.cancel")}
              </Button>
              <Button type="primary" onClick={changeDriver} icon="save" loading={inProgress} size='small'>
                {I18n.t("general.save")}
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    );
  };
  return (
    <div className="alignLeft">
      <Card size="small" bodyStyle={ { textAlign: "left", height: "240px", ...cardStyle } }>
        <Row>
          <Col className="leftBlock" xs={ 12 }>
            <span className="primaryText textBold fontSize18">
              { I18n.t("general.driver") }
            </span>{ " " }
            &nbsp;&nbsp;&nbsp;
            { currentOrder.driver_attempts &&
              currentOrder.driver_attempts.length > 0 && (
                <AttemptedDetails
                  record={ currentOrder }
                  isMilitaryTime={ currentOrder.isMilitaryTime }
                  timeZone={ currentOrder.order_timeZoneId }
                  timeZoneName={ currentOrder.order_tz_short_name }
                />
              ) }
          </Col>
          <Col xs={ 12 } className="alignRight">
            <Icon
              component={ () => <img style={ { height: "30px" } } src={ driver_logo } /> }
            />
          </Col>
        </Row>
        <Row>
          { currentOrder.driver_details &&
            currentOrder.driver_details.length > 0 &&
            currentOrder.driver_details[ 0 ].id ? (
            <Profile
              address={
                currentOrder.cs_location
                  ? currentOrder.cs_location.l_address
                  : {}
              }
              isShipper={isShipper}
              profile={ (({
                image,
                first_name,
                last_name,
                mobile_number,
                email,
                employee_code,
              }) => ({
                image,
                full_name: `${first_name} ${last_name}`,
                mobile_number,
                email,
                employee_code,
              }))(currentOrder.driver_details[ 0 ]) }
              showImage={ false }
              isMobileScreen={ false }
              withLables={ false }
              showAddress={ false }
              showEmail={ false }
              isDriver={ true }
              edit={edit}
              handleEdit={handleEdit}
              showMobileNo={isShipper ? false : true}
            />
          ) : (
            <div className="marginLeft15 marginTop20">
              <h3 className="details_value_grey fontSize20">
                { I18n.t("users.not_assigned") }
              </h3>
            </div>
          ) }
          { false && currentOrder.is_location_based && (
            <Col xs={ 24 } className="padding5 fontSize14" style={ { padding: '2px 5px' } }>
              <Row>
                <span>
                  <Icon
                    component={ () => (<img style={ { height: '20px', marginTop: -5 } } src={ trailerIcon } />) }
                  />
                  &nbsp;&nbsp;
                  { I18n.t('order.trailer_no') }:
                  &nbsp;&nbsp;
                </span>
                <span className="textCaptilize textBold">
                  { doFormate(currentOrder.trailer_number) }
                </span>
              </Row>
            </Col>
          ) }
        </Row>
      </Card>
      {openDriversModal && renderDriverEditModal()}
    </div>
  );
};

class OrderDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeKey: 'details',
      setNotesKey: 'shipper_note',
      order_id: this.props.order_id,
      isDeletedOrder: this.props.isDeletedOrder || false,
      showQuestionForm: false,
      config: props.config || {},
      inProgress: false,
      uploadKey: '',
      orders: {},
      currentOrder: props.order || {},
      currentProcessingOrder: '',
      organizationSettings: {},
      isMilitaryTime: false,
      currentProcessingLocation: null,
      isGeneratingPdf: false,
      groupItems: false,
      wmsData : {},
      ZipMessage: '',
      accounts:[],
      modalVisible: false,
      selectedImage: '',
      showItemExceptionModal: false,
      currentRecord: {},
      mawbButtonClicked: false,
      consolidatedData: [],
      consolidateLoading: false,
      allStatus: [],
    }
  }

  componentDidMount() {
    // this.getOrderDetails();
    this.fetchData();
    this.getWmsDetails();
    this.getAccounts();
    this.getIsBeyondArea();
    this.setState({
      organizationSettings: this.props.organizationSettings,
      isMilitaryTime:
          this.props.organizationSettings.is_military_time == 'true',
    })
    if(!isEmpty(this.props.tab)){
      this.goToTab(this.props.tab);
    }
  }

  getAccounts = (whId) => {
    const accountsData = this.props.warehouseFilter.findMatchingAccounts(whId);
    this.setState({
      accounts: accountsData || [],
    });
  };

  handlePrint = (url) => {
    const { currentOrder } = this.state;
    const refer_id = currentOrder.order_id;
    const location_id = currentOrder.cs_location.id;
    const itemObject = _.find(currentOrder.pictures, obj => obj.picture.url === url);
    const imageId = _.get(itemObject, '_id', '');
    alertMessage("Printing in progress", "info");
    downloadOrderPictures(refer_id, location_id, imageId)
      .then((result) => {
        if (result.success) {
          if (result.data.length === 0) {
            alertMessage("No attachment found", "error");
            return;
          }
          printPDF(result.data.combine_attachments_pdf);
        } else {
          if (Array.isArray(result.errors) && result.errors.length > 0) {
            renderAlertMessage(result.errors);
          } else {
            alertMessage("Failed to print", "error");
          }
        }
      })
  }

  getAllStatus = (incomindData) => {
    const allStatuses = incomindData.map((data) => data.status);
    this.setState({ allStatus: allStatuses });
  };

  fetchData = () => {
    this.setState({ consolidateLoading: true });
    const orderId = _.get(this.state.currentOrder, "order_id", "");
    const payload = {
      order_id: orderId,
    };
    fetchConsolidatedMawbs(payload)
      .then((res) => {
        if (res.success) {
          this.setState({consolidateLoading: false});
          this.setState({consolidatedData : res.data.mawb_orders});
          this.getAllStatus(res.data.mawb_orders);
        } else {
          this.setState({consolidateLoading: false});
        }
      })
      .catch((err) => {
        this.setState({consolidateLoading: false});
        alertMessage(err.message, "error");
      })
      .finally(() => {
        this.setState({consolidateLoading: false});
      });
  };

  openModal = (imageUrl) => {
    this.setState({
      selectedImage: imageUrl,
      modalVisible: true,
    });
  };

  closeModal = () => {
    this.setState({
      selectedImage: '',
      modalVisible: false,
    });
  };

  getWmsApiUrlHostName = (key) => {
    switch (key) {
      case 'app':
        return 'apigw';

      case 'fe-qa':
        return 'api-test';

      default:
        return 'api-beta';
    }
  };

  fetchUserToken = async () => {
    const env = getEnvironment();
    const envUrl = this.getWmsApiUrlHostName(env);
    const url = `https://${envUrl}.fleetenable.com/api/auth/user_token`;
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Accept': 'application/json, text/plain, */*',
    };
    const body = JSON.stringify({
      username: 'stack.test',
      password: 'stack1234',
    });

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: body
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Response data:', data);
      return {success: true, data: data};
    } catch (error) {
      console.error('Error fetching user token:', error);
      alertMessage(`Error fetching java user token: ${error.message}`, "error");
      return {success: false, errors: [error.message]};
    }
  }

  fetchWms = async (payload) => {
    const {
      referId = "",
      currentRole = "",
      orgId = "",
      warehouseIds = "",
    } = payload;
    const env = getEnvironment();
    const envUrl = this.getWmsApiUrlHostName(env);

    const url = `https://${envUrl}.fleetenable.com/api/order/getWhCommentsAndTracks?referId=${referId}&orgId=${orgId}&warehouseIds=${warehouseIds}&currentRole=${currentRole}`;

    try {
      const token_details = JSON.parse(localStorage.getItem('auth') || '{}');
      const token = token_details.access_token;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Accept': 'application/json, text/plain, */*',
          "Authorization": `Bearer ${token}`
        },
      });

      if (!response.ok) {
        return {success: false, errors: [ "Failed to fetch WMS data" ]};
      }

      const data = await response.json();
      return {success: true, data: data};
    } catch (error) {
      return { success: false, errors: [ error ] };
    }
  }

  getWmsDetails = () => {
    const { order_id } = this.state;
    const payload = {
      referId: order_id,
      orgId: this.props.currentOrg.id,
      warehouseIds: this.props.userContext.activeWarehouseid[0],
      currentRole: this.props.userContext.currentRole,
    };
    this.fetchWms({ ...payload  }).then((result) => {
      if (result.success) {
        const wmsData = result.data;  
        this.setState({
              wmsData: {
                unload_details: wmsData.unloadDetails || {},
                wh_comments: wmsData.whComments || [],
                pictures: wmsData.pictures || [],
                pictureTitlePictures: !_.isEmpty(wmsData.pictures) ? wmsData.pictures.filter((e) => _.get(e, 'pictureCode') !== 'POD' && _.get(e, 'pictureTitle') !== 'POD') : [],
                PodPictures: !_.isEmpty(wmsData.pictures) ? wmsData.pictures.filter((e) => _.get(e, 'pictureCode') == 'POD' || _.get(e, 'pictureTitle') == 'POD') : [],
                received_at: wmsData.receivedAt || "",
                wh_status: wmsData.whStatus || "",
                order_status: wmsData.status || "",
                dispatchedAt: wmsData.dispatchedAt || "",
              }
            });
          } else {
            renderAlertMessage(result.errors)
            this.setState({
              wmsData: {
                unload_details: {},
                wh_comments: [],
                dispatchedAt: "",
                order_status: '',
                wh_status: "",
                pictureTitlePictures: [],
                PodPictures: [],
                received_at: "",
                dispatchedAt: "",
                pictures: []
              },
              inProgress: false
            });
      }
    });



    // wmsPreCall(preCallPayload).then((result) => {
    // if(result.success)
    //   {
    //     fetchWms({...payload , java_token : result.token}).then((result) => {
    //       if (result.success) {
    //         this.setState({
    //           wmsData: {
    //             unload_details: result.unloadDetails,
    //             wh_comments: result.whComments,
    //             pictures: result.pictures,
    //             pictureTitlePictures: !_.isEmpty(result.pictures) ? result.pictures.filter((e) => _.get(e, 'pictureTitle') == 'POD') : [],
    //             PodPictures: !_.isEmpty(result.pictures) ? result.pictures.filter((e) => _.get(e, 'pictureTitle') != 'POD') : [],
    //             received_at: result.receivedAt,
    //             wh_status: result.whStatus,
    //             order_status: result.status,
    //             dispatchedAt: result.dispatchedAt || "",
    //           }
    //         });
    //       } else {
    //         renderAlertMessage(result.errors)
    //         this.setState({
    //           wmsData: {
    //             unload_details: {},
    //             wh_comments: [],
    //             dispatchedAt: "",
    //             order_status: '',
    //             wh_status: "",
    //             pictureTitlePictures: [],
    //             PodPictures: [],
    //             received_at: "",
    //             dispatchedAt: result.dispatchedAt || "",
    //             pictures: []
    //           },
    //           inProgress: false
    //         });
    //       }
    //     });
    //   }
    // })
    
  };

  handleMawbClick = () => {
    this.setState({
      mawbButtonClicked: true,
    }); 
  };

  getIsBeyondArea = () => {
    const orderType = findIsbeyondArea(this.state.currentOrder?.locations)
    if(orderType){
      const message = `No billing zones found with provided zipcode (${orderType})`;
      this.setState({ZipMessage: message})
    }
  }

  renderWms = () => {
    const { wh_comments, unload_details, order_status, wh_status, received_at, pictureTitlePictures, PodPictures, dispatchedAt, pictures } = this.state.wmsData;
    const currentOrder = this.state.currentOrder || {}
    // const originalDate = unload_details?.updated_at ?
    //  moment(unload_details.updated_at) : '';
    // const formattedDate = originalDate ? moment(originalDate).format(AppConfig.dateTimeFormat) : "";

    return (

      <>
        <Row type='flex' justify='space-around'>
          <Col span={10}>
            <Card
              size="small"
              title={
                <span className="primaryText textBold fontSize18">
                  {I18n.t("order.unload_details")}
                </span>
              }
            >
              <Descriptions
                bordered
                size="small"
                column={1}
              >
                <Descriptions.Item className="wms_details_heading" label={"Order Status"}>
                  {order_status ? order_status : '-'}
                </Descriptions.Item>
                <Descriptions.Item className='wms_details_heading' label={"Door No"}>
                  {unload_details?.doorNumber ? unload_details.doorNumber : '-'}
                </Descriptions.Item>
                <Descriptions.Item className='wms_details_heading' label={"Truck"}>
                  {unload_details?.truckNumber ? unload_details.truckNumber : '-'}
                </Descriptions.Item>
                <Descriptions.Item className='wms_details_heading' label={"Received At"}>
                  {received_at ? (
                    <Fragment>
                      <DisplayTime
                        dateTimeString={received_at}
                        timeFormat={AppConfig.dateTimeFormat}
                        displayWithDate={true}
                        timeZoneId={currentOrder.order_timeZoneId}
                        isUTC={true}
                      />
                      {` ${currentOrder.order_tz_short_name}`}
                    </Fragment>
                  ) : (
                    "-"
                  )}
                </Descriptions.Item>
                <Descriptions.Item className="wms_details_heading" label={"Warehouse Status"}>
                  {wh_status ? wh_status : '-'}
                </Descriptions.Item>
                <Descriptions.Item className="wms_details_heading" label={"Dispatched At"}>
                  {!_.isEmpty(dispatchedAt) ? (
                    <Fragment>
                      <DisplayTime
                        dateTimeString={dispatchedAt}
                        timeFormat={AppConfig.dateTimeFormat}
                        displayWithDate={true}
                        timeZoneId={currentOrder.order_timeZoneId}
                        isUTC={true}
                      />
                      {` ${currentOrder.order_tz_short_name}`}
                    </Fragment>
                  ) : (
                    "-"
                  )}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
          <Col span={10}>
            <Row>
              <Card
                size="small"
                className="marginBottom20"
                title={
                  <span className="primaryText textBold fontSize18">
                    Pictures
                  </span>
                }
              >
                <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", padding: '1rem' }}>
                  {!_.isEmpty(pictureTitlePictures) ?
                    <ImageViewer
                      showCapturedDate={true}
                      orderPictures={pictureTitlePictures}
                      images={pictureTitlePictures ? pictureTitlePictures.filter(item => item?.picture?.Url).map((item, index) => item.picture.Url) : []}
                    />
                    :
                    <h2 >No Pictures Data</h2>
                  }
                </div>

              </Card>


              <Card
                size="small"
                title={
                  <span className="primaryText textBold fontSize18">
                    POD
                  </span>
                }
              >
                <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", padding: '1rem' }}>
                  {!_.isEmpty(PodPictures) ?
                    <ImageViewer
                      showCapturedDate={true}
                      orderPictures={PodPictures}
                      images={PodPictures ? PodPictures.filter(item => item?.picture?.Url).map((item, index) => item.picture.Url) : []}
                    />
                    :
                    <h2 >No POD Pictures Data</h2>
                  }
                </div>
              </Card>
            </Row>
          </Col>
        </Row>
      <div>
        <WMSList size="small"
         data={wh_comments || []}
         timeZoneId={currentOrder.order_timeZoneId}
         order_tz_short_name={currentOrder.order_tz_short_name}
         />
      </div>
      </>
    )

  }

  componentDidUpdate(prevProps, prevState) {
    if (
        !_.isEqual(
            prevState.organizationSettings,
            this.props.organizationSettings,
        )
    ) {
      this.setState({
        organizationSettings: this.props.organizationSettings,
        isMilitaryTime:
            this.props.organizationSettings.is_military_time == 'true',
      })
    }
    if (
        !equal(
            prevProps.order,
            this.props.order,
        )
    ) {
      this.setState({
        currentOrder: this.props.order,
      })
    }

    if (!_.isEqual(prevProps.warehouseFilter.warehouseWithAccounts, this.props.warehouseFilter.warehouseWithAccounts)) {
      this.getAccounts();
    }

  }
  handleOrderDetailsChange = (element, value, cb = null) => {
    const currentOrder = { ...this.state.currentOrder }
    currentOrder[element] = value
    this.setState({ currentOrder })
  }

  fomatString = (str, format = '') => doFormate(str, format)

  _renderNoteDetails = () => {
    const currentOrder = this.state.currentOrder || {}
    return (
        <Card
            size="small"
            // title={this.state.setNotesKey === "driver_note" ? "Driver Notes" : "Consignee Note"}
        >
          <ConsigneeNote
              order={currentOrder}
              isDriverNote={this.state.setNotesKey === 'driver_note' ? true : false}
          />
        </Card>
    )
  }
  renderPrivateNotes = () => {
    // const currentOrder = this.state.currentOrder || {}
    // return (
    //   <Card
    //     size="small"
    //     >
    //       <Row>
    //       <Col xs={24}>
    //         <span className="textBold">{I18n.t("general.privateNotes")}:</span>
    //         &nbsp;&nbsp;
    //         <span className="alignLeft">
    //           {currentOrder.private_notes !== "" ? currentOrder.private_notes : "N/A"}
    //         </span>
    //       </Col>
    //       </Row>
    //     </Card>
    // )
    const currentOrder = this.state.currentOrder || {}
    const order_private_notes = currentOrder.order_private_notes || [];
    return (
      <PrivateNotes
        organization_id={currentOrder.organization_id}
        currentSourceRecord={currentOrder}
        customer_order_notes={order_private_notes}
        key={currentOrder.id}
        noteTitle={"Private Notes"}
        apiEndpoint={privateNotesApi}
        refer_type="order"
        scroll={{ y: 100 }}
      />
    );
  
  }

  navigateToWarehouse = () => {
    const { history } = this.props
    return history.push(`/locations`)
  }

  navigateToAccount = () => {
    const { order, history, isShipper, } = this.props;
    const {
      account_id,
      account_name,
      account_code,
      primary_account_id,
      email,
      contact_number,
    } = order;
    const hasAccess = checkServiceExistance(["OPSAS", "ACNF"], "ALL");
    const account = !isEmpty(account_name) ? account_name : account_code;
    const navigateTo = !isEmpty(primary_account_id)
      ? primary_account_id
      : !isEmpty(account_id)
      ? account_id
      : "";
    const { pathname } = history.location;
    const screenFrom = pathname.substring(1);
    return !isShipper && hasAccess && !isEmpty(navigateTo) ?(
      <Link
        // onClick={() => {
        //   history.push(`/accounts/${navigateTo}/settings`);
        // }}
        onClick={() => {
          history.push({
          pathname: `/accounts/${navigateTo}/settings`,
          state: { screenFrom }, // Pass screenFrom as state
        });
        }}
      >
        <Tooltip placement="topLeft" title={account}>
          {ellipseText(account, 18)}
        </Tooltip>
      </Link>
    ) : (
      <Fragment>
         <Tooltip placement="topLeft" title={account}>
          {ellipseText(account, 18)}
         </Tooltip>
      </Fragment>

    );
  };

  navigateToLos = (order) => {
    const { history } = this.props
    const { account_id, los_name, primary_account_id } = order;
    const{currentOrder} = this.state;
    const navigateTo = !isEmpty(primary_account_id) ? primary_account_id : (
      !isEmpty(account_id) ? account_id : ''
    );
    const dataObject = order ? order : currentOrder
    return !this.props.isShipper && !isEmpty(navigateTo) ? (
      <Link
        onClick={ () => {
          history.push(`/accounts/${navigateTo}/settings?tab=service_levels_adjustments`);
        } }
      >
        { dataObject.los_name }
      </Link>
    ) : (
      <Fragment>{ dataObject.los_name }</Fragment>
    );
  };

  renderOrderDetails = () => {
  const currentOrder = this.state.currentOrder || {};
  const status = this.fomatString(currentOrder.status);
  const whLocationCode = doFormate(currentOrder.wh_location.location_code);
  const whName = doFormate(currentOrder.wh_location.name);
  const accountDetails = currentOrder?.account_details
    ? currentOrder.account_details
    : {};
    const OtherRefs = _.get(currentOrder, 'references', []) || [];
    const OtherRefsString = OtherRefs.filter(Boolean).join(', ') || '';

  return (
    <Card
      size="small"
      // title={I18n.t("order.details")}
      //style={{ height: "138px" }}
      bodyStyle={{ minHeight: 178 }}
    >
      <Col
        xs={24}
        md={12}
        style={{
          borderRight: "1px solid rgb(0 0 0 / 13%)",
          paddingLeft: "5px",
        }}
      >
        <Details order={currentOrder} isShipper={this.props.isShipper} />
      </Col>
      <Col xs={24} md={12}>
        <Row>
          <span
            className="primaryText textBold fontSize16"
            style={{ marginLeft: "12px" }}
          >
            <span style={{ paddingTop: "12px" }}>
              {I18n.t("account.account")} :
            </span>
            <span style={{ marginLeft: "12px" }} className="details_value">
              {this.navigateToAccount() || "N/A"}
            </span>
          </span>

          {accountDetails.email && (
            <div style={{ marginLeft: "12px" }} className="details_value">
              <div>
                <Icon type="mail" />
                &nbsp;{retrieverValueFromObject(accountDetails, "email")}
              </div>
            </div>
          )}

          {accountDetails.contact_number && (
            <div style={{ marginLeft: "12px" }} className="details_value">
              <Icon type="phone" />
              &nbsp;{retrieverValueFromObject(accountDetails, "contact_number")}
            </div>
          )}
          {accountDetails.address && (
            <div style={{ marginLeft: "12px" }} className="details_value">
              <AddressInfo address={accountDetails.address || {}} showIcon />
            </div>
          )}
        </Row>

        <Row style={{ marginTop: "10px" }}>
          <span
            className="primaryText textBold fontSize16"
            style={{ marginLeft: "12px" }}
          >
            <span style={{ paddingTop: "12px" }}>
              {I18n.t("order.reference_label")}
            </span>
          </span>

          {/* <div style={{ marginLeft: '12px' }}>
              {isEmpty(currentOrder.reference_1_type) &&
              isEmpty(currentOrder.reference_2_type) ? (
                'Refrences are not available'
              ) : (
                <OrderRefrences order={currentOrder} />
              )}
            </div> */}
          <div style={{ marginLeft: "12px" }}>
          <OrderRefrences
              order={currentOrder}
              goToTab={this.goToTab}
              handleMawbClick={this.handleMawbClick}
              consolidatedData={this.state.consolidatedData}
              consolidateLoading={this.state.consolidateLoading}
              getOrderDetails={this.props.getOrderDetails}
              fetchData={this.fetchData}
              isConsolidatedPresent={this.props.isConsolidatedPresent}
            />
          </div>
          { !isEmpty(OtherRefsString) && (
              <Row style={{ marginTop: "10px" }}>
                <span
                  className="primaryText textBold fontSize16"
                  style={ { marginLeft: "12px" } }
                >
                  <span style={ { paddingTop: "12px" } }>
                    {I18n.t("order.other_reference")}
                  </span>&nbsp;
                    <Copyable text={ OtherRefsString } />
                </span>
                <Col xs={ 24 } style={ { marginLeft: "12px" } }>
                  <span>
                    <span>{ OtherRefsString
                    }</span>
                  </span>
                </Col>
              </Row>
          ) }
        </Row>
      </Col>
    </Card>
  );
};


  renderShipperNotes = () => {
    const currentOrder = this.state.currentOrder || {}
    return (
        <Card
            size="small"
            // title="Shipper Notes"
        >
          <ShipperNote order={currentOrder} />
        </Card>
    )
  }

  renderConsigneeDetails = () => {
    const currentOrder = this.state.currentOrder || {};
    const consigneeDetailsObj = currentOrder?.consignee_details ? currentOrder.consignee_details : {};
    let csDetails = { ...currentOrder };
    const { first_name, last_name, phone, phone_2, email, l_address, company_name} = consigneeDetailsObj

    const custName = first_name || last_name  ? this.fomatString(
      `${first_name} ${
          !isEmpty(last_name)
              ? last_name
              : ''
      }`,
    ) : 'NA'
  const customerPhoneOne = phone ? formatUSPhoneNumber(phone) : 'NA';
  const customerPhoneTwo = phone_2 ? formatUSPhoneNumber(phone_2) :''
  const custEmail = this.fomatString(email);

      return (
        <div className="alignLeft">
          <Row type="flex" gutter={8}>
            <Col xs={12}>
              <span className="primaryText textBold">
                {I18n.t("order.customer_details")}
              </span>
            </Col>
            <Col xs={24}>
              <Row type="flex" gutter={8}>
                <Col span={24}>
                  <Col span={12}>
                    {/* <Text className="textBold">{"Name"}</Text>: &nbsp; */}
                    <Row>
                      <Col lg={6} xl={3} xxl={3}>
                        <Icon type="user" />
                      </Col>
                      <Col lg={18} xl={21} xxl={21}>
                        <Text className="details_value_grey">{custName}</Text>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12}>
                      {/* <Text className="textBold">{"Phone"}</Text>: &nbsp; */}
                      <Row>
                        <Col lg={6} xl={3} xxl={3}>
                          <Icon type="mobile" />
                        </Col>
                        <Col lg={18} xl={21} xxl={21}>
                          <Text className="details_value_grey">{`${customerPhoneOne} ${
                            customerPhoneTwo !== "NA" ? `${customerPhoneTwo}` : ''
                          }`}</Text>
                        </Col>
                      </Row>
                    </Col>
                  </Col>
              </Row>
              <Row gutter={8}>
                <Col span={24} className='paddingLeft15'>
                                    {/* <Col span={12}>
                    <Text className="textBold">{"Company"}</Text>: &nbsp;
                    <Row>
                      <Col lg={6} xl={3} xxl={3}>
                        <Icon type="bank" />
                      </Col>
                      <Col lg={18} xl={21} xxl={21}>
                        <Text className="details_value_grey">{companyName}</Text>
                      </Col>
                    </Row>
                  </Col> */}
                  {/* <Col span={12}>
                    <Text className="textBold">{"Email"}</Text>: &nbsp;
                    <Row>
                      <Col lg={6} xl={3} xxl={3}>
                        <Icon type="mail" />
                      </Col>
                      <Col lg={18} xl={21} xxl={21}>
                        <Text className="details_value_grey">{custEmail}</Text>
                      </Col>
                    </Row>
                  </Col> */}
                   {
                      <AddressInfo address={l_address || {}} showIcon showNAInfo={true}/>
                    }
                </Col>

              </Row>
            </Col>
          </Row>
        </div>
      );
  }


  renderCustomerDetails = (locationDetails = null) => {
    const currentOrder = this.state.currentOrder || {};
    const dataObject = locationDetails ? locationDetails : currentOrder;
    let csDetails = { ...currentOrder };
    if (locationDetails) {
      csDetails.customer_first_name = dataObject.first_name;
      csDetails.customer_last_name = dataObject.last_name;
      csDetails.customer_phone_one = dataObject.phone;
      csDetails.customer_phone_two = dataObject.phone_2;
      csDetails.customer_email = dataObject.email;
      csDetails.cs_location = Object.assign(
          ({},
              {
                l_address: locationDetails.l_address,
                company_name: locationDetails.company_name,
              })
      );
    }
    const custName = this.fomatString(
        `${csDetails.customer_first_name} ${
            !isEmpty(csDetails.customer_last_name)
                ? csDetails.customer_last_name
                : ''
        }`,
    )
    const customerPhoneOne = formatUSPhoneNumber(
        csDetails.customer_phone_one,
    )
    const customerPhoneTwo = formatUSPhoneNumber(
        csDetails.customer_phone_two,
    )
    const custEmail = this.fomatString(csDetails.customer_email)
    const cs_location = csDetails.cs_location
        ? csDetails.cs_location.l_address
        : {}
    const companyName = csDetails?.cs_location
        ? csDetails.cs_location.company_name
        : {}
    const distance = locationDetails?.distance ? locationDetails.distance : csDetails.distance
    const isTransferPickup = locationDetails?.order_type === 'T' && locationDetails?.type_of_loc === 'PICKUP'

    return (
        <Card
            size="small"
            bodyStyle={{ height: '190px' }}
            // title={I18n.t("customer.details")}
            // extra={
            //   <Fragment>
            //     <span onClick={() => this.props.surveyDetails(this.state.currentOrder.id)} >Survey Details</span> &nbsp;
            //     <span onClick={() => this.props.formDetails(this.state.currentOrder.id)} >{I18n.t('questionaries.form_details')}</span>
            //   </Fragment>
            // }
        >
          <div className="alignLeft">
            <Row>
              <Col xs={12}>
              <span className="primaryText textBold fontSize18">
                {!this.props.isAirCargo && (
                  <EndUserTitle stringToAppend={I18n.t("general.details")} />
                )}
              </span>
            </Col>
            <Col xs={12}>
            <Row>
              <Col md={24}>
                <div className="textBold alignRight">
                  <span>
                    <BranchesIcon />
                    &nbsp;
                    {I18n.t("general.distance")}&nbsp;:&nbsp;
                    <span>{distance} {""}mi.</span>
                  </span>
                </div>
              </Col>
            </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Profile
                address={cs_location}
                profile={{
                  image: { url: customerlogo },
                  full_name: custName,
                  mobile_number: `${customerPhoneOne} ${
                    customerPhoneTwo !== 'NA' ? `,${customerPhoneTwo}` : ''
                  }`,
                  email: custEmail,
                  distance: distance,
                  company_name: companyName,
                }}
                showImage={false}
                isMobileScreen={false}
                withLables={false}
                isCustomer={true}
                showDistance={false}
              />
            </Col>

              {!_.isObject(cs_location) ||
                  (cs_location.coordinates &&
                      (parseFloat(cs_location.coordinates[0]) === 0.0 ||
                          parseFloat(cs_location.coordinates[1]) === 0.0) && (
                          <div className="marginLeft10">
                            <Icon
                                type="warning"
                                style={{
                                  fontSize: '18px',
                                  color: '#ff0000',
                                }}
                            />
                            <span className="marginLeft10">
                      {' '}
                              {I18n.t('messages.invalid_address')}.{' '}
                    </span>
                          </div>
                      ))}
            </Row>
          </div>
        </Card>
    )
  }

  renderDispatcherNotes = () => {
    const currentOrder = this.state.currentOrder || {}
    const { customer_order_notes } = currentOrder
    return (
        <DispatcherNotes
            organization_id={this.state.orders.organization_id}
            currentSourceRecord={currentOrder}
            customer_order_notes={customer_order_notes}
            key={currentOrder.id}
            refer_type="order"
            apiEndpoint={dispatcherNotesApi}
            noteTitle="Notes"
            scroll={{y:100}}
        />

    )
  }

  handleUpdateItemGrouping = (items) => {
    const { currentOrder } = this.state;
    const order_items = currentOrder.order_items;
    // const typeOfOrder = formData.type_of_order;
    // let updatedData = _.cloneDeep(formData)
    if (_.isArray(items)) {
      items.forEach((item) => {
        const itemIndex = _.findIndex(order_items, ["item_id", item.id]);
        if (itemIndex >= 0) {
          order_items[itemIndex].item_group_id = item.item_group_id;
          // updatedData = this.setItemtoFormData(
          //   order_items[itemIndex],
          //   updatedData
          // );
        }
      });
    }
    this.setState(
        {
          order_items,
          groupItems: false,
          // isModified: true,
          // formData: _.cloneDeep(updatedData),
          // isItemNew: false,
          // currentItem: {},
        },
        () => {
          // this.setUpdatedQuoteAmount();
        }
    );
  };


  handleOrderDetailsOnUpdate = () => {
    // const { currentOrder } = this.state;
    // fetchOrderDetails(currentOrder.id).then((response) => {
    //   if (response && response.success && response.orders) {
    //     const rec = _.find(response.orders, ["id", currentOrder.id]);
    //     if(rec) {
    //     this.setState({
    //       currentOrder: response.orders[0],
    //     });
    //   }
    //   }
    // });
    this.props.getOrderDetails()
  };

  renderItemsExceptionModal = () => {
    const { currentRecord,showItemExceptionModal } = this.state;
    return (
      <ItemsExceptionModal
        visible={showItemExceptionModal}
        onCancel={() => {
          this.setState({ showItemExceptionModal: false });
        }}
        data={currentRecord}
        isMilitaryTime={this.state.isMilitaryTime}
      />
    );
  };

  renderItems = () => {
    const currentOrder = this.state.currentOrder || {}
    const { orderItems } = this.state
    let items = currentOrder.order_items ? currentOrder.order_items : []
    let orderItemRemark = _.get(currentOrder, "item_remarks_label", "")
    let isRemarkPresent = _.get(currentOrder,"item_remarks","")
    // const accessorials = currentOrder.org_customer_accessorials || [];
    return (
        <Fragment>
          <Card
              title={
                <span className="primaryText textBold fontSize18">
              {I18n.t("order.item_details")}
            </span>
              }
              size="small"
              extra={
                  !this.state.groupItems && items.length > 1 && checkServiceExistance("SCA") && (<div className="alignRight marginTop10">
                    <Button
                        size="small"
                        type="primary"
                        onClick={() => {
                          this.setState({
                            groupItems: true,
                          });
                        }}
                    >
                      {" "}
                      {I18n.t("order.group_items")}
                    </Button>
                  </div>)
              }
          >
            {!this.state.groupItems ? (
                <ItemListDetails
                    data={items}
                    isVisible
                    showItemType={false}
                    showActions={true}
                    showException={true}
                    pagination={{ position: "none" }}
                    itemRemarkLabel = {orderItemRemark}
                    checkForRemark = {isRemarkPresent}
                    size="small"
                    orderConfig={this.props.orderConfig}
                    rowClassName={(record, index) =>
                      record.item_picture_data && record.item_picture_data?.some(item => _.get(item, "exception_code") !== "") ? "errRow" : ""
                  }
                    handleModalView={(record) => {
                      this.setState({
                        showItemExceptionModal: true,
                        currentRecord: record,
                      });
                    }
                    }
                />
            ) : (
                <Fragment>
                  <ItemGrouping
                      items={items || []}
                      onCancel={() => {
                        this.setState({
                          groupItems: false,
                        });
                      }}
                      customer_order_id={this.state.order_id}
                      updateItems={(updatedItems) => {
                        this.handleUpdateItemGrouping(updatedItems);
                      }}
                      orderConfig={this.props.orderConfig}
                  />
                </Fragment>
            )}
          </Card>
          {false &&
              currentOrder.order_items.length > 0 &&
              currentOrder.order_items.map((item, index) => (
                  <Col lg={12} md={12} sm={24} xs={24} className="padding5">
                    <ItemDetails order_item={item} accessorials={accessorials} />
                  </Col>
              ))}
              {this.state.showItemExceptionModal && this.renderItemsExceptionModal()}
        </Fragment>
    );
  }

  _renderExceptions = () => {
    const currentOrder = this.state.currentOrder || {}
    const orderExceptions = currentOrder.order_exceptions || []
    return (
        <ExceptionsList
            data={orderExceptions}
            pagination={{ position: 'none' }}
            size="small"
            timeZone={currentOrder.order_timeZoneId}
            timeZoneName={currentOrder.order_tz_short_name}
            isMilitaryTime={this.state.isMilitaryTime}
            scroll = {{y: this.props.fromIndividualScreen ? "calc(100vh - 290px)" : "calc(100vh - 180px)"}}
        />
    )
  }

  _renderItemExceptions = () => {
    const currentOrder = this.state.currentOrder || {};
    const orderItems = _.get(currentOrder, "order_items", []);
    const exceptionOrderItems = orderItems.filter((items) => {
      return items.failed_notes && !_.isEmpty(items.failed_notes) || items.item_picture_data && items.item_picture_data.some((item) => _.get(item, "exception_code") !== "");
    }
    );
    return exceptionOrderItems.length > 0 ? (
      <ItemsExceptionLogList
        data={exceptionOrderItems}
        isMilitaryTime={this.state.isMilitaryTime}
        scroll={{
          y: this.props.fromIndividualScreen
            ? "calc(100vh - 290px)"
            : "calc(100vh - 180px)",
        }}
        pagination={{ position: "none" }}
      />
    ) : (
      <Empty
       description={<b>There are No Item Exceptions</b>}
       image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
       imageStyle={{
        height: 60,
      }}
       />
    )
  };
  

  // renderReferences = () => {
  //   const currentOrder = this.state.currentOrder || {}
  //   return (
  //     <Card
  //       // title={I18n.t("order.reference_label")}
  //       size="small"
  //       style={{ height: '138px' }}
  //       bodyStyle={{ minHeight: 30 }}
  //     >
  //       {isEmpty(currentOrder.reference_1_type) &&
  //       isEmpty(currentOrder.reference_2_type) ? (
  //         'Refrences are not available'
  //       ) : (
  //         <OrderRefrences order={currentOrder} />
  //       )}
  //     </Card>
  //   )
  // }

  renderScheduledDate = (locationDetails = null) => {
    const currentOrder = this.state.currentOrder || {};
    //const dataObject = locationDetails ? locationDetails : currentOrder
    return (
      <Card size="small" > {/* style={{ height: '240px' }} */}
        <OrderScheduleDate
          order={currentOrder}
          handleOrderDetailsOnUpdate={this.handleOrderDetailsOnUpdate}
          locationDetails={locationDetails}
          showActions={!this.props.isShipper}
         />
      </Card>
    )
  }

  renderOrderPics = (locationDetails = null) => {
    const currentOrder = this.state.currentOrder || {}
    return (
        <OrderPictures
            currentLocation={locationDetails}
            order_id={currentOrder.id}
            takeMultipleSigns={false}
            showType="imageClick"
            account_id={currentOrder.account_id}
            currentOrder={currentOrder}
            customer_order_number = {currentOrder.customer_order_number}
            showActions={!this.props.isShipper}
            styleObj={{ height: '190px' }}
        />
    )
  }

  renderDriversFeedback = () => {
    const currentOrder = this.state.currentOrder || {}
    const feedbacks = currentOrder.feedbacks ? currentOrder.feedbacks : []
    const driverFeedback = feedbacks.filter((x) => x.f_user_type === 'DRIVER')
    return (
        currentOrder.driver_details &&
        currentOrder.driver_details.length > 0 &&
        driverFeedback.length > 0 && (
            <Col xs={24}>
              <h3 className="details_heading">Driver Feedback</h3>
              {driverFeedback.map((reviewRow, index) => (
                  <Row key={index}>
                    <Col xs={4} style={{ textAlign: 'center' }}>
                      {currentOrder.driver_details[0].image &&
                      currentOrder.driver_details[0].image.url ? (
                          <Avatar
                              src={`${currentOrder.driver_details[0].image.url}`}
                              size={40}
                          />
                      ) : (
                          <Avatar
                              src="./../icons/user_100*100.png"
                              alt="DriverProfile"
                              size={40}
                          />
                      )}
                    </Col>
                    <Col xs={20}>
                      <h4 style={{ marginBottom: '0em' }}>
                        {currentOrder.driver_details[0].first_name ||
                        currentOrder.driver_details[0].last_name
                            ? `${currentOrder.driver_details[0].first_name} ${currentOrder.driver_details[0].last_name}`
                            : 'NA'}
                      </h4>
                      <Rate
                          allowHalf
                          disabled
                          defaultValue={parseFloat(reviewRow.f_rating)}
                          style={{ fontSize: 16 }}
                      />{' '}
                      &nbsp;&nbsp;
                      <span style={{ fontSize: 12 }}>
                  {reviewRow.updated_at
                      ? moment(new Date(reviewRow.updated_at)).format('lll')
                      : 'NA'}
                </span>
                      <p style={{ marginTop: 10 }}>
                        {reviewRow.f_text && reviewRow.f_text}
                      </p>
                    </Col>
                  </Row>
              ))}
            </Col>
        )
    )
  }

  renderCustomersFeedback = () => {
    const currentOrder = this.state.currentOrder || {}
    const feedbacks = currentOrder.feedbacks ? currentOrder.feedbacks : []
    const customerFeedback = feedbacks.filter(
        (x) => x.f_user_type === 'CUSTOMER',
    )
    const custName = this.fomatString(
        `${currentOrder.customer_first_name} ${currentOrder.customer_last_name}`,
    )
    return customerFeedback.length > 0 ? (
        <Col xs={24}>
          <h3 className="details_heading">{I18n.t('feedback.customer')}</h3>
          {customerFeedback.map((reviewRow, index) => (
              <Row key={index}>
                <Col xs={4} style={{ textAlign: 'center' }}>
                  <Avatar src={customerlogo} size={40} />
                </Col>
                <Col xs={20}>
                  <h4 style={{ marginBottom: '0em' }}>{custName}</h4>
                  <Rate
                      allowHalf
                      disabled
                      defaultValue={parseFloat(reviewRow.f_rating)}
                      style={{ fontSize: 16 }}
                  />
                  &nbsp;&nbsp;
                  <span style={{ fontSize: 12 }}>
                {reviewRow.updated_at
                    ? moment(new Date(reviewRow.updated_at)).format('lll')
                    : 'NA'}
              </span>
                  <p style={{ marginTop: 10 }}>
                    {reviewRow.f_text && reviewRow.f_text}
                  </p>
                </Col>
              </Row>
          ))}
        </Col>
    ) : (
        ''
    )
  }

  handleQuesFormDetails = (id, locationId = null) => {
    this.setState({
      showQuestionForm: true,
      currentProcessingOrder: id,
      currentProcessingLocation: locationId,
    })
  }

  closeQuestionFormDetails = () => {
    this.setState({
      showQuestionForm: false,
      currentProcessingOrder: '',
      currentProcessingLocation: null,
    })
  }

  _renderOrderQuoteAmount = () => {
    const currentOrder = this.state.currentOrder || {}
    const quoteOrders = []
    const details = this.props.details || {}
    const locations = currentOrder.locations || [];
    if(locations?.length){
      locations.forEach(loc => {
        quoteOrders.push({
          id: currentOrder.id,
          order_no: currentOrder.customer_order_number,
          label: details.label,
          quote_order_number: currentOrder.quote_order_number,
          location_id: loc.id || '',
          type_of_loc: loc.type_of_loc,
        });
      })
    }else {
      quoteOrders.push({
        id: currentOrder.id,
        order_no: currentOrder.customer_order_number,
        label: details.label,
        quote_order_number: currentOrder.quote_order_number,
        location_id: currentOrder?.cs_location?.id || '',
        type_of_loc: null,
      });
    }


    // quoteOrders.push({
    //   id: currentOrder.id,
    //   order_no: currentOrder.customer_order_number,
    //   label: details.label,
    //   quote_order_number: currentOrder.quote_order_number,
    // })
    return (
        <OrderQuoteAmount
            orders={quoteOrders}
            org_id={userStore.getStateValue('selectedOrg')}
            actionType="show"
        />
    )
  }

  _renderWarehouseAccessorials = () => {
    const currentOrder = this.state.currentOrder || {}
    const orders = []
    const details = this.props.details || {}
    orders.push({
      id: currentOrder.id,
      order_no: currentOrder.customer_order_number,
      label: details.label,
      required_accessorials: currentOrder.required_accessorials || []
    })
    return(
      <AccWhAccessorial
          accountId={currentOrder.account_id}
          orders={orders}
          actionType="show"
          org_id={userStore.getStateValue('selectedOrg')}
      />

    )
  }

  renderExpandedRow = (record) => {
    const auditLogData = Array.isArray(record?.audit_log) ? record.audit_log.map(rec => ({ ...rec, id: randomNumber() })) : [];
    return auditLogData.length > 0 ? (
      <Row>
        <Col span={ 8 } className="padding10">
          <h3>Audit</h3>
          <OrderFiledActivities data={ auditLogData } pagination={ { position: 'none' } } />
        </Col>
      </Row>
    ) :
      (
        <Row>
          <Text className="textCenter">No Audit Log Found</Text>
        </Row>
      )
      ;
  };



  renderActivities = () => {
    const currentOrder = this.state.currentOrder || {}
    // const timeZoneId = currentOrder.wh_location ? currentOrder.wh_location.timeZoneId : '';
    const activities = currentOrder.order_activities || []
    const fineTunedActivities = activities.map(rec=> ({
      ...rec,
      id: rec.id ? rec.id : randomNumber(),
    }))
    return (
      <OrderActivities
        rowClassName={(record) => record?.description ?
          _.includes(record.description.toLowerCase(), "complete")
            ? "bgd-color-green"
            : _.includes(record.description.toLowerCase(), "fail")
              ? "bgd-color-red"
              : null : null
        }
        isMilitaryTime={this.state.isMilitaryTime}
        data={fineTunedActivities}
        size="small"
        pagination={{ position: "none" }}
        timeZone={currentOrder.order_timeZoneId}
        timeZoneName={currentOrder.order_tz_short_name}
        expandedRowRender={this.renderExpandedRow}
        scroll={{
          y: this.props.fromIndividualScreen
            ? "calc(100vh - 290px)"
            : "calc(100vh - 180px)",
        }}
      />
    )
  }

  renderNotification = () => {
    const currentOrder = this.state.currentOrder || {}
    const notificationLog = currentOrder.order_notifications || []
    return (
        <NotificationList
            isMilitaryTime={this.state.isMilitaryTime}
            data={notificationLog}
            size="small"
            pagination={{ position: 'none' }}
            timeZone={currentOrder.order_timeZoneId}
            timeZoneName={currentOrder.order_tz_short_name}
            rowKey={(record) => record.id}
            expandedRowRender={(record) => (
                <Fragment>
                  <Row>
                    <Col span={24}>
                      <div className="marginTop10 marginBottom15">
                        <span className="textBold">{I18n.t('general.message')}:</span>
                        &nbsp;&nbsp;
                        <span className="fontSize13">
                    {record.notification_msg_body}
                  </span>
                      </div>
                      {!isEmpty(record.status_logs) && _.isArray(record.status_logs) && (
                          <div>
                            <Timeline>
                              {record.status_logs.map((errLog, index) => {
                                const hasErr = !isEmpty(errLog.error)
                                return (
                                    <Timeline.Item
                                        color={hasErr ? 'red' : 'blue'}
                                        key={index}
                                    >
                            <span className="textCaptilize fontSize12">
                              {errLog.status}
                              {hasErr
                                  ? ` (${I18n.t(
                                      `sms.error_codes.${errLog.error}`,
                                  )})`
                                  : ''}
                              {errLog.tracked_at && (
                                  <span>
                                  &nbsp; on &nbsp;
                                    {momentTimeWithShortName(
                                        this.state.isMilitaryTime
                                            ? 'MMM D, YYYY HH:mm'
                                            : 'lll',
                                        errLog.tracked_at,
                                        currentOrder.order_timeZoneId,
                                        currentOrder.order_tz_short_name,
                                    )}
                                </span>
                              )}
                            </span>
                                    </Timeline.Item>
                                )
                              })}
                            </Timeline>
                          </div>
                      )}
                      {/* <StatusLogList
                  data={!isEmpty(record.status_logs) && _.isArray(record.status_logs)? record.status_logs :[]}
                  pagination={{ position: "none" }}
                  size="small"
                  timeZone={currentOrder.order_timeZoneId}
                  timeZoneName={currentOrder.order_tz_short_name}
                /> */}
                    </Col>
                  </Row>
                </Fragment>
            )}
            scroll = {{y: this.props.fromIndividualScreen ? "calc(100vh - 290px)" : "calc(100vh - 180px)"}}
        />
    )
  }

  renderOrderAccssorials = (currentLocation) => {
    const currentOrder = this.state.currentOrder || {}
    return (
        <ViewOrderAccessorial
            account_id={currentOrder.account_id}
            currentLocation={currentLocation?.id ? currentLocation.id : null}
            customer_order_id={currentOrder.id}
            org_id={userStore.getStateValue('selectedOrg')}
            isMilitaryTime={this.state.isMilitaryTime}
            config={this.props.config}
            haveMultipleLocations={currentOrder?.locations?.length > 1}
        />
    )
  }

  goToTab = (step) => {
    this.setState({
      activeKey: step,
    })
  }
  goToNotesTab = (setKey) => {
    this.setState({
      setNotesKey: setKey,
    })
  }

  updateQuote = (id, amount) => {
    this.handleOrderDetailsChange('quotation_amount', amount)
  }

  handleStatusResult = (result) => {
    if (result.success) {
      alertMessage(I18n.t('messages.processed'), 10)
      const updatedStatus =  result.orders.status;
      if(!isEmpty(updatedStatus)){
        this.handleOrderDetailsChange('status',updatedStatus )
        this.props.updateParentState('is_updated', true);
      }
    } else {
      if (result.errors && result.errors.length > 0) {
        alertMessage(result.errors.join(','), 'error', 10)
      }
    }
  }

  enableLoading = (loadingStatus) => {
    this.setState({
      inProgress: loadingStatus,
    })
  }

  renderCSDetails = (index, location = null, locationBased) => {
    const {currentOrder} = this.state;
    const driver_attempts = currentOrder?.driver_attempts ? currentOrder.driver_attempts : []
    const showDriverDetails = !!location; // Convert location to a boolean value
    const orderPicturesData = location ? location : this.state.currentOrder;
    const checkForManual = ["T","LH"].includes(currentOrder.type_of_order) ? _.get(currentOrder, `locations[${index}].is_manual`, false) : _.get(currentOrder, `is_manual`, false);
    const checkForCompleteStatus = ["T","LH"].includes(currentOrder.type_of_order) ? _.get(currentOrder, `locations[${index}].status`, null) : _.get(currentOrder, `status`, null);
    const renderEditIcon = checkForCompleteStatus === "COMPLETED" && checkForManual === true ? true : false;

    return (
      <Row className={ location ? "" : "marginTop10" } key={ index }>
        <Col span={ showDriverDetails ? 10 : 8 } className="currentOrder_details">
          { this.renderCustomerDetails(location) }
        </Col>
        { !showDriverDetails && (
          <Col span={ 4 } >
            <DriverDetails currentOrder={ this.state.currentOrder } cardStyle={ {
              height: 190,
            } }
            edit={renderEditIcon}
            getOrderDetails = {this.props.getOrderDetails}
            locationBased={ locationBased }
            isShipper={this.props.isShipper}
             />
          </Col>
        ) }

        {/* Conditionally render the DriverDetails column */ }
        { showDriverDetails && (
          <Col span={ 4 } className="currentOrder_details">
            <DriverDetails currentOrder={{
              ...location,
              driver_attempts: location?.id ? driver_attempts.filter(rec => rec.location_id === location.id ) : []
            }} cardStyle={{ height: 190 }} edit={renderEditIcon} getOrderDetails = {this.props.getOrderDetails} locationBased={locationBased}
            isShipper={this.props.isShipper}
            />
          </Col>
        ) }

        <Col span={ showDriverDetails ? 5 : 6 } className="currentOrder_details">
          { this.renderOrderPics(location) }
        </Col>

        <Col span={ showDriverDetails ? 5 : 6 } className="currentOrder_details">
          { this.renderScheduledDate(location) }
          <Col
            span={ 24 }
            className="currentOrder_details"
            style={ { padding: "0px", marginTop: 5 } }
          >
            <SurveyFormIcon
              order={ this.state.currentOrder }
              handleQuesFormDetails={ this.handleQuesFormDetails }
              surveyDetails={ this.props.surveyDetails }
              locationDetails={ location }
            />
          </Col>
        </Col>
      </Row>
    );
  };

  printBol = () => {
    this.setState({
      isGeneratingPdf: true,
    });
    fetchBolPdf(this.state.currentOrder.id)
        .then((result) => {
          if (result.success) {
            base64ToPdf(
                result.bol_file,
                `OrderBol${this.state.currentOrder.id}.pdf`
            );
          } else {
            alertMessage("failed to generate pdf","error", 10)
          }
        })
        .finally(() => {
          this.setState({
            isGeneratingPdf: false,
          });
        });
  }

  renderAccessorial = (details, currentLocation) => {
    return (<Collapse
        defaultActiveKey={[`acc${details.key}`]}
        style={{ height: 'auto' }}
        expandIconPosition="right"
        className="marginBottom10 customCollapse"
        key={`Colacc${details.key}`}
        size="small"
    >
      <Panel
          header={
            <Fragment>{currentLocation && `${I18n.t(`order.${currentLocation.type_of_loc}`)} `} {I18n.t('general.accessorial')}</Fragment>
          }
          key={`acc${details.key}`}
          size="small"
      >
        { this.renderOrderAccssorials(currentLocation)}
      </Panel>
    </Collapse>)
  }

  renderAdjustments = (details, currentLocation) => {
    return (<Collapse
        defaultActiveKey={[`adj${details.key}`]}
        style={{ height: 'auto' }}
        expandIconPosition="right"
        className="marginBottom10 customCollapse"
        key={`Colacc${details.key}`}
        size="small"
    >
      <Panel
          header={
            <Fragment>{currentLocation && `${I18n.t(`order.${currentLocation.type_of_loc}`)} `} 
            {I18n.t('adjustments.label')}({this.state.currentOrder.customer_order_number})</Fragment>
          }
          key={`adj${details.key}`}
          size="small"
      >
        <InvoicesLines order={this.state.currentOrder}/>
      </Panel>
    </Collapse>)
  }

  renderServiceDuration = (dataObject) => {
    const serviceDuration = !isEmpty(dataObject.service_duration)
        ? `${dataObject.service_duration} ${I18n.t(
            'general.min_short_cut',
        )}`
        : 'NA'
    return (
        <Fragment><Icon type="clock-circle" style={{ fontSize: 20, marginRight: 4 }} />
          <span className="textBold fontSize15">
        <span>{I18n.t('order.duration')}</span>
            &nbsp;:&nbsp;
            <span className="details_value_grey">
          {' '}
              {serviceDuration}
        </span>
      </span></Fragment>)
  }

  renderExtraLocDetails = (currentLocation) => {
    return <Row type='flex' align='middle' justify='space-between' gutter={ 8 }>
      { (currentLocation.order_type !== 'LH') && !isEmpty(currentLocation.los_name) && (
        <Col>
          <AntText className="details_heading">{ I18n.t("los.label") }</AntText> :  <AntText className="details_value">{ this.navigateToLos(currentLocation) }</AntText>
        </Col>
      ) }
      <Col>
        { this.renderServiceDuration(currentLocation) }
      </Col>
    </Row>;
  }


  render() {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 150, // set width to 150 pixels
        render: (data, record) => formatFullName(record)
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: 200, // set width to 200 pixels
      },
      {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'phone',
        width: 100, // set width to 100 pixels
      },
    ];

    const currentOrder = this.state.currentOrder || {}
    const details = this.props.details || {}
    const labelsInfo = {
      Origin: _.get(currentOrder, 'origin_port') || 'NA',
      Destination: _.get(currentOrder, 'destination_port') || 'NA',
      ITNo: _.get(currentOrder, 'it_number') || 'NA',
      RouteName: _.get(currentOrder, 'routing') || 'NA',
    };
    const orderExceptions = currentOrder.order_exceptions || []
    const exceptionsInDesc =
        currentOrder.status === 'EXCEPTION'
            ? sortByDate(orderExceptions, 'updated_at', 'descend')
            : ''
    const showActions = !this.props.isShipper
    const {isDeletedOrder} = this.state
    const current_role = userStore.getCurrentRole();
    const accountCodesWithExceededLimit = this.props.creditsExceededAccnts.map(rec => rec.account_code);
    const requiredConsignee = this.props?.organizationSettings?.['require_consignee_details'] === 'true' || false;
    const renderLineHaulTab = currentOrder?.type_of_order === 'LH' ? true : false;
    return (
      <Fragment>
      <Spin spinning={this.state.inProgress} delay={1000}>
        <div className="card-container">
          <Row>
            <Col xs={24}>
              <div className="alignCenter" style={{ marginBottom: 5, marginTop: -5 }}>
                {currentOrder.status === 'PENDING' && (

                    <PendingOrdersActions
                        handleStatusResult={this.handleStatusResult}
                        enableLoading={this.enableLoading}
                        orderIds={[currentOrder.id]}
                        size="small"
                    />

                )}
              </div>
              <span style={{ color: '#ffff',float: 'right', marginTop: -20 }} className="paddingLeft15">
              {currentOrder.id}
          </span>
            </Col>
          </Row>
          <Tabs
              type="card"
              onChange={this.goToTab}
              activeKey={this.state.activeKey}
              tabBarExtraContent={
                <Row
                    type="flex"
                    style={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'center',
                      padding: '0 10px',
                    }}
                >
                  {
                    currentOrder?.pdf_response?.response && <Col>
                    <MiscData pdf_response={currentOrder.pdf_response} position={{ x: -400 , y: 0}}/>
                  </Col>
                  }
                  {
                      !currentOrder.is_location_based &&
                      <Col className="serviceDurationDetail">
                        {this.renderServiceDuration(currentOrder)}
                      </Col>
                  }
                  <Col>
                    <OrderStatus
                        record={currentOrder}
                        displayKey="status"
                        label=""
                        showLabel={false}
                        className="textBold textPrimary"
                        displayType="button"
                        popoverMsg={
                          exceptionsInDesc.length > 0
                              ? exceptionsInDesc[0].exception_message
                              : (currentOrder.status === 'ON_HOLD' && currentOrder.on_hold_reason ?  currentOrder.on_hold_reason : '')
                        }
                    />
                  </Col>


                </Row>
              }
          >
            <TabPane tab="Details" key="details" className="orderDetailScroll">
              {isEmpty(currentOrder) || isEmpty(currentOrder.id) ? (
                  <Skeleton active />
              ) : (
                  <div className="order_details_parent_div detailsSection">
                       <div className={currentOrder.is_beyond_area || accountCodesWithExceededLimit.includes(currentOrder.account_code) ? 'marginBottom10' : ''}>
                        <Row type='flex' gutter={ 8 }>
                          {currentOrder.is_beyond_area && (
                          <Col>
                            <Alert
                              message={!isEmpty(this.state.ZipMessage) ? this.state.ZipMessage : "No billing zones found with provided zipcode"}
                              type="error"
                                style={ {
                                  width: '100%',
                                } }
                              showIcon
                              icon={<Icon type="info-circle" theme="filled" />}
                              closable
                            />
                          </Col>
                          )}
                          {accountCodesWithExceededLimit.includes(currentOrder.account_code) && (
                          <Col >
                            <Alert
                              message="Account Credit Limit Exceeded"
                              type="error"
                                style={ {
                                  width: '100%',
                                } }
                              showIcon
                              icon={<Icon type="info-circle" theme="filled" />}
                              closable
                            />
                          </Col>
                          )}
                        </Row>
                        </div>
                    <Row>
                      <Col
                          sm={24}
                          xs={24}
                          md={12}
                          lg={12}
                          className="currentOrder_details"
                      >
                        <this.renderOrderDetails />
                        </Col>
                        <Col
                          sm={ 24 }
                          xs={ 24 }
                          md={ 12 }
                          lg={ 12 }
                          className="currentOrder_details"
                        >
                           <Card size="small">
                          <Col>
                            <Row type="flex">
                              <Col span={12}>
                                <Row className="">
                                  <Col className="leftBlock" xs={12}>
                                    <span className="primaryText textBold ">
                                      {"Received Info"}
                                    </span>
                                  </Col>
                                  <Col xs={24} className="fontSize13">
                                    <Text className="textBold">
                                      {"Received at"}
                                    </Text>
                                    : &nbsp;
                                    <Text className="details_value_grey">
                                      {currentOrder.received_at
                                        ? formatByTimeConfig(
                                            moment(currentOrder.received_at),
                                            this.state.isMilitaryTime,
                                            "MMM Do YYYY HH:mm",
                                            "MMM Do YYYY h:mm A"
                                          )
                                        : "NA"}
                                    </Text>
                                  </Col>
                                  <Col xs={24} className="fontSize13">
                                    <Text className="textBold">
                                      {"Delivery By"}
                                    </Text>
                                    : &nbsp;
                                    <Text className="details_value_grey">
                                      {currentOrder.delivery_by
                                        ? formatByTimeConfig(
                                            moment(currentOrder.delivery_by),
                                            this.state.isMilitaryTime,
                                            "MMM Do YYYY HH:mm",
                                            "MMM Do YYYY h:mm A"
                                          )
                                        : "NA"}
                                    </Text>
                                  </Col>
                                  <Col>
                                    <Col
                                      className="leftBlock"
                                      xs={12}
                                      style={{ marginTop: 10 }}
                                    >
                                      <span className="primaryText textBold">
                                        {"Labels Info"}
                                      </span>
                                    </Col>
                                    <Col xs={24}>
                                      <Row type="flex" gutter={8}>
                                        <Col>
                                          <Text className="textBold">
                                            {"Origin"}
                                          </Text>
                                          : &nbsp;
                                          <Text className="details_value_grey">
                                            {labelsInfo.Origin}
                                          </Text>
                                          <br />
                                          <Text className="textBold">
                                            {"IT No"}
                                          </Text>
                                          : &nbsp;
                                          <Text className="details_value_grey">
                                            {labelsInfo.ITNo}
                                          </Text>
                                          {labelsInfo.ITNo &&
                                            labelsInfo.ITNo !== "NA" && (
                                              <Copyable
                                                text={labelsInfo.ITNo}
                                              />
                                            )}
                                        </Col>

                                        <Col>
                                          <Text className="textBold">
                                            {"Destination"}
                                          </Text>
                                          : &nbsp;
                                          <Text className="details_value_grey">
                                            {labelsInfo.Destination}
                                          </Text>
                                          <br />
                                          <Text className="textBold">
                                            {"Routing"}
                                          </Text>
                                          : &nbsp;
                                          <Text className="details_value_grey">
                                            {labelsInfo.RouteName}
                                          </Text>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Col>
                                </Row>
                              </Col>
                              <Col span={12}>
                                <Row className="" gutter={[8, 8]}>
                                  <Col span={24}>
                                    <EstimationAmount
                                      order={currentOrder}
                                      swithToBilling={
                                        showActions ? this.goToTab : null
                                      }
                                      renderSideBySide={
                                        requiredConsignee  ? true : false
                                      }
                                      isShipper={this.props.isShipper}
                                    />
                                  </Col>
                                  {requiredConsignee  && (
                                    <Col span={24}>
                                      {this.renderConsigneeDetails()}
                                    </Col>
                                  )}
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </Card>


                          <Col className="leftBlock marginTop5" xs={ 12 }>
                            <span className="primaryText textBold ">
                              { "Shipper Emails" }
                            </span>
                          </Col>
                            <Table
                              size="small"
                              className='verySmallTable'
                              columns={ columns }
                              dataSource={
                                this.state.currentOrder.account_manager_email_details
                              }
                              pagination={ false }
                              scroll={ { y: 70 } }
                              locale={{
                                emptyText: (
                                  <h4 className="alignCenter">
                                    No Records Found
                                  </h4>
                                )
                              }}
                            />
                        </Col>

                    </Row>
                    {currentOrder.is_location_based ?
                        currentOrder.locations.map((location, index) => {
                          return (
                              <Row className="marginTop10">
                                <Col xs={24} className="currentOrder_details">
                                  <Card
                                      size="small"
                                      bodyStyle={{ zoom: '94%', padding: 5 }}
                                      title={<span className='fontSize16'>{ `Stop ${index + 1}`} Details ({location.is_recovery ? 'Recovery' : location.is_release ? 'Release' : location.type_of_loc}) - {location.status}</span>}
                                      extra={ this.renderExtraLocDetails(location) }
                                  >
                                    {this.renderCSDetails(index, location,location.is_location_based)}
                                  </Card>
                                </Col>

                                {/* <Divider orientation="left">
                          {location.type_of_loc ? I18n.t(`order.${location.type_of_loc}`) : `Location ${index + 1}`} Details
                        </Divider>
                        {this.renderCSDetails(index, location)} */}
                              </Row>
                          )})
                        :
                        (
                            this.renderCSDetails(-1)
                        )}
                        {/* (
                            this.renderCSDetails(0,currentOrder.cs_location)
                        ) */}
                    {/* {!currentOrder.is_location_based && */}
                        <Row className="marginTop10">
                          <Col
                              sm={24}
                              xs={24}
                              md={24}
                              lg={24}
                              className="currentOrder_details"
                          >
                            <this.renderItems />
                          </Col>
                        </Row>
                    {/* } */}
                    <Row className="alignLeft marginTop10 ">
                      <Tabs
                          onChange={this.goToNotesTab}
                          activeKey={this.state.setNotesKey}
                      >
                        <TabPane tab="Shipper Notes" key="shipper_note">
                          <Row>
                            <Col
                                sm={24}
                                xs={24}
                                md={24}
                                lg={24}
                                className="currentOrder_details"
                            >
                              <this.renderShipperNotes />
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tab="Dispatcher Notes" key="dispatcher_note">
                          <Row>
                            <Col
                                sm={24}
                                xs={24}
                                md={24}
                                lg={24}
                                className="dispatcherNotes"
                            >
                              <this.renderDispatcherNotes />
                            </Col>
                          </Row>
                        </TabPane>
                        {!currentOrder.is_location_based && <TabPane tab="Consignee Notes" key="consignee_note">
                          <Row>
                            <Col
                                sm={24}
                                xs={24}
                                md={24}
                                lg={24}
                                className="currentOrder_details"
                            >
                              <this._renderNoteDetails />
                            </Col>
                          </Row>
                        </TabPane>
                        }
                        <TabPane tab="Driver Notes" key="driver_note">
                          <Col
                              sm={24}
                              xs={24}
                              md={24}
                              lg={24}
                              className="currentOrder_details"
                          >
                            <this._renderNoteDetails />
                          </Col>
                        </TabPane>
                        { !['shipper', 'driver'].includes(current_role) && <TabPane tab="Private Notes" key="private_note">
                          <Row>
                            <Col
                              sm={24}
                              xs={24}
                              md={24}
                              lg={24}
                              className="currentOrder_details"
                            >
                              {this.renderPrivateNotes()}
                            </Col>
                          </Row>
                        </TabPane>}
                      </Tabs>
                    </Row>
                  </div>
              )}
            </TabPane>
            {
                (currentOrder?.type_of_order === 'LH' || this.props.isConsolidatedPresent) && !isDeletedOrder && (
                <TabPane
                className="padding10"
                tab={I18n.t("general.sub_orders")}
                  key="subOrders"
                >
                  <SubORdersForLh
                  customerDetails={currentOrder}
                  dontShowtheSearchBar
                  fromIndividualScreen={this.props.fromIndividualScreen}
                  accounts={this.state.accounts}
                  linehaulTab={renderLineHaulTab}
                  mawbButtonClicked={this.state.mawbButtonClicked}
                  consolidatedData ={this.state.consolidatedData}
                  consolidateLoading = {this.state.consolidateLoading}
                  allStatus = {this.state.allStatus}
                  isConsolidatedPresent={this.props.isConsolidatedPresent}
                  />
                </TabPane>
                  )
                }
                {
                  currentOrder?.locations[0]?.is_recovery &&!isDeletedOrder&&(
                    <TabPane
                    className="padding10 detailsSection orderDetailScroll"
                    tab={I18n.t("general.recovery")}
                      key="recovery"
                    >
                      <RecoveryDataTab
                      customerDetails={currentOrder}
                      isMilitaryTime={this.state.isMilitaryTime}
                      />
                    </TabPane>
                  )
                }
                {
                  currentOrder.locations[1]?.is_release &&!isDeletedOrder&&(
                    <TabPane
                    className="padding10 detailsSection orderDetailScroll"
                    tab={I18n.t("general.release")}
                      key="release"
                    >
                      <ReleaseDataTab
                      customerDetails={currentOrder}
                      isMilitaryTime={this.state.isMilitaryTime}
                      />
                    </TabPane>
                  )
                }
            {checkServiceExistance(['OAA', 'OQA'], 'ANY') && !isDeletedOrder&&(
                <TabPane
                    tab={I18n.t('general.accessorial')}
                    key="accessorial"
                    className="padding10 detailsSection orderDetailScroll"
                >
                  {checkServiceExistance('OQA') && (
                      <this._renderOrderQuoteAmount />
                  )}

                  {checkServiceExistance(['OAA', 'AAAC'], 'ALL') && (
                    <this._renderWarehouseAccessorials/>
                  )}

                  <Row gutter={10}>
                    {checkServiceExistance(['OAA', 'AAAC'], 'ALL') && (
                      currentOrder.is_location_based ?
                        currentOrder.locations.map((location, index) => {
                          return (
                            <Col span={currentOrder.locations.length > 1 ? 12 : 24}>
                              {this.renderAccessorial(details, location)}
                            </Col>
                          )
                        })
                        :
                        (
                         <Col >
                          {this.renderAccessorial(details, null)}
                         </Col>
                        )
                    )}

                  </Row>
                  <Row gutter={10}>
                    <Col xs={24}>
                      {this.renderAdjustments(details, null)}
                    </Col>
                  </Row>
                </TabPane>
            )}

            {showActions && checkServiceExistance('OEA') && !isDeletedOrder&&(
                <TabPane tab="Estimate" key="billing" className="orderDetailScroll">
                  <div className="appointmentForm">
                    <Invoice
                        order={currentOrder}
                        order_id={currentOrder.id}
                        callInvoceDetails={true}
                        showHeading={false}
                        showOtherDetails={false}
                        updateQuote={(id, amount) =>
                            this.updateQuote(id, amount, details.key)
                        }
                        setData={false}
                        activeKey={this.state.activeKey}
                    />
                  </div>
                </TabPane>
            )}
            {showActions && checkServiceExistance('OBLR') && !isDeletedOrder&&(
                <TabPane
                    tab={I18n.t('BillOfRating.bill_of_rating_title')}
                    key="bol"
                    className="orderDetailScroll"
                >
                  <div className="appointmentForm">
                    {checkServiceExistance('GOBP') && (
                        <div
                            className="alignCenter marginBottom10"
                            style={{ cursor: 'pointer' }}
                            onClick={this.printBol}
                        >
                          <Icon type="printer" /> Print
                        </div>
                    )}
                    <Spin spinning={this.state.isGeneratingPdf} delay={1000}>
                      <BillOfRatingModal orderId={currentOrder.id} order={currentOrder} />
                    </Spin>
                  </div>
                </TabPane>
            )}
            {currentOrder.is_lh_order &&!isDeletedOrder&& (
                <TabPane
                  tab={I18n.t("linehaul.label")}
                  key="linehaul"
                  className="padding10 detailsSection orderDetailScroll"
                >
                  <Row gutter={16}>
                    <Col xs={24}>
                      <LhDetails
                        order_id={currentOrder.linehaul_id}
                        actionType="view"
                        isMilitaryTime={this.state.isMilitaryTime}
                      />
                    </Col>
                  </Row>
                </TabPane>
            )}
            {/* {
              // type_of_order != 'T' &&
              showActions && checkServiceExistance('ORD') &&
                ['R', 'D', 'TR'].includes(currentOrder.type_of_order) && (
                    <TabPane
                        tab={I18n.t('recoveries.label')}
                        key="recoveries"
                        className="padding10 detailsSection orderDetailScroll"
                    >
                      <Row gutter={16}>
                        <Col xs={24}>
                          <RecoveryManage
                              order_id={currentOrder.id}
                              actionType="view"
                              // timeZoneId={currentOrder.order_timeZoneId}
                              typeOfOrder={currentOrder.type_of_order}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                )}
            { showActions && !['M', 'T'].includes(currentOrder.type_of_order) && checkServiceExistance('ORED') && (
                <TabPane
                    tab={I18n.t('releases.label')}
                    key="releases"
                    className="padding10 detailsSection orderDetailScroll"
                >
                  <Row gutter={16}>
                    <Col xs={24}>
                      <ReleaseManage
                          order_id={currentOrder.id}
                          actionType="view"
                          timeZoneId={currentOrder.order_timeZoneId}
                          account_id={currentOrder.account_id}
                          org_id={ currentOrder.organization_id }
                      />
                    </Col>
                  </Row>
                </TabPane>
            )} */}
            {
             showActions && !isDeletedOrder&&(
               <TabPane
                 tab={I18n.t("exceptions.log")}
                 key="exceptions"
                 className="padding10"
               >
                 <Tabs>
                   <TabPane tab={I18n.t("exceptions.orderException")} key="orderException">
                     <this._renderExceptions />
                   </TabPane>
                   <TabPane tab={I18n.t("exceptions.itemException")} key="itemException">
                    <this._renderItemExceptions/>
                   </TabPane>
                 </Tabs>
               </TabPane>
             )
           }
            {showActions && <TabPane
                tab={I18n.t('order.activities')}
                key="activities"
                className="padding10"
            >
              <this.renderActivities />
            </TabPane>
            }
            {showActions && !isDeletedOrder&&<TabPane
                tab={I18n.t('order.notification_log')}
                key="notification_log"
                className="padding10 detailsSection"
            >
              <Row gutter={16}>
                <Col xs={24}>
                  <this.renderNotification />
                </Col>
              </Row>
            </TabPane>
            }
            {showActions && !isDeletedOrder&&<TabPane
                tab={"WMS"} //"I18n.t('order.wms')"
                key="wms"
                className="padding10 detailsSection"
            >
              <Row gutter={16}>
                <Col xs={24}>
                  <this.renderWms />
                </Col>
              </Row>
            </TabPane>
            }
            {showActions && checkServiceExistance("SCA") && !isDeletedOrder&& <TabPane
                tab={"EDI Status"} //"I18n.t('order.wms')"
                key="send_status"
                className="padding10 detailsSection orderDetailScroll"
            >
              <Row gutter={16}>
                <Col xs={24}>
                  <SendStatusReportComponent
                    order={this.state.currentOrder}
                    order_id={this.state.currentOrder.id}
                  />
                </Col>
              </Row>
            </TabPane>
            }
          </Tabs>
        </div>
      </Spin>
      {this.state.showQuestionForm && (
          <BaseModal
              title="Form Details"
              onCancel={() => this.closeQuestionFormDetails()}
              className="surveyModal"
              width="70%"
              style={{ top: 40, overflowY: 'auto' }}
          >
            <QuesFromResponse recordId={this.state.currentProcessingOrder} locationId={this.state.currentProcessingLocation} />
          </BaseModal>
      )}
    </Fragment>
    )
  }
}
OrderDetails.propTypes = {
  order_id: PropTypes.string.isRequired,
  reqFrom: PropTypes.string,
  updateParentState: PropTypes.func.isRequired,
  fromIndividualScreen: PropTypes.bool
}
OrderDetails.defaultProps = {
  reqFrom: '',
  fromIndividualScreen: false
}

const OrderDetailsComponent = withRouter((props) => {
  const { orgSettings: organizationSettings, accountsExceeded, currentOrg, isAirCargo } = useContext(OrgContext);
  const userContext = useContext(UserContext);
  const isAircargo = isAirCargo();
  const warehouseFilter = useContext(WarehouseContext);
  return (
    <OrderDetails
      userContext={ userContext }
      warehouseFilter={ warehouseFilter }
      organizationSettings={ organizationSettings }
      creditsExceededAccnts={ accountsExceeded }
      currentOrg={ currentOrg }
      isAirCargo={ isAircargo }
      { ...props }
    />
  );
});

export default OrderDetailsComponent;
//