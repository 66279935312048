import AppConfig from "../config/AppConfig";
import { Call, ApiUrl, PageParams, ParseGeneralResponse, optionalPageParams } from "./ApiUtils";

export const TransferOrderApi = {
  fetchParams: () => {
    const url = ApiUrl("v2/message_template_params");
    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: { messages: "" },
    });
  },
  manage: (data, isNew) => {
    data.operation_code = "CCO";
    const url = isNew
      ? ApiUrl("v2/line_haul_orders")
      : ApiUrl(`v2/line_haul_orders/${data.id}`);
    const method = isNew ? "post" : "put";
    return Call(method, url, data, ParseGeneralResponse, {
      responseDataKeys: { orders: "line_haul_orders" , warning: "warning" },
    });
  },
  delete: (id) => {
    const url = ApiUrl("v2/line_haul_routes/" + id);
    return Call("delete", url, { operation_code: 'LRD' }, ParseGeneralResponse, {
      responseDataKeys: { data: "" },
    });
  },
  fetchEvents: (renderType = "", page = 1, perPage = AppConfig.perPage) => {
    let url = ApiUrl(`v2/line_haul_orders?operation_code=TEI`);
    if (renderType === "list") {
      url += `&render_type=list&${optionalPageParams(page, perPage, false)}`;
    }
    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: { data: "line_haul_orders", pagination: "meta.pagination" },
    });
  },
  validateOrders: (orders) => {
    const url = ApiUrl(`v2/line_haul_orders/validate_linehauls?linehaul_order_ids=${orders}`);
    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: { data: "" },
    });
  },
};
